/**
 * @category LeftSide
 * @component
 * @module LeftSide
 * @description This component is the parent component of all the other components contained in the left side
 * of the dashboard. It controls which parts are visible and which are not based on the toggle buttons.
 */
import React from "react";
import ToggleButton from "../commonComponents/ToggleButton";
import Grid from "@mui/material/Grid";
import Models from "./Models/Models";
import FileUploader from "./Files/FileUploader";
import Demo from "./Demo/Demo";

function LeftSide() {
  return (
    <Grid item xs={2.5}>
      <div className="toggleButtonContainer">
        <ToggleButton content={<Demo />} title={"Demo"} id="demo-switch" />
        <ToggleButton
          content={<FileUploader />}
          title={"Data"}
          id="data-switch"
        />
        <ToggleButton
          title={"Models"}
          content={<Models />}
          id="models-switch"
        />
      </div>
    </Grid>
  );
}

export default LeftSide;
