import React from "react";
import deleteIcon from "../../../res/icons/delete.png";
import TextField from "@mui/material/TextField";
import "./polling.scss";

function AnswerEntry(props) {
  const { value, setValue, index, deleteAnswer, deleteAllowed = false } = props;

  return (
    <div className="answerArea" key={index}>
      <TextField
        className={`answerEntry ${
          index > 1 || deleteAllowed ? "withDelete" : ""
        }`}
        size="small"
        value={value}
        onChange={(e) => setValue(e.target.value, index)}
        label="Poll answer"
      />
      {index > 1 || deleteAllowed ? (
        <img
          src={deleteIcon}
          alt="delete Answer"
          className="deleteAnswer"
          onClick={() => deleteAnswer(index)}
        />
      ) : (
        <></>
      )}
    </div>
  );
}

export default AnswerEntry;
