import React from "react";
import FitOptions from "./FitOptions";
import "./fit.scss";

function FitSection() {
  return (
    <div className="fitSection">
      <div className="container">
        <FitOptions closeModal={() => {}} />
      </div>
    </div>
  );
}

export default FitSection;
