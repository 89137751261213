import React, { useState } from "react";
import { Select, MenuItem, FormControl } from "@mui/material";
import OnePx from "../../../res/icons/line_icons/1px.png";
import TwoPx from "../../../res/icons/line_icons/2px.png";
import ThreePx from "../../../res/icons/line_icons/3px.png";
import FourPx from "../../../res/icons/line_icons/4px.png";
import SixPx from "../../../res/icons/line_icons/6px.png";
import EightPx from "../../../res/icons/line_icons/8px.png";

function LineWidthSelect(props) {
  const { updateWidth, dist, defaultWidth } = props;
  const [lineStyle, setLineWidth] = useState(defaultWidth);

  const handleChange = (event) => {
    setLineWidth(event.target.value);

    updateWidth(event.target.value, dist);
  };

  return (
    <FormControl
      size="small"
      variant="standard"
      style={{ width: 75, marginLeft: "10px", marginRight: "10px" }}
    >
      <Select
        value={lineStyle}
        onChange={handleChange}
        label="Line Style"
        style={{ padding: 0, height: "20px", width: "75px" }}
      >
        <MenuItem value={1}>
          <img src={OnePx} alt="1 pixel" style={{ height: "20px" }} />
        </MenuItem>
        <MenuItem value={2}>
          <img src={TwoPx} alt="2 pixel" style={{ height: "20px" }} />
        </MenuItem>
        <MenuItem value={3}>
          <img src={ThreePx} alt="3 pixel" style={{ height: "20px" }} />
        </MenuItem>
        <MenuItem value={4}>
          <img src={FourPx} alt="4 pixel" style={{ height: "20px" }} />
        </MenuItem>
        <MenuItem value={6}>
          <img src={SixPx} alt="6 pixel" style={{ height: "20px" }} />
        </MenuItem>
        <MenuItem value={8}>
          <img src={EightPx} alt="8 pixel" style={{ height: "20px" }} />
        </MenuItem>
      </Select>
    </FormControl>
  );
}

export default LineWidthSelect;
