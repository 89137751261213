import React from "react";

function MainAppCrash() {
  return (
    <div
      style={{
        width: "95vw",
        height: "95vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "1.5em",
      }}
    >
      <div style={{ padding: "10vh 10vw", textWrap: "wrap" }}>
        Something went wrong, please try refreshing the page or closing and
        opening the page again.
        <br />
        <br />
        If you can, please report this to support.
      </div>
    </div>
  );
}

export default MainAppCrash;
