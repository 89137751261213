import React, { useState } from "react";
import { Select, MenuItem, FormControl } from "@mui/material";
import SolidLine from "../../../res/icons/line_icons/solid.png";
import DottedLine from "../../../res/icons/line_icons/dot.png";
import DashedLine from "../../../res/icons/line_icons/dash.png";
import LongDashedLine from "../../../res/icons/line_icons/long-dash.png";
import DashDotLine from "../../../res/icons/line_icons/dashdot.png";
import LongDashDotLine from "../../../res/icons/line_icons/longdashdot.png";

function LineStyleSelect(props) {
  const { updateLine, dist, defaultLine } = props;
  const [lineStyle, setLineStyle] = useState(defaultLine);

  const handleChange = (event) => {
    setLineStyle(event.target.value);

    updateLine(event.target.value, dist);
  };

  return (
    <FormControl
      size="small"
      variant="standard"
      style={{ width: 75, marginLeft: "10px", marginRight: "10px" }}
    >
      <Select
        value={lineStyle}
        onChange={handleChange}
        label="Line Style"
        style={{ padding: 0, height: "20px", width: "75px" }}
      >
        <MenuItem value="solid">
          <img src={SolidLine} alt="Solid Line" style={{ height: "20px" }} />
        </MenuItem>
        <MenuItem value="dot">
          <img src={DottedLine} alt="Dotted Line" style={{ height: "20px" }} />
        </MenuItem>
        <MenuItem value="dash">
          <img src={DashedLine} alt="Dashed Line" style={{ height: "20px" }} />
        </MenuItem>
        <MenuItem value="longdash">
          <img
            src={LongDashedLine}
            alt="Long Dashed Line"
            style={{ height: "20px" }}
          />
        </MenuItem>
        <MenuItem value="dashdot">
          <img
            src={DashDotLine}
            alt="Dash Dot Line"
            style={{ height: "20px" }}
          />
        </MenuItem>
        <MenuItem value="longdashdot">
          <img
            src={LongDashDotLine}
            alt="Long Dash Dot Line"
            style={{ height: "20px" }}
          />
        </MenuItem>
      </Select>
    </FormControl>
  );
}

export default LineStyleSelect;
