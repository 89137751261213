import React, { useState, useContext } from "react";
import { Button } from "@mui/material";
import { DashboardContext } from "../../../context/DashboardContext";
import { GraphContext } from "../../../context/GraphContext";
import { AuthContext } from "../../../context/AuthContext";
import { file as demoFile, model } from "./demoData";
import "./demo.scss";
import { generatePlotData } from "../../middle/graphLogic";
import {
  filterCurveDataToRange,
  getColor,
  getDataPointsFromFile,
  getShortName,
} from "../../../utils/helpers";
import { deepCopy } from "../Models/modelLogic";
import { WebSocketContext } from "../../../context/WebSocketContext";

function Demo() {
  const {
    button1Active,
    setButton1Active,
    button2Active,
    setButton2Active,
    button3Active,
    setButton3Active,
    uploadedFiles,
    setUploadedFiles,
    setModelData,
    setFileID,
    setAbbrDic,
    setNamingDic,
    setModelNextID,
  } = useContext(DashboardContext);

  const { graphs, setGraphs, setZIndices, setRequestedFitModel } =
    useContext(GraphContext);

  const { sendJsonMessage, isFitOngoing } = useContext(WebSocketContext);
  const { currentUser } = useContext(AuthContext);

  const [smallWindow] = useState({
    height: Math.max(window.innerWidth * 0.3, 220),
    width: Math.max(window.innerWidth * 0.4, 260),
  });

  function handleButton1Click() {
    setButton1Active(true);
    setUploadedFiles([demoFile]);
    generateGraphFromFile(demoFile);
  }

  function handleButton2Click() {
    setButton2Active(true);
    setModelData([model]);
    setAbbrDic((oldDict) => {
      return { ...oldDict, Raman: 1 };
    });
    setNamingDic((oldDict) => {
      return { ...oldDict, 1: "Raman" };
    });
    setModelNextID((oldId) => oldId + 1);

    const demoGraph = graphs.find((graph) => graph.id == 1);

    const plotData = generatePlotData(
      model.curves[0].curve,
      "",
      "",
      getColor(demoGraph.plotData.length + 1),
      "Raman",
      null,
      1,
      null,
      5
    );

    demoGraph.plotData = [...demoGraph.plotData, ...plotData];
    demoGraph.containedModels = [{ modelId: 1, quantity: 5 }];

    setGraphs([deepCopy(demoGraph)]);
  }

  function handleButton3Click() {
    setButton3Active(true);
    setRequestedFitModel((old) => [...old, { modelId: 1, quantity: 5 }]);
    handleStep3();
  }

  const generateGraphFromFile = (fileToSet) => {
    const data = Object.prototype.hasOwnProperty.call(fileToSet, "dataPoints")
      ? fileToSet.dataPoints
      : getDataPointsFromFile(fileToSet.content);

    createGraphEntry(
      data,
      fileToSet.name,
      fileToSet.ID,
      null,
      fileToSet.dataRangeMax,
      fileToSet.dataRangeMin,
      fileToSet.edges
    );
  };

  const createGraphEntry = (
    data,
    name,
    fileID,
    modelID,
    rangeMax,
    rangeMin,
    edges
  ) => {
    const shortName = getShortName(name);

    const plotData = generatePlotData(
      data,
      rangeMin,
      rangeMax,
      "blue",
      shortName,
      fileID,
      modelID,
      edges
    );

    const layout = {
      xaxis: {
        title: { text: "Wavenumber [cm<sup>-1</sup>]" },
        mirror: "ticks",
        showline: true,
        linecolor: "#000",
        linewidth: 1,
        range: [-200, 250],
      },
      yaxis: {
        title: { text: "Amplitude" },
        mirror: "ticks",
        showline: true,
        linecolor: "#000",
        linewidth: 1,
        range: [0, 950],
      },
      margin: {
        l: 60, // left margin
        r: 50, // right margin
        b: 50, // bottom margin
        t: 30, // top margin
        pad: 0, // padding between the plotting area and the axis labels
      },
      autosize: true,
      width: smallWindow.width - 2,
      height: smallWindow.height - 38,
      paper_bgcolor: "#edf7ff",
    };

    const position = {
      x: 50,
      y: 0,
    };

    const demoGraphs = [
      {
        id: 1,
        position: position,
        title: "Demo Graph",
        plotData: plotData,
        layout: layout,
        containedFiles: fileID != null ? [fileID] : [],
        containedModels: modelID != null ? [modelID] : [],
      },
    ];

    setGraphs(demoGraphs);

    setZIndices((prevZIndices) => ({ ...prevZIndices, [1]: 0 }));
    setFileID((old) => old + 1);
  };

  const handleStep3 = () => {
    const demoFile = uploadedFiles.find((uplFile) => uplFile.ID == 1);

    let data = Object.prototype.hasOwnProperty.call(demoFile, "dataPoints")
      ? demoFile.dataPoints
      : getDataPointsFromFile(demoFile.content);

    data = filterCurveDataToRange(
      data,
      demoFile.dataRangeMin,
      demoFile.dataRangeMax
    );

    const filteredCutParams = demoFile.edges.filter(
      (param) =>
        (param.min !== undefined && param.min !== "") ||
        (param.max !== undefined && param.max !== "")
    );

    let fitinfoLoc = {
      peak0auto: true,
      edges: filteredCutParams,
      onlypos: false,
      npoints: demoFile.npoints,
      range: { min: -200, max: 250 },
    };

    // let range = {};
    // range = {
    //   ...range,
    //   min: demoFile.dataRangeMin != "" ? demoFile.dataRangeMin : -1000,
    // };
    // range = {
    //   ...range,
    //   max: demoFile.dataRangeMax != "" ? demoFile.dataRangeMax : 1000,
    // };

    // fitinfoLoc = { ...fitinfoLoc, range: range };

    let payload = {
      User: currentUser.id,
      Data: {
        SendData: [
          {
            dataid: demoFile.ID,
            data: data,
          },
        ],
      },
      Fit: {
        AutoFit: [
          {
            modeltype: -113,
            modelid: 1,
            dataid: demoFile.ID,
            fitinfo: fitinfoLoc,
            quantity: 5, //Default Raman
          },
        ],
      },
      Model: {
        GetModelCurve: [
          {
            min: -200,
            max: 250,
            modelid: 1,
            quantity: 5,
          },
        ],
      },
    };

    sendJsonMessage(payload, { type: "autofit" });
  };

  return (
    <div className="demo">
      <div className="buttonsContainer">
        <div className="buttonSection">
          <div className="buttonWithText">
            <Button
              variant="contained"
              size="small"
              sx={{ m: 1 }}
              onClick={() => handleButton1Click()}
              className="button"
              id="load-example-data"
              disabled={isFitOngoing}
            >
              Step 1
            </Button>
            <div className="text">Load and display demo data.</div>
          </div>
          {button1Active ? (
            <div className="textAfterClick" id="1-step-text">
              You can now see the data listed in the data tab and displayed in
              the graph.
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="buttonSection">
          <div className="buttonWithText">
            <Button
              variant="contained"
              size="small"
              sx={{ m: 1 }}
              onClick={() => handleButton2Click()}
              className="button"
              id="demo-step-2"
              disabled={!button1Active || isFitOngoing}
            >
              Step 2
            </Button>
            <div className="text">Create and display a Raman model</div>
          </div>
          {button2Active ? (
            <div className="textAfterClick" id="2-step-text">
              The model is listed in the model menu, and all the model
              parameters in the parameters window. One line in the graph is the
              experimental data, the other is the output of the model. The model
              line is zero because no fit was made yet.
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="buttonSection">
          <div className="buttonWithText">
            <Button
              variant="contained"
              size="small"
              sx={{ m: 1 }}
              onClick={() => handleButton3Click()}
              className="button"
              id="demo-step-3"
              disabled={!button1Active || !button2Active || isFitOngoing}
            >
              Step 3
            </Button>
            <div className="text">Autofit the model to the data.</div>
          </div>
          {button3Active ? (
            <div className="textAfterClick" id="3-step-text">
              The Raman model is fitted to the data. In the parameters tab you
              can see the parameters of the fitted model, such as the
              temperature. You can change the value of any of the parameters and
              see in the graph the effect this has. You can always rerun the
              autofit, to get back to the best fit. You can also try to fit the
              data using your new variables as a starting point. The autofit
              takes care of a lot of technicalities for you, such as removing
              part of the dataset with too high intensity for accurate
              measurements. Unless you understand your data very well and are
              able to perform these manipulations yourself, the fit might not be
              stable. The fit menu is intended for the most expert users.
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}

export default Demo;
