import React from "react";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} placement="top" />
))(() => ({
  // function can get '{ theme }' here if needed
  zIndex: 9999,
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "transparent",
    border: "0",
  },
}));

export default HtmlTooltip;
