import React, { useState } from "react";
import { Select, MenuItem, FormControl } from "@mui/material";
import Line from "../../../res/icons/line_icons/line.png";
import Marker from "../../../res/icons/line_icons/marker.png";
import MarkerPlusLine from "../../../res/icons/line_icons/marker+line.png";

function LineModeSelect(props) {
  const { updateLineMode, dist, defaultLineMode = "lines" } = props;
  const [lineMode, setLineMode] = useState(defaultLineMode);

  const handleChange = (event) => {
    setLineMode(event.target.value);

    updateLineMode(event.target.value, dist);
  };

  return (
    <FormControl
      size="small"
      variant="standard"
      style={{ width: 50, marginLeft: "10px", marginRight: "10px" }}
    >
      <Select
        value={lineMode}
        onChange={handleChange}
        label="Line Style"
        style={{ padding: 0, height: "20px", width: "50px" }}
      >
        <MenuItem value="lines">
          <img src={Line} alt="Line" style={{ height: "20px" }} />
        </MenuItem>
        <MenuItem value="markers">
          <img src={Marker} alt="Marker" style={{ height: "20px" }} />
        </MenuItem>
        <MenuItem value="lines+markers">
          <img src={MarkerPlusLine} alt="Marker plus line" style={{ height: "20px" }} />
        </MenuItem>
      </Select>
    </FormControl>
  );
}

export default LineModeSelect;
