import React from "react";
import Modal from "react-modal";

function ConfirmationScreen(props) {
  const { onYes, onNo, text, open, setOpen, element } = props;

  return (
    <>
      <Modal
        isOpen={open}
        onRequestClose={() => setOpen(false)}
        shouldCloseOnOverlayClick={true}
        contentLabel="Model Modal"
        appElement={element.current}
        style={{
          content: {
            width: "500px",
            height: "155px",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
          },
          overlay: {
            // backgroundColor: "transparent",
            zIndex: "9000",
          },
        }}
      >
        <div className="confirmModal">
          <div
            className="confirmAsk"
            data-testid="confirm-text"
            id="confirmation-question"
          >
            {text}
          </div>
          <div className="confirmButtons">
            <div
              className="button"
              onClick={() => onYes()}
              data-testid="confirm-yes"
              id="confirm-yes-button"
            >
              Yes
            </div>
            <div
              className="button"
              onClick={() => onNo()}
              data-testid="confirm-no"
              id="confirm-no-button"
            >
              No
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ConfirmationScreen;
