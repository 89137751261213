import React from "react";
import "./adminComponents.scss";
import UserDetail from "./UserDetail";

function UserData(props) {
  const {
    fullName,
    email,
    emailVerified,
    newsLetter,
    dateCreated,
    setNewsletter,
    setEmail,
    setFullName,
  } = props;

  let dateObj = new Date(dateCreated);

  let day = String(dateObj.getDate()).padStart(2, "0");
  let month = String(dateObj.getMonth() + 1).padStart(2, "0"); // Note: 0 = January, so we must add 1 to get correct month
  let year = dateObj.getFullYear();

  let hours = String(dateObj.getHours()).padStart(2, "0");
  let minutes = String(dateObj.getMinutes()).padStart(2, "0");

  let newDateString =
    day + "/" + month + "/" + year + " " + hours + ":" + minutes;

  return (
    <div className="userData">
      <UserDetail
        title={"Full Name:"}
        value={fullName !== null ? fullName : ""}
        setValue={setFullName}
      />
      <UserDetail
        title={"Email:"}
        value={email}
        showVerified={emailVerified}
        setValue={setEmail}
      />
      <UserDetail
        title={"Newsletter:"}
        value={newsLetter}
        checkbox
        checkboxLabel="Agreed"
        setValue={setNewsletter}
      />
      <UserDetail title={"Date Created:"} value={newDateString} nonEdit />
    </div>
  );
}

export default UserData;
