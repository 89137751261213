import React, { useState, useEffect } from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Box } from "@mui/material";
import "./commonComponents.scss";

const SLIDE_OUT_HEIGHT = 70;

const SlideOutWindow = ({ onClick }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleMouseMove = (e) => {
      if (e.clientX <= 1) {
        setIsVisible(true);
        setMousePosition({ x: e.clientX, y: e.clientY - SLIDE_OUT_HEIGHT / 2 });
      }
    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  const handleMouseEnter = () => {
    setIsVisible(true);
  };

  const handleMouseLeave = () => {
    setIsVisible(false);
  };

  return (
    <Box
      data-testid="slide-out-window"
      className={`slide-out-component ${isVisible ? "visible" : ""}`}
      onClick={onClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{ top: mousePosition.y }}
    >
      <ChevronRightIcon />
    </Box>
  );
};

export default SlideOutWindow;
