import React, { useEffect, useState, useContext } from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { TextField } from "@mui/material";
import { GraphContext } from "../../../context/GraphContext";
import { GeneralContext } from "../../../context/GeneralContext";
import "./fit.scss";
import { processSymbols, toScientificNotation } from "../../../utils/helpers";

function X2Chip(props) {
  const {
    fileName,
    modelName,
    quantityName,
    weightInitial,
    index,
    handleDeleteChip,
    handleTermWeightChange,
    handleClick,
    id,
    chi2 = null,
    activeParams = null,
    needsUpdate = false,
  } = props;
  const { selectedChi2Terms } = useContext(GraphContext);
  const { recordedErrorLog } = useContext(GeneralContext);
  const [weight, setWeight] = useState(weightInitial);

  const handleWeightChange = (e) => {
    try {
      if (e.target.value !== undefined && e.target.value !== null) {
        if (e.target.value !== "") {
          const parsedValue = parseFloat(e.target.value);
          if (parsedValue >= 0) {
            setWeight(parsedValue);
          } else {
            setWeight(0);
          }
        } else {
          setWeight(e.target.value);
        }
      }
    } catch (error) {
      recordedErrorLog("Weight change handler failure: ", error);
    }
  };

  const handleBlur = () => {
    if (weight === "") {
      setWeight(0);
    }
  };

  useEffect(() => {
    if (weight === "") {
      handleTermWeightChange(index, 0);
    } else {
      handleTermWeightChange(index, weight);
    }
  }, [weight]);

  function sanitizeChi2(val) {
    try {
      if (typeof val === "number") {
        return toScientificNotation(parseInt(val));
      } else {
        return "N/A";
      }
    } catch (error) {
      recordedErrorLog("Chi2 sanitization has failed: ", error);
    }
  }

  return (
    <div
      className={`x2Chip ${
        selectedChi2Terms.some((listId) => listId === id) ? "" : "notSelected"
      }`}
      onClick={(e) => handleClick(e, id)}
      id="chi2-term-chip"
    >
      <div className="namesContainer">
        <div className="name">{fileName}</div>
        <div className="modelAndQuantity">
          <div className="name">{modelName}</div>
          <div className="spacer" />
          <div className="quantityName">{processSymbols(quantityName)}</div>
        </div>
      </div>
      <div className="entriesContainer">
        <TextField
          style={{
            height: "100%",
            width: "67px",
          }}
          label="Weight"
          value={weight}
          onChange={handleWeightChange}
          onBlur={handleBlur}
          className="rangeVal"
          size="small"
          type="number"
        />
        <div className="chiValues">
          <div className="valueEntry">
            <div className="title">
              Χ<sup>2</sup>:
            </div>
            <div
              className={`value${needsUpdate ? " greyOut" : ""}`}
              id="calculated-chi2-value"
            >
              {chi2 === null ? "N/A" : sanitizeChi2(chi2)}
            </div>
          </div>
          <div className="valueEntry">
            <div className="title" title="Number of Active Fit Parameters">
              AP:
            </div>
            <div
              className={`value${needsUpdate ? " greyOut" : ""}`}
              id="active-parameters-value"
            >
              {activeParams === null ? "N/A" : sanitizeChi2(activeParams)}
            </div>
          </div>
        </div>
      </div>
      <div className="deleteArea">
        <HighlightOffIcon
          className="deleteIcon"
          onClick={() => handleDeleteChip(index)}
        />
        <div
          className="deleteOverlay"
          onClick={() => handleDeleteChip(index)}
        />
      </div>
    </div>
  );
}

export default X2Chip;
