import React from "react";
import "./polling.scss";
import { calculatePercentage } from "../../../utils/helpers";

function AnswerBar(props) {
  const { answer, total, value } = props;

  const percentage = calculatePercentage(value, total);

  return (
    <div className="answerBar">
      <div className="filledPart" style={{ width: `${percentage}%` }} />
      <div className="emptyPart" style={{ width: `${100 - percentage}%` }} />
      <div className="answer">{answer}</div>
      <div className="percentage">
        {value}/{total} ({percentage}%)
      </div>
    </div>
  );
}

export default AnswerBar;
