import React from 'react';
import './hexTextLoader.scss';

const HexTextLoader = ({ text }) => {
  return (
    <div className="hex-text-loader">
      <div className="hex-honeycomb">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <span className="loader-text">{text}</span>
    </div>
  );
};

export default HexTextLoader;
