import React, { useState, useContext } from "react";
import { DashboardContext } from "../../../../context/DashboardContext";
import { GeneralContext } from "../../../../context/GeneralContext";
import { processFileWithOptions } from "./processingLogic";
import RawAndSolved from "./RawAndSolved";
import ProcessingOptions from "./ProcessingOptions";
import "./fileProcessing.scss";

function SingleFileReprocess(props) {
  const { file, handleDone, handleCancel, modalId } = props;
  const { filePresets, setFilePresets } = useContext(DashboardContext);
  const { recordedErrorLog } = useContext(GeneralContext);
  const [processedContent, setProcessedContent] = useState("");

  const handleSave = (optionObject) => {
    try {
      const saveName = prompt("Name your option set:");
      if (saveName !== null) {
        const largestIdObj = filePresets.reduce((acc, curr) => {
          return acc.id > curr.id ? acc : curr;
        }, filePresets[0]);

        setFilePresets((old) => [
          ...old,
          {
            presetName: saveName,
            id: largestIdObj.id + 1,
            presetOptions: optionObject,
          },
        ]);
      }
    } catch (error) {
      recordedErrorLog("Save handler failure: ", error);
    }
  };

  const handleApply = (optionObject) => {
    try {
      const dataPoints = processFileWithOptions(
        file.content,
        optionObject,
        recordedErrorLog
      );

      if (dataPoints !== null) {
        const maxX = dataPoints.reduce(function (max, p) {
          const x = p.x > 0 ? Math.ceil(p.x) : Math.floor(p.x);
          return x > max ? x : max;
        }, Math.ceil(dataPoints[0].x));

        const minX = dataPoints.reduce(function (min, p) {
          const x = p.x > 0 ? Math.ceil(p.x) : Math.floor(p.x);
          return x < min ? x : min;
        }, Math.floor(dataPoints[0].x));

        const fileData = {
          name: file.name,
          size: file.size,
          type: file.type,
          lastModified: file.lastModified,
          content: file.content,
          dataPoints: dataPoints,
          npoints: dataPoints.length,
          edges: [{ min: "", max: "" }],
          dataRangeMin: minX,
          dataRangeMax: maxX,
          xUnit: optionObject.xUnit,
          ID: file.ID,
          options: optionObject,
        };

        handleDone(fileData);
      } else {
        setProcessedContent(dataPoints);
      }
    } catch (error) {
      recordedErrorLog("Apply handler has failed: ", error);
    }
  };

  const handleCheck = (optionObject) => {
    try {
      const dataPoints = processFileWithOptions(
        file.content,
        optionObject,
        recordedErrorLog
      );

      setProcessedContent(dataPoints);
    } catch (error) {
      recordedErrorLog("Check handler has failed: ", error);
    }
  };

  return (
    <div className="singleFileReprocess">
      <RawAndSolved
        rawFileContent={file !== null ? file.content : ""}
        processedContent={processedContent}
      />
      <div className="divider" />
      <ProcessingOptions
        save={handleSave}
        apply={handleApply}
        check={handleCheck}
        modalId={modalId}
        cancellable
        cancel={handleCancel}
      />
    </div>
  );
}

export default SingleFileReprocess;
