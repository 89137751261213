import React, { useState, useEffect, useContext } from "react";
import Modal from "react-modal";
import CloseIcon from "@mui/icons-material/Close";
import { GeneralContext } from "../../context/GeneralContext";
import "./commonComponents.scss";

function BroadcastModal(props) {
  const { elementRef, isOpen, setIsOpen, handleClose } = props;
  const { broadcastMessage, setBroadcastMessage } = useContext(GeneralContext);
  const [showClose, setShowClose] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setShowClose(true);
      }, 1500);
    }
  }, [isOpen]);

  const handleModalClose = () => {
    setBroadcastMessage("");
    setShowClose(false);
    handleClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
      shouldCloseOnOverlayClick={false}
      contentLabel="Broadcast Modal"
      appElement={elementRef.current}
      style={{
        content: {
          top: "35%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -35%)",
          width: "70vw",
          height: "70vh",
        },
        overlay: {
          zIndex: "9999",
        },
      }}
    >
      <div className="broadcastModal">
        <div className="broadcastHeader">
          <div className="title">Message from Administrator:</div>
          {showClose ? (
            <CloseIcon
              className="icon"
              onClick={() => handleModalClose()}
              data-testid="close-icon"
            />
          ) : (
            <></>
          )}
        </div>
        <div className="message">{broadcastMessage}</div>
      </div>
    </Modal>
  );
}

export default BroadcastModal;
