import React from "react";
import "./adminComponents.scss";

function PrivacyPolicy() {
  return (
    <div className="privacyPolicyModal">
      <h2>Privacy Policy</h2>
      <h4>Website statistics</h4>
      <p>
        This website uses <a href="https://matomo.org/">Matomo</a> to compute
        the visit statistics. It is configured to protect the users data and
        anonymity:
      </p>
      <ul>
        <li>Zero cookies</li>
        <li>Anonymization of the IP addresses</li>
        <li>
          Data are stocked locally on our provider servers (
          <a href="https://www.infomaniak.com/fr">Infomaniak</a>, Switzerland)
          and are not shared with a third party
        </li>
        <li>Deletion of the raw data after 12 months</li>
      </ul>
      <p>
        These statistics help us to improve the user experience and are used for
        this only purpose.
      </p>
      <h4>Data form</h4>
      <p>
        The personal information collected in the registration forms and
        questionnaire are used for the further development of RefFIT only and
        are not shared with a third party.
      </p>
    </div>
  );
}

export default PrivacyPolicy;
