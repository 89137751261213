import React, { useState, useContext, useEffect, useRef } from "react";
import { DashboardContext } from "../../../context/DashboardContext";
import { WebSocketContext } from "../../../context/WebSocketContext";
import { GraphContext } from "../../../context/GraphContext";
import { GeneralContext } from "../../../context/GeneralContext";
import { AuthContext } from "../../../context/AuthContext";
import Button from "@mui/material/Button";
import {
  hasParentOrChildWithID,
  getModelById,
  replaceModelById,
  setLinked,
  deleteObjectsByID,
  addSubModelToModels,
  unlinkModelList,
  refreshLinks,
  createModelsForDisplay,
  deepCopy,
  updateDisplayName,
  isDescendant,
  constructModelList,
  generateModel,
  linkedSubModelAdd,
  generateModelFromFile,
  createModelFromParamList,
  collectClashingGroupsFromNonRecParams,
  collectClashingGroupsFromRecParams,
  allContainedGroups,
  updateRecParamsAndRows,
} from "./modelLogic";
import Modal from "react-modal";
import {
  handleFileChange,
  generateWarningObject,
  hasProperty,
  updateModelsOnGroupChanges,
  simpleReadFile,
  adjustModalPositionAndSize,
  vwToPixels,
  arrayBufferToBase64,
  removeModelsFromTablesById,
} from "../../../utils/helpers";
import "./../leftSide.scss";
import GroupClashWindow from "./GroupClashWindow";

export default function Models() {
  const {
    modelData,
    setModelData,
    modelNextID,
    setModelNextID,
    abbrDic,
    setAbbrDic,
    namingDic,
    setNamingDic,
    setAllLocalModels,
    allLocalModels,
    setWarnings,
    setNewWarningCount,
    setUndoModels,
    valueGroups,
    setValueGroups,
  } = useContext(DashboardContext);
  const {
    lastJsonMessage,
    sendJsonMessage,
    isFitOngoing,
    retrieveRequestData,
    deleteRequestDataEntry,
  } = useContext(WebSocketContext);
  const {
    setUpdatedModelFE_IDs,
    graphs,
    setGraphs,
    chi2Terms,
    setChi2Terms,
    selectedChi2Terms,
    setSelectedChi2Terms,
  } = useContext(GraphContext);
  const { limitedToast, recordedErrorLog } = useContext(GeneralContext);
  const { currentUser, isAuthReady } = useContext(AuthContext);
  const [linkingActive, setLinkingActive] = useState(false);
  const [linkingID, setLinkingID] = useState(null);
  const [linkingReffitID, setLinkingReffitID] = useState(null);
  let modelsAfterUnlink = [];
  let idAfterUnlink = 0;
  // let modelsForDisplay = [];
  let allModelsReceived = [];
  const [modelsToDisplay, setModelsToDisplay] = useState([]);
  const modelsRef = useRef(null);
  const localModelsDataRef = useRef(null);
  const [modalList, setModalList] = useState(null);
  // const [allModels, setAllModels] = useState(null);
  const allModels = useRef(null);
  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalPlaceAndSize, setModalPlaceAndSize] = useState({
    top: "0",
    left: "0",
    height: "300px",
    width: "300px",
  });
  const [clashModalIsOpen, setClashModalIsOpen] = useState(false);
  const fileInputRef = useRef(null);

  const [clashingModel, setClashingModel] = useState(null);
  const [clashingGroups, setClashingGroups] = useState(null);
  const [upadatedGroupsWithNoClash, setUpdatedGroupsWithNoClash] =
    useState(null);

  // const requestedBinary = useRef([]);

  useEffect(() => {
    const modelsForDisplay = createModelsForDisplay(
      modelData,
      renameModel,
      startLinking,
      isLinkActive,
      deleteModel,
      addSubModel,
      unlinkModel,
      canLink,
      namingDic
    );

    setModelsToDisplay(modelsForDisplay);
  }, [modelData, namingDic, linkingActive]);

  useEffect(() => {
    if (clashingModel !== null) {
      setClashModalIsOpen(true);
    }
  }, [clashingModel]);

  useEffect(() => {
    localModelsDataRef.current = modelData;
  }, [modelData]);

  //Creating a local abbreviation dictionary here, since the useState setter is not
  //setting the value fast enough for it to be used in multi level depth sub model generation
  //the context is still being updated, in case the value needs to be accessed from other parts and
  //it allows for names to stay the same during page refresh or "Models" window closing and opening
  let localAbbrDic = abbrDic;
  function getAbbrDic() {
    return localAbbrDic;
  }

  function setLocalAbbrDic(changedAbbrs) {
    localAbbrDic = changedAbbrs;
    setAbbrDic(localAbbrDic);
  }

  const handleCloseModal = () => {
    setModalIsOpen(false);
  };

  const handleCloseClashModal = () => {
    setClashModalIsOpen(false);
  };

  let currentID = modelNextID;

  function getID() {
    return currentID;
  }

  function upID() {
    currentID = currentID + 1;
    setModelNextID(currentID);
  }

  const handleModalEntryClick = (reffitID) => {
    try {
      const generatedModel = generateModel(
        reffitID,
        getID,
        upID,
        getAbbrDic,
        setLocalAbbrDic,
        allModelsReceived,
        namingDic,
        setNamingDic,
        false,
        recordedErrorLog
      );
      const modelDataToChange = deepCopy(localModelsDataRef.current);
      modelDataToChange.push(generatedModel);
      setModelData(modelDataToChange);

      handleCloseModal();
    } catch (error) {
      recordedErrorLog("Modal entry click handler failure: ", error);
    }
  };

  function handleModelListReponse(jsonMessage) {
    try {
      const listOfReffitIDs = jsonMessage.Model.ModelList.map(
        (entry) => entry.reffit_id
      );
      sendJsonMessage(
        {
          User: currentUser.id,
          Model: { GetModelSpecs: listOfReffitIDs },
        },
        { type: "get-model-specs" }
      );
      allModels.current = jsonMessage.Model.ModelList;
    } catch (error) {
      recordedErrorLog("Error handling model LIST response: ", error);
    }
  }

  function handleModelSpecsReponse(jsonMessage) {
    try {
      let allModelsCopy = deepCopy(allModels.current);
      allModelsCopy = allModelsCopy.map((model) => {
        const selectedDetails = jsonMessage.Model.ModelSpecs.find(
          (details) => details.ReffitModelID == model.reffit_id
        );
        return {
          ...model,
          params: selectedDetails.Parameters,
          outputs: selectedDetails.Outputs,
        };
      });
      allModelsReceived = deepCopy(allModelsCopy);
      allModels.current = allModelsCopy;
      setAllLocalModels(allModelsCopy);

      setModalList(constructModelList(allModelsCopy, handleModalEntryClick));
      setLoading(false);
    } catch (error) {
      recordedErrorLog("Error handling model SPECS response: ", error);
    }
  }

  function generateModelForParams(reffitID) {
    const generatedModel = generateModel(
      reffitID,
      getID,
      upID,
      getAbbrDic,
      setLocalAbbrDic,
      // allModels,
      allModels.current,
      namingDic,
      setNamingDic,
      false,
      recordedErrorLog
    );

    return generatedModel;
  }

  function handleBinaryModelReponse(jsonMessage, details) {
    try {
      const newModels = [];
      let requestedIds = details.requestedIds;

      for (let i = 0; i < jsonMessage.Model.SendModel.length; i++) {
        const modelFromBinary = jsonMessage.Model.SendModel[i];

        let isFirstRequest = true;

        const binaryGetId = () => {
          if (isFirstRequest) {
            isFirstRequest = false;
            return modelFromBinary.modelid;
          }

          return getID();
        };

        const generatedModel = generateModel(
          modelFromBinary.modeltype,
          binaryGetId,
          upID,
          getAbbrDic,
          setLocalAbbrDic,
          // allModels,
          allModels.current,
          namingDic,
          setNamingDic,
          false,
          recordedErrorLog
        );

        const filledModelWithParams = createModelFromParamList(
          modelFromBinary,
          generatedModel,
          generateModelForParams
        );

        newModels.push(filledModelWithParams);

        requestedIds = requestedIds.filter(
          (id) => id !== modelFromBinary.modelid
        );
      }

      setModelData((oldData) => [...oldData, ...newModels]);

      if (requestedIds.length > 0) {
        throw new Error(
          "Not all requested binary models were returned from the BE"
        );
      }
    } catch (error) {
      recordedErrorLog("Error handling binary model load response: ", error);
    }
  }

  useEffect(() => {
    try {
      if (lastJsonMessage) {
        if (hasProperty(lastJsonMessage, "requestID")) {
          const requestData = retrieveRequestData(lastJsonMessage.requestID);
          if (requestData !== null) {
            switch (requestData.type) {
              case "get-model-list":
                handleModelListReponse(lastJsonMessage);
                deleteRequestDataEntry(lastJsonMessage.requestID);
                break;
              case "get-model-specs":
                handleModelSpecsReponse(lastJsonMessage);
                deleteRequestDataEntry(lastJsonMessage.requestID);
                break;
              case "binary-model-load":
                handleBinaryModelReponse(lastJsonMessage, requestData);
                deleteRequestDataEntry(lastJsonMessage.requestID);
                break;

              default:
                break;
            }
          }
        } else {
          throw new Error("Request ID was not attached to the json message.");
        }
      }
    } catch (error) {
      recordedErrorLog(
        "Last Json message handling failure in useEffect: ",
        error
      );
    }
  }, [lastJsonMessage]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        modelsRef.current &&
        !modelsRef.current.contains(event.target) &&
        !isDescendant(event.target)
      ) {
        setLinkingActive(false);
        setLinkingID(null);
      }
    }

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [modelsRef]);

  useEffect(() => {
    if (allLocalModels.length < 1) {
      sendJsonMessage(
        { User: currentUser.id, Model: { GetModelList: [] } },
        { type: "get-model-list" }
      );
      setLoading(true);
    } else {
      // setAllModels(allLocalModels);
      allModels.current = allLocalModels;
      allModelsReceived = deepCopy(allLocalModels);
      setModalList(
        constructModelList(allModelsReceived, handleModalEntryClick)
      );
      setLoading(false);
    }
  }, []);

  function renameModel(newName, ID) {
    setNamingDic({ ...namingDic, [ID]: newName });
    setModelData(updateDisplayName(modelData, ID, newName));
    propagateRenameToGraphs(newName, ID);
  }

  function propagateRenameToGraphs(newName, ID) {
    try {
      let graphsUpdate = deepCopy(graphs);

      graphsUpdate = graphsUpdate.map((graph) => {
        const updatedPlotData = graph.plotData.map((data) => {
          if (hasProperty(data, "modelId") && data.modelId === ID) {
            return { ...data, name: newName };
          }
          return data;
        });
        return { ...graph, plotData: updatedPlotData };
      });

      setGraphs(graphsUpdate);
    } catch (error) {
      recordedErrorLog("Rename propagation failure: ", error);
    }
  }

  function deleteModel(ID, unlinked) {
    try {
      let idToUse = ID;
      let modelsToUse = deepCopy(modelData);

      if (unlinked) {
        idToUse = idAfterUnlink - 1;
        modelsToUse = modelsAfterUnlink;
      }

      const modelToDelete = getModelById(idToUse, modelsToUse);
      let groupsCopy = deepCopy(valueGroups);
      const containedGroupsInModel = allContainedGroups(modelToDelete);
      groupsCopy = groupsCopy.map((group) => {
        if (hasProperty(containedGroupsInModel, group.groupNumber)) {
          const remainingMembers =
            group.memberCount -
            containedGroupsInModel[group.groupNumber].memberCount;
          if (remainingMembers <= 0) {
            return {
              ...group,
              value: null,
              hardMax: null,
              hardMin: null,
              memberCount: 0,
            };
          } else {
            return {
              ...group,
              memberCount: remainingMembers,
            };
          }
        } else {
          return group;
        }
      });

      let cleanedList = deleteObjectsByID(modelsToUse, idToUse);
      cleanedList = removeModelsFromTablesById(cleanedList, idToUse);
      refreshLinks(cleanedList);
      setModelData(cleanedList);
      clearGraphsAfterModelDelete(modelToDelete);
      clearChi2AfterModelDelete(idToUse);

      setValueGroups(groupsCopy);
    } catch (error) {
      recordedErrorLog("Model deletion failure: ", error);
    }
  }

  function clearGraphsAfterModelDelete(modelToDelete) {
    const graphsCopy = deepCopy(graphs);
    // we use an object here, because it lets use to do edits with reference not changing in recursive functions
    const update = {
      needed: false,
    };

    clearGraphs(modelToDelete, graphsCopy, update);

    if (update.needed) {
      setGraphs(graphsCopy);
    }
  }

  function clearGraphs(modelToDelete, graphsCopy, update) {
    try {
      const ID = modelToDelete.FE_ID;

      for (let i = 0; i < graphsCopy.length; i++) {
        const graph = graphsCopy[i];

        if (
          graph.containedModels.length > 0 &&
          graph.containedModels.some((model) => model.modelId === ID)
        ) {
          update.needed = true;

          graphsCopy[i].containedModels = graphsCopy[i].containedModels.filter(
            (model) => model.modelId !== ID
          );
          graphsCopy[i].plotData = graphsCopy[i].plotData.filter((plot) => {
            if (hasProperty(plot, "modelId")) {
              if (plot.modelId === ID) {
                return false;
              }
            }
            return true;
          });
        }
      }

      if (modelToDelete.subModels.length > 0) {
        for (let i = 0; i < modelToDelete.subModels.length; i++) {
          const subModel = modelToDelete.subModels[i];
          clearGraphs(subModel, graphsCopy, update);
        }
      }
    } catch (error) {
      recordedErrorLog("Graph clearance failure: ", error);
    }
  }

  function clearChi2AfterModelDelete(ID) {
    try {
      const termsCopy = deepCopy(chi2Terms);

      const updatedTerms = [];
      const remainingIDs = [];

      for (let i = 0; i < termsCopy.length; i++) {
        const term = termsCopy[i];
        if (term.modelId !== ID) {
          updatedTerms.push(term);
          remainingIDs.push(term.id);
        }
      }

      const selectedTermsUpdate = selectedChi2Terms.filter((id) =>
        remainingIDs.includes(id)
      );

      setSelectedChi2Terms(selectedTermsUpdate);
      setChi2Terms(updatedTerms);
    } catch (error) {
      recordedErrorLog("Chi2 clearance failure: ", error);
    }
  }

  function unlinkModel(indexesToEl) {
    // using this function instead of setter because of useState setter delay -
    // the setter is setting the value in parallel to the main web app thread and
    // therefore it is not finished when delete function needs it
    function setNewNextID(ID) {
      idAfterUnlink = ID;
      setModelNextID(ID);
    }
    try {
      const indexes = indexesToEl.split(";").map((char) => parseInt(char));
      const changed = unlinkModelList(
        deepCopy(modelData),
        indexes,
        modelNextID,
        setNewNextID,
        getAbbrDic,
        namingDic,
        setLocalAbbrDic,
        setNamingDic
      );
      refreshLinks(changed);
      //same about delay here
      modelsAfterUnlink = deepCopy(changed);
      setModelData(changed);
    } catch (error) {
      recordedErrorLog("Model unlinking failure: ", error);
    }
  }

  function canLink(ID, reffitID) {
    if (
      hasParentOrChildWithID(modelData, linkingID, reffitID, ID) ||
      linkingID == ID ||
      reffitID != linkingReffitID
    ) {
      return false;
    } else {
      return true;
    }
  }

  function startLinking(ID, reffitID) {
    try {
      if (!linkingActive) {
        setLinkingActive(true);
        setLinkingID(ID);
        setLinkingReffitID(reffitID);
      } else {
        if (
          linkingID != ID &&
          !hasParentOrChildWithID(modelData, linkingID, linkingReffitID, ID) &&
          reffitID === linkingReffitID
        ) {
          // Resets the model undo, since after linking, it's step too far to undo the change
          setUndoModels((old) =>
            old.filter((oldModel) => oldModel.FE_ID !== linkingID)
          );

          const modelFound = getModelById(ID, modelData);
          setLinked(modelFound);

          let modelDataToUse = modelData;

          const probableUpdate = updateRecParamsAndRows(
            modelData,
            linkingID,
            modelFound
          );

          if (probableUpdate !== null) {
            modelDataToUse = probableUpdate;
          }

          const replacedList = replaceModelById(
            modelDataToUse,
            modelFound,
            linkingID
          );
          
          setModelData(replacedList);
          setLinkingActive(false);
          setLinkingID(null);
        }
      }
    } catch (error) {
      recordedErrorLog("Linking initiation failure: ", error);
    }
  }

  function isLinkActive() {
    return linkingActive;
  }

  function addSubModel(ID, linked) {
    try {
      if (linked) {
        const newList = linkedSubModelAdd(
          modelData,
          ID,
          getID,
          // allModels,
          allModels.current,
          upID,
          getAbbrDic,
          setLocalAbbrDic,
          namingDic,
          setNamingDic,
          recordedErrorLog,
          null
        );
        setModelData(newList);
      } else {
        const newList = addSubModelToModels(
          modelData,
          ID,
          getID,
          // allModels,
          allModels.current,
          upID,
          getAbbrDic,
          setLocalAbbrDic,
          namingDic,
          setNamingDic,
          null,
          recordedErrorLog,
          null
        );
        setModelData(newList);
      }
    } catch (error) {
      recordedErrorLog("Sub model addition failure: ", error);
    }
  }

  // modelsForDisplay = createModelsForDisplay(
  //   modelData,
  //   renameModel,
  //   startLinking,
  //   isLinkActive,
  //   deleteModel,
  //   addSubModel,
  //   unlinkModel,
  //   canLink,
  //   namingDic
  // );

  const handleAddModel = () => {
    try {
      setModalIsOpen(true);

      const modalPosition = {
        top: modelsRef.current != null ? modelsRef.current.offsetTop : 0,
        right: "auto",
        left: vwToPixels(17),
      };
      const modalSize = { width: 285, height: 350 };

      const adjusted = adjustModalPositionAndSize(modalPosition, modalSize);

      setModalPlaceAndSize(adjusted);
    } catch (error) {
      recordedErrorLog("Model adding failure: ", error);
    }
  };

  const processFiles = async (files, attempt = 1) => {
    const maxAttempts = 3; // Attempt number in case allLocalModels is not loaded in yet
    const delayBetweenAttempts = 1500; // 1.5 seconds

    let jsonAndTxtFiles = [];
    let rfmFiles = [];

    try {
      // Separate the files into two lists: one for JSON and TXT, and another for RFM
      jsonAndTxtFiles = files.filter(
        (file) =>
          file.name.endsWith(".json") ||
          file.name.endsWith(".txt") ||
          file.name.endsWith(".JSON") ||
          file.name.endsWith(".TXT")
      );
      rfmFiles = files.filter(
        (file) => file.name.endsWith(".rfm") || file.name.endsWith(".RFM")
      );
    } catch (error) {
      recordedErrorLog("Error separating files: ", error);
    }

    try {
      if (jsonAndTxtFiles.length > 0) {
        const filesData = await Promise.all(
          jsonAndTxtFiles.map((file) => simpleReadFile(file))
        );
        const parsedData = JSON.parse(filesData[0].content);

        if (allLocalModels.length > 0) {
          if (
            allLocalModels.some(
              (model) => model.reffit_id === parsedData.modeltype
            )
          ) {
            const generatedModel = generateModelFromParams(parsedData);
            addModelEntryFromFile(generatedModel);
          } else {
            limitedToast("Model authentication issue.");
            generateWarningObject(
              "Your uploaded model could not be found in your subscribed model list. If you believe this is a mistake, please contact support.",
              2,
              setWarnings,
              setNewWarningCount
            );
          }
        } else {
          // This part is not really tested, because model loading is very very fast for now
          if (attempt < maxAttempts) {
            setTimeout(() => {
              processFiles(files, attempt + 1);
            }, delayBetweenAttempts);
          } else {
            limitedToast("Model authentication issue.");
            generateWarningObject(
              "Your uploaded model could not be found in your subscribed model list. Data took too long to load or there's another issue. If you believe this is a mistake, please contact support.",
              2,
              setWarnings,
              setNewWarningCount
            );
          }
        }
      }
    } catch (error) {
      recordedErrorLog("ERROR FILE PROCESS: ", error);
      limitedToast("Selected file could not be processed.");
      generateWarningObject(
        "Selected file could not be processed.",
        2,
        setWarnings,
        setNewWarningCount
      );
    } finally {
      if (rfmFiles && rfmFiles.length > 0) {
        processRfmFiles(rfmFiles);
      }

      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    }
  };

  const processRfmFiles = async (rfmFiles) => {
    try {
      const rfmData = await Promise.all(
        rfmFiles.map((file) => simpleReadFile(file))
      );

      const sendModel = [];
      const requestedIds = [];

      // Handle RFM file processing logic here
      rfmData.forEach((fileData) => {
        const base64Content = arrayBufferToBase64(fileData.content);
        const newIdForModel = getID();
        // requestedBinary.current = [...requestedBinary.current, newIdForModel];
        requestedIds.push(newIdForModel);
        upID();
        sendModel.push({
          modelid: newIdForModel,
          binary: base64Content,
        });
      });

      const payload = {
        User: currentUser.id,
        Model: {
          SendModel: sendModel,
        },
      };
      sendJsonMessage(payload, {
        type: "binary-model-load",
        requestedIds: requestedIds,
      });
    } catch (error) {
      recordedErrorLog("ERROR RFM FILE PROCESS: ", error);
      limitedToast("Selected RFM file could not be processed.");
      generateWarningObject(
        "Selected RFM file could not be processed.",
        2,
        setWarnings,
        setNewWarningCount
      );
    }
  };

  function generateModelFromParams(loadedParams) {
    try {
      const generatedModel = generateModel(
        loadedParams.modeltype,
        getID,
        upID,
        getAbbrDic,
        setLocalAbbrDic,
        // allModels,
        allModels.current,
        namingDic,
        setNamingDic,
        false,
        recordedErrorLog
      );

      const filledModelWithParams = createModelFromParamList(
        loadedParams,
        generatedModel,
        generateModelForParams
      );

      return filledModelWithParams;
    } catch (error) {
      recordedErrorLog("Model generation from parameters failure: ", error);
    }
  }

  const addModelEntryFromFile = (modelFromFile) => {
    try {
      let hasGroups = false;

      for (let i = 0; i < modelFromFile.modelParams.length; i++) {
        const param = modelFromFile.modelParams[i];
        if (hasProperty(param, "group")) {
          hasGroups = true;
          break;
        }
      }

      if (!hasGroups && hasProperty(modelFromFile, "recParams")) {
        for (let i = 0; i < modelFromFile.recParams.length; i++) {
          const paramRow = modelFromFile.recParams[i];

          for (let j = 0; j < paramRow.length; j++) {
            const param = paramRow[j];
            if (hasProperty(param, "group")) {
              hasGroups = true;
              break;
            }
          }
          if (hasGroups) {
            break;
          }
        }
      }

      if (hasGroups) {
        let clashingGroups = {};
        let groupsUpdate = deepCopy(valueGroups);

        // Now we check what groups are in the loaded model and if there are any clashes with current groups
        // First we check non recuring parameters
        const checkedNonRecParams = collectClashingGroupsFromNonRecParams(
          modelFromFile,
          valueGroups,
          groupsUpdate
        );
        clashingGroups = checkedNonRecParams.clashingGroups;
        groupsUpdate = checkedNonRecParams.groupsUpdate;

        // Now with double for we check for recuring parameters
        const checkedRecParams = collectClashingGroupsFromRecParams(
          modelFromFile,
          valueGroups,
          clashingGroups,
          groupsUpdate
        );

        clashingGroups = checkedRecParams.clashingGroups;
        groupsUpdate = checkedRecParams.groupsUpdate;

        const clashignGroupNumbers = Object.keys(clashingGroups);

        // We check here if there are any values loaded in that have clashing groups
        if (clashignGroupNumbers.length > 0) {
          // We found that there are clashing groups and we will have to initiate merging procedure
          setClashingModel(modelFromFile);
          setClashingGroups(clashingGroups);
          setUpdatedGroupsWithNoClash(groupsUpdate);
        } else {
          // We have no clashing groups here, so we can proceed with adding the model
          const generatedModel = generateModelFromFile(
            modelFromFile,
            getID,
            upID,
            getAbbrDic,
            setLocalAbbrDic,
            namingDic,
            setNamingDic
          );
          const modelDataToChange = deepCopy(localModelsDataRef.current);
          modelDataToChange.push(generatedModel);
          setModelData(modelDataToChange);
          setValueGroups(groupsUpdate);
        }
      } else {
        const generatedModel = generateModelFromFile(
          modelFromFile,
          getID,
          upID,
          getAbbrDic,
          setLocalAbbrDic,
          namingDic,
          setNamingDic
        );
        const modelDataToChange = deepCopy(localModelsDataRef.current);
        modelDataToChange.push(generatedModel);
        setModelData(modelDataToChange);
      }
    } catch (error) {
      recordedErrorLog("Adding model from file has failed: ", error);
    }
  };

  const handleLoadModel = () => {
    fileInputRef.current.click();
  };

  // Loaded model Clashing solutions:
  // Reject
  function rejectClash() {
    resetClashModal();
  }

  // Solve
  function solveClash(updatedValGroups, uploadedModel) {
    try {
      // Updating model after solving the clash
      const generatedModel = generateModelFromFile(
        uploadedModel,
        getID,
        upID,
        getAbbrDic,
        setLocalAbbrDic,
        namingDic,
        setNamingDic
      );
      const modelDataToChange = deepCopy(localModelsDataRef.current);
      modelDataToChange.push(generatedModel);
      setValueGroups(updatedValGroups);

      // UPDATE MODELS WITH LATEST GROUP CHANGE!
      const updatedModelsObj = updateModelsOnGroupChanges(
        modelDataToChange,
        updatedValGroups
      );
      setUpdatedModelFE_IDs((oldIDS) => {
        return [...oldIDS, ...updatedModelsObj.updatedIds];
      });
      setModelData(updatedModelsObj.udpatedModels);

      resetClashModal();
    } catch (error) {
      recordedErrorLog("Clash solving has failed: ", error);
    }
  }

  function resetClashModal() {
    handleCloseClashModal();
    setClashingModel(null);
    setClashingGroups(null);
  }

  return (
    <div className="models" ref={modelsRef}>
      <div className="modelsButtonSection">
        {isAuthReady &&
        currentUser.email !== "demo@speqqle.com" &&
        currentUser.email !== "zbjdnmgrklqkaeumso@cwmxc.com" ? (
          <>
            <Button
              variant="contained"
              size="small"
              sx={{ m: 1 }}
              onClick={() => handleAddModel()}
              className="modelsButton"
              id="add-model-button"
              disabled={isFitOngoing}
            >
              Add Model
            </Button>
            <Button
              variant="contained"
              size="small"
              sx={{ m: 1 }}
              onClick={() => handleLoadModel()}
              className="modelsButton"
              id="load-model-button"
              disabled={isFitOngoing}
            >
              Load Model
            </Button>
            <input
              ref={fileInputRef}
              type="file"
              accept=".json,.txt,.rfm"
              onChange={(e) =>
                handleFileChange(
                  e,
                  ["json", "JSON", "txt", "TXT", "rfm", "RFM"],
                  processFiles
                )
              }
              style={{ display: "none" }}
              data-testid="model-file-input"
            />
          </>
        ) : (
          <div className="demoOnlyWarning" id="restricted-model-load-demo">
            Custom Model loading is for premium users only.
          </div>
        )}
      </div>
      {linkingActive ? (
        <div className="linkingNote">Click on another model to link</div>
      ) : (
        <></>
      )}
      {/* <div className="modelList">{modelsForDisplay}</div> */}
      <div className="modelList">{modelsToDisplay}</div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={handleCloseModal}
        shouldCloseOnOverlayClick={true}
        contentLabel="Model Modal"
        appElement={modelsRef.current}
        style={{
          content: {
            width: modalPlaceAndSize.width,
            height: modalPlaceAndSize.height,
            top: modalPlaceAndSize.top,
            left: modalPlaceAndSize.left,
            right: modalPlaceAndSize.right,
          },
          overlay: {
            backgroundColor: "transparent",
            zIndex: "9000",
          },
        }}
      >
        <div className="modalList" id="load-model-list">
          {!loading &&
          allModels.current != null &&
          allModels.current.length > 0 ? (
            modalList
          ) : allModels.current != null && allModels.current.length === 0 ? (
            <div>
              You currently have no access to any models. Please subscribe for a
              package or contact an admin.
            </div>
          ) : (
            <div>Loading ...</div>
          )}
        </div>
      </Modal>
      <Modal
        isOpen={clashModalIsOpen}
        onRequestClose={handleCloseClashModal}
        shouldCloseOnOverlayClick={false}
        contentLabel="Clashing Modal"
        appElement={modelsRef.current}
        id="clashModal"
        style={{
          content: {
            width: "80vw",
            height: "60vh",
            top: `20%`,
            left: "10%",
            right: "auto",
          },
          overlay: {
            zIndex: "8000",
          },
        }}
      >
        <GroupClashWindow
          clashingGroups={clashingGroups}
          clashingModel={clashingModel}
          valueGroups={upadatedGroupsWithNoClash}
          solveClash={solveClash}
          rejectClash={rejectClash}
        />
      </Modal>
    </div>
  );
}
