import React from "react";

function FAQPage() {
  return (
    <div className="faqPage">
      <h1>F.A.Q.</h1>
      <h2>Some very important question?</h2>
      <p>
        Very good answer to the question with some examples and explanations.
        Here is a list of some details:
      </p>
      <ul>
        <li>Detail 1 very important</li>
        <li>Detail 2 some interesting things here</li>
        <li>Detail 3 you must not forget this</li>
        <li>Detail 4 this might interest you, or not</li>
      </ul>
      <h2>Some very important question?</h2>
      <p>
        Very good answer to the question with some examples and explanations.
        Here is a list of some details:
      </p>
      <ul>
        <li>Detail 1 very important</li>
        <li>Detail 2 some interesting things here</li>
        <li>Detail 3 you must not forget this</li>
        <li>Detail 4 this might interest you, or not</li>
      </ul>
      <h2>Some very important question?</h2>
      <p>
        Very good answer to the question with some examples and explanations.
        Here is a list of some details:
      </p>
      <ul>
        <li>Detail 1 very important</li>
        <li>Detail 2 some interesting things here</li>
        <li>Detail 3 you must not forget this</li>
        <li>Detail 4 this might interest you, or not</li>
      </ul>
    </div>
  );
}

export default FAQPage;
