import React, { useState, useEffect } from "react";
// import { Button, TextField } from "@mui/material";
import { Button } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
// import VisibilityIcon from "@mui/icons-material/Visibility";
// import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
// import InputAdornment from "@mui/material/InputAdornment";
// import IconButton from "@mui/material/IconButton";
import PasswordValidator from "password-validator";
import { api } from "../utils/authActions";
import "./passwordReset.scss";
import PasswordEntry from "../components/adminComponents/PasswordEntry";

const PasswordReset = () => {
  let query = new URLSearchParams(useLocation().search);
  const token = query.get("token");
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  // const [confirmPassword, setConfirmPassword] = useState("");
  // const [showPassword, setShowPassword] = useState(false);
  // const [passGood, setPassGood] = useState(true);
  // const [passMissmatch, setPassMissmatch] = useState(false);
  const [resetFailed, setResetFailed] = useState(false);
  const [resetSuccessful, setResetSuccessful] = useState(false);

  const schema = new PasswordValidator();
  schema
    .is()
    .min(8) // Minimum length 8
    .is()
    .max(100) // Maximum length 100
    .has()
    .uppercase() // Must have uppercase letters
    .has()
    .lowercase() // Must have lowercase letters
    .has()
    .digits(2) // Must have at least 2 digits
    .has()
    .not()
    .spaces() // Should not have spaces
    .is()
    .not()
    .oneOf(["Passw0rd", "Password123"]); // Blacklist these values

  useEffect(() => {
    if (resetSuccessful) {
      setTimeout(() => {
        navigate("/");
      }, 3000);
    }
  }, [resetSuccessful]);

  // const handlePasswordChange = (e) => {
  //   setPassword(e.target.value);
  // };

  // const checkPassMatch = () => {
  //   if (confirmPassword !== "" && password !== confirmPassword) {
  //     setPassMissmatch(true);
  //   } else {
  //     setPassMissmatch(false);
  //   }
  // };

  // const handlePassBlur = (e) => {
  //   const pass = e.target.value;

  //   if (confirmPassword !== "") {
  //     checkPassMatch();
  //   }

  //   if (password !== "") {
  //     if (schema.validate(pass)) {
  //       setPassGood(true);
  //     } else {
  //       setPassGood(false);
  //     }
  //   }
  // };

  // const handleClickShowPassword = () => setShowPassword((show) => !show);

  // const handleMouseDownPassword = (event) => {
  //   event.preventDefault();
  // };

  // const handleConfirmPasswordChange = (e) => {
  //   setConfirmPassword(e.target.value);
  // };

  const handleResetPassword = () => {
    if (
      password !== "" &&
      // password === confirmPassword &&
      schema.validate(password)
    ) {
      const payload = {
        token: token,
        new_password: password,
        password_confirm: password,
      };
      api
        .resetPassword(payload)
        .then(() => {
          setResetFailed(false);
          setResetSuccessful(true);
        })
        .catch(() => {
          setResetFailed(true);
          setResetSuccessful(false);
        });
    }
  };

  return (
    <div className="passwordResetPage">
      <div className="entryArea">
        <PasswordEntry
          title={"Create new password"}
          setFinalPass={setPassword}
          stacked
        />
        {/* <h2>Create new password</h2>
        <TextField
          size="small"
          label="New Password"
          value={password}
          onChange={handlePasswordChange}
          onBlur={(e) => handlePassBlur(e)}
          fullWidth
          margin="normal"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          type={showPassword ? "text" : "password"}
        />
        <TextField
          size="small"
          label="Confirm Password"
          value={confirmPassword}
          onChange={handleConfirmPasswordChange}
          onBlur={(e) => handlePassBlur(e)}
          fullWidth
          margin="normal"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          type={showPassword ? "text" : "password"}
        />
        {passMissmatch ? (
          <div className="missmatch">Passwords do not match!</div>
        ) : (
          <></>
        )}
        {!passGood ? (
          <div className="missmatch">
            Password must:
            <ul>
              <li>Be from 8 to 100 characters long</li>
              <li>Contain uppercase letter</li>
              <li>Contain lowercase letter</li>
              <li>Contain 2 digits</li>
              <li>Not contain spaces</li>
            </ul>
          </div>
        ) : (
          <></>
        )} */}
        <Button
          variant="contained"
          color="primary"
          onClick={handleResetPassword}
          disabled={token === null}
          className="resetButton"
        >
          Reset Password
        </Button>
        {token === null ? (
          <div className="missmatch">
            <b>Reset token not found!</b> Please click the link in email again
            or request password reset again.
          </div>
        ) : (
          <></>
        )}
        {resetFailed ? (
          <div className="missmatch">
            <b>Password reset failed</b>, please try again or contact support.
          </div>
        ) : (
          <></>
        )}
        {resetSuccessful ? (
          <div className="success">
            <b>Reset successful</b>, redirecting to login ...
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default PasswordReset;
