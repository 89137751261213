import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
// import { useLocation } from "react-router-dom";
import { api } from "../utils/authActions";
import "./confirmEmail.scss";

function ConfirmEmail() {
  let query = new URLSearchParams(useLocation().search);
  const token = query.get("token");
  const [text, setText] = useState("Email Confirmation Page");
  const navigate = useNavigate();

  useEffect(() => {
    if (token !== null && token !== undefined) {
      api
        .confirmEmail(token)
        .then(() => {
          setText("Email confirmation successful! Redirecting ...");
          setTimeout(() => {
            navigate("/");
          }, 3000);
        })
        .catch((err) => {
          console.log("ERROR: ", err);
          setText("Email confirmation unsuccessful! Please contact support.");
        });
    }
  }, []);

  return (
    <div className="confirmEmailPage">
      <div className="text">{text}</div>
    </div>
  );
}

export default ConfirmEmail;
