import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { GeneralContext } from "../../../context/GeneralContext";
import { api } from "../../../utils/authActions";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import "./polling.scss";
import { Button } from "@mui/material";
import { formatDate } from "../../../utils/helpers";
import toast from "react-hot-toast";

function PollSolver(props) {
  const { question, pollId, endDate, handleSolved } = props;
  const { authToken, isAuthReady } = useContext(AuthContext);
  const { recordedErrorLog } = useContext(GeneralContext);
  const [answers, setAnswers] = useState([]);
  const [selectedAnswer, setSelectedAnswer] = useState(null);

  useEffect(() => {
    if (isAuthReady) {
      api
        .getPollAnswers(authToken, pollId)
        .then((response) => {
          setAnswers(response.data);
          setSelectedAnswer(null);
        })
        .catch((err) => {
          {
            console.log("error getting poll answers: ", err);
            toast.error("Could not retrieve poll answers.");
          }
        });
    }
  }, [isAuthReady, pollId]);

  const handleSolve = () => {
    try {
      if (selectedAnswer !== null) {
        const payload = {
          poll_id: pollId,
          answer_local_id: selectedAnswer,
        };

        api
          .answerPoll(authToken, payload)
          .then((response) => {
            console.log("answer to poll solve: ", response);
          })
          .catch((err) => {
            recordedErrorLog(
              "THERE WAS AN ERROR IN SUBMITTING POLL RESPONSE: ",
              err
            );
            toast.error("Could not submit poll response.");
          });

        handleSolved(pollId);
      }
    } catch (error) {
      recordedErrorLog("Solving handler failure: ", error);
    }
  };

  return (
    <div className="pollSolver">
      <div className="question">{question}</div>
      {answers.length > 0 ? (
        <>
          <div className="allAnswersContainer">
            {answers.map((answer) => {
              return (
                <div className="answerArea" key={answer.answer_local_id}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedAnswer === answer.answer_local_id}
                          onChange={() =>
                            setSelectedAnswer(answer.answer_local_id)
                          }
                        />
                      }
                      label={answer.answer}
                    />
                  </FormGroup>
                </div>
              );
            })}
          </div>
          <div className="solveButtonArea">
            <Button
              variant="contained"
              className="solveButton"
              onClick={() => handleSolve()}
              disabled={selectedAnswer === null}
            >
              Submit
            </Button>
          </div>
        </>
      ) : (
        <></>
      )}
      <div className="dateArea">
        <div className="title">Active till:</div>
        <div className="endDate">{formatDate(endDate)}</div>
      </div>
    </div>
  );
}

export default PollSolver;
