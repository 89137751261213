import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import "./profilePages.scss";

function WelcomePage() {
  const navigate = useNavigate();

  return (
    <div className="welcomePage">
      <div className="message">Welcome to Speqqle!</div>
      <div className="login">
        <Button
          variant="contained"
          className="loginButton"
          onClick={() => navigate("/")}
        >
          Login
        </Button>
      </div>
    </div>
  );
}

export default WelcomePage;
