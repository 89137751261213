import React, { useState, useContext, useEffect } from "react";
import Grid from "@mui/material/Grid";
import ToggleButton from "../commonComponents/ToggleButton";
import Parameters from "./parameters/Parameters";
import FitSection from "./fit/FitSection";
import { DashboardContext } from "../../context/DashboardContext";
import { GeneralContext } from "../../context/GeneralContext";
import "./rightSide.scss";
import { isAllFalse } from "../../utils/helpers";

function RightSide(props) {
  const { setShowOtherPanel } = props;
  const { sectionsStatus, setSectionsStatus, callFromGraph, setCallFromGraph } =
    useContext(DashboardContext);
  const { recordedErrorLog } = useContext(GeneralContext);
  const [gridWidth, setGridWidth] = useState(2);

  function handleSection(isOpen) {
    if (isOpen) {
      setShowOtherPanel(false);
      setGridWidth(6);
    } else {
      setShowOtherPanel(true);
      setGridWidth(2);
    }
  }

  useEffect(() => {
    if (callFromGraph) {
      setCallFromGraph(false);
    }
  }, [callFromGraph]);

  useEffect(() => {
    handleSection(!isAllFalse(sectionsStatus));
  }, [sectionsStatus]);

  function handleParamsStatus(isOpen, type) {
    try {
      let updatedSection = { ...sectionsStatus };
      switch (type) {
        case "Fit":
          updatedSection = { ...updatedSection, fitOpen: isOpen };
          break;
        case "Param":
          updatedSection = { ...updatedSection, paramOpen: isOpen };
          break;
        default:
          updatedSection = {
            paramOpen: false,
            fitOpen: false,
          };
          setShowOtherPanel(true);
          setGridWidth(2);
          break;
      }

      setSectionsStatus(updatedSection);
    } catch (error) {
      recordedErrorLog("Params status handler failure: ", error);
    }
  }

  return (
    <Grid item xs={gridWidth} sx={{ textAlign: "right" }}>
      <div className="toggleButtonContainerRight">
        <ToggleButton
          content={<Parameters fitOpen={sectionsStatus.fitOpen} />}
          title={"Parameters"}
          id="parameters-switch"
          position={"right"}
          type={"Param"}
          handleStatusChange={handleParamsStatus}
          needSlideOutClose={true}
          fitContent
          callFromGraph={callFromGraph}
          noBgNoBorder
        />
        <ToggleButton
          content={<FitSection />}
          white
          title={"Fit"}
          id="fit-switch"
          position={"right"}
          type={"Fit"}
          handleStatusChange={handleParamsStatus}
          needSlideOutClose={true}
          fitContent
        />
      </div>
    </Grid>
  );
}

export default RightSide;
