import React, { useState, useEffect, useContext, useRef } from "react";
import { AuthContext } from "../../context/AuthContext";
import { GeneralContext } from "../../context/GeneralContext";
import { api } from "../../utils/authActions";
import UserData from "../../components/adminComponents/UserData";
import { Button } from "@mui/material";
import Modal from "react-modal";
import ChangesCheck from "../../components/adminComponents/ChangesCheck";
import ConfirmationScreen from "../../components/commonComponents/ConfirmationScreen";
import "./profilePages.scss";
import PasswordEntry from "../../components/adminComponents/PasswordEntry";
import HtmlTooltip from "../../components/commonComponents/HtmlTooltip";

function Details() {
  const [isLoading, setIsLoading] = useState(true);
  const { authToken, setCurrentUser } = useContext(AuthContext);
  const { recordedErrorLog } = useContext(GeneralContext);
  const [data, setData] = useState(null);
  const [fullName, setFullName] = useState(null);
  const [email, setEmail] = useState(null);
  const [newsLetter, setNewsletter] = useState(null);
  const detailRef = useRef();
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [changesList, setChangesList] = useState([]);
  const [confirmDelOpen, setConfirmDelOpen] = useState(false);
  const [confirmDeacOpen, setConfirmDeacOpen] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordFilled, setPasswordFilled] = useState(false);
  const elementForModal = useRef();

  useEffect(() => {
    if (authToken !== "") {
      api
        .getMe(authToken)
        .then((response) => {
          setIsLoading(false);
          setData(response.data);
          setFullName(response.data.full_name);
          setEmail(response.data.email);
          setNewsletter(response.data.newsletter);
        })
        .catch((err) => {
          console.log("THERE WAS AN ERROR: ", err);
        });
    }
  }, [authToken]);

  useEffect(() => {
    setChangesList(produceChangeList());
  }, [email, newsLetter, fullName, password]);

  const startUpdate = () => {
    setIsConfirmOpen(true);
  };

  const produceChangeList = () => {
    try {
      const list = [];

      if (email != null && email !== data.email) {
        list.push({
          title: "Email",
          prev: data.email,
          new: email,
        });
      }
      if (fullName != null && fullName !== data.full_name) {
        list.push({
          title: "Full Name",
          prev: data.full_name,
          new: fullName,
        });
      }
      if (password !== "") {
        list.push({
          title: "Password",
          prev: "***",
          new: "***",
        });
      }
      if (newsLetter != null && newsLetter !== data.newsletter) {
        list.push({
          title: "Newsletter",
          prev: data.newsletter ? "Accepted" : "Not Accepted",
          new: newsLetter ? "Accepted" : "Not Accepted",
        });
      }

      return list;
    } catch (error) {
      recordedErrorLog("Changes list production has failed: ", error);
      return [];
    }
  };

  const handleCloseConfirmModal = () => {
    setIsConfirmOpen(false);
  };

  const approveChanges = () => {
    try {
      const payload = {
        ...data,
        full_name: fullName,
        email: email,
        newsletter: newsLetter,
      };

      if (password !== "") {
        payload.password = password;
      }

      api
        .updateMe(authToken, payload)
        .then((response) => {
          setChangesList([]);
          setData(response.data);
          setCurrentUser(response.data);
        })
        .catch((err) => {
          console.log("ERROR ON UPDATE: ", err);
        });

      setIsConfirmOpen(false);
    } catch (error) {
      recordedErrorLog("Changes approval has failed: ", error);
    }
  };

  const reverseChanges = () => {
    try {
      setEmail(data.email);
      setFullName(data.full_name);
      setNewsletter(data.newsletter);
      setIsConfirmOpen(false);
    } catch (error) {
      recordedErrorLog("Changes reversal has failed: ", error);
    }
  };

  const deleteAccount = () => {
    api
      .deleteCurrentUser(authToken)
      .then((response) => {
        console.log("DELETE USER SUCCESS: ", response);
      })
      .catch((err) => {
        console.log("DELETE USER ERROR: ", err);
      });
    setConfirmDelOpen(false);
  };

  const deactivateAccount = () => {
    setConfirmDeacOpen(false);
  };

  return (
    <div className="detailsPage">
      {isLoading ? (
        "LOADING ..."
      ) : (
        <>
          <div className="userDetailsContainer" ref={detailRef}>
            <UserData
              fullName={fullName}
              setFullName={setFullName}
              email={email}
              setEmail={setEmail}
              emailVerified={data.email_confirmed}
              newsLetter={newsLetter}
              setNewsletter={setNewsletter}
              dateCreated={data.created_on}
            />
            <PasswordEntry
              title={"Change Password:"}
              setFinalPass={setPassword}
              setFilled={setPasswordFilled}
            />
            <Button
              variant="contained"
              className="updateButton"
              onClick={() => startUpdate()}
              disabled={
                changesList === undefined ||
                changesList.length < 1 ||
                (password === "" && passwordFilled)
              }
            >
              Confirm Changes
            </Button>
            <Modal
              isOpen={isConfirmOpen}
              onRequestClose={handleCloseConfirmModal}
              shouldCloseOnOverlayClick={true}
              contentLabel="Fit Modal"
              appElement={detailRef.current}
              style={{
                content: {
                  top: "35%",
                  left: "50%",
                  right: "auto",
                  bottom: "auto",
                  marginRight: "-50%",
                  transform: "translate(-50%, -35%)",
                  width: "550px",
                  height: "650px",
                },
                overlay: {
                  backgroundColor: "transparent",
                  zIndex: "100",
                },
              }}
            >
              <ChangesCheck
                changesList={changesList}
                approveChanges={approveChanges}
                reverseChanges={reverseChanges}
              />
            </Modal>
          </div>
          <div className="deleteDeact" ref={elementForModal}>
            <HtmlTooltip
              title={
                <React.Fragment>
                  <div className="soonTooltip">
                    This DELETES your account WITH ALL YOUR DATA. This action is
                    irrecoverable and once performed you cannot restore it. If
                    you want an ability to restore your account, use
                    &rsquo;Deactivate&rsquo; instead.
                  </div>
                </React.Fragment>
              }
            >
              <Button
                variant="contained"
                className="delDeactButton"
                onClick={() => setConfirmDelOpen(true)}
              >
                Delete Account
              </Button>
            </HtmlTooltip>
            <HtmlTooltip
              title={
                <React.Fragment>
                  <div className="soonTooltip">
                    This will deactivate your account and it will seem as if
                    does not exist anymore, but your data is still saved in case
                    you want to restore your account.
                  </div>
                </React.Fragment>
              }
            >
              <Button
                variant="contained"
                className="delDeactButton"
                onClick={() => setConfirmDeacOpen(true)}
                id="deactivate-account-button"
              >
                Deactivate Account
              </Button>
            </HtmlTooltip>
            <ConfirmationScreen
              onYes={deleteAccount}
              onNo={() => setConfirmDelOpen(false)}
              text={
                "Are you sure you want to DELETE your account? ALL YOUR DATA WILL BE LOST! We will not be able to recover it once the account is deleted!"
              }
              open={confirmDelOpen}
              setOpen={setConfirmDelOpen}
              element={elementForModal}
            />
            <ConfirmationScreen
              onYes={deactivateAccount}
              onNo={() => setConfirmDeacOpen(false)}
              text={"Are you sure you want to DEACTIVATE your account?"}
              open={confirmDeacOpen}
              setOpen={setConfirmDeacOpen}
              element={elementForModal}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default Details;
