import React, { useState, useContext } from "react";
import { Button, TextField } from "@mui/material";
import { GeneralContext } from "../../context/GeneralContext";
// import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import "./adminComponents.scss";

function Package(props) {
  const {
    title,
    description,
    price,
    length,
    active = false,
    expired = false,
    activeTill = "",
    admin = false,
    id,
    handleAdd,
    handlePurchaseInit,
  } = props;
  const [days, setDays] = useState(0);
  const [months, setMonths] = useState(0);
  const { recordedErrorLog } = useContext(GeneralContext);

  const handleDayChange = (e) => {
    try {
      const value = parseFloat(e.target.value);
      if (value < 0) {
        setDays(0);
      } else {
        setDays(value);
      }
    } catch (error) {
      recordedErrorLog("Day change handler has failed: ", error);
    }
  };

  const handleMonthChange = (e) => {
    try {
      const value = parseFloat(e.target.value);
      if (value < 0) {
        setMonths(0);
      } else {
        setMonths(value);
      }
    } catch (error) {
      recordedErrorLog("Month change handler has failed: ", error);
    }
  };

  return (
    <div className="packageContainer">
      <div className={`package ${expired ? "expired" : ""}`}>
        <div className={`packageTitle ${active ? "" : "offer"}`}>
          {/* <div className="titleAndQuest">
            <HelpOutlineIcon className="icon"/>
          </div> */}
          {title}
          {!active ? (
            <div
              className="activateButton"
              onClick={() => handlePurchaseInit()}
            >
              Activate
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="packageDetails">
          {description}
          <div className="moreSection">
            <div className="moreButton" role="button">
              More
            </div>
          </div>
          {active ? (
            <div className={`timeLeft`} id="active-till-indicator">
              Active till: {activeTill}
            </div>
          ) : (
            <div className="offer" id="offer-container">
              <div className="price">Cost: {price} CHF</div>
              <div className="length">Duration: {length} Months</div>
            </div>
          )}
        </div>
        {admin ? (
          <div className="adminSection">
            <div className="note">ADMIN OPTIONS:</div>
            <div className="optionsSection">
              <div className="option">
                <Button
                  variant="contained"
                  className="addButton"
                  onClick={() => handleAdd(id, days, months)}
                >
                  Add
                </Button>
                <div className="dateSelection">
                  <div className="title">Duration:</div>
                  <div className="days">
                    <TextField
                      label="Days"
                      value={days}
                      onChange={(e) => handleDayChange(e)}
                      className="timeInput"
                      size="small"
                      type="number"
                    />
                  </div>
                  <div className="monts">
                    <TextField
                      label="Months"
                      value={months}
                      onChange={handleMonthChange}
                      className="timeInput"
                      size="small"
                      type="number"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
      {expired ? (
        <div className="renewSection">
          <div
            className="renewButton"
            onClick={() => handlePurchaseInit()}
            role="button"
          >
            Renew
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default Package;
