import React, { useContext, useState, useEffect, useRef } from "react";
import Package from "../../components/adminComponents/Package";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { AuthContext } from "../../context/AuthContext";
import { GeneralContext } from "../../context/GeneralContext";
import { api } from "../../utils/authActions";
import "./profilePages.scss";
import { getPackageFromList } from "../../components/adminComponents/adminLogic";
import Modal from "react-modal";
import PollSolver from "../../components/adminComponents/polling/PollSover";

function ProfileHome() {
  const { authToken, isAuthReady, packages } = useContext(AuthContext);
  const {
    activePolls,
    setActivePolls,
    pollToSolve,
    setPollToSolve,
    recordedErrorLog,
  } = useContext(GeneralContext);
  const [activePacks, setActivePacks] = useState([]);
  const [expiredPacks, setExpiredPacks] = useState([]);
  const [purchaseModalOpen, setPurchaseModalOpen] = useState(false);
  // const [activePolls, setActivePolls] = useState([]);
  // const [pollToSolve, setPollToSolve] = useState(null);
  const profileHomeRef = useRef();

  const navigate = useNavigate();

  const handleReffitRedirect = () => {
    navigate("/dashboard");
  };

  function isTodayOrFuture(dateString) {
    try {
      const inputDate = new Date(dateString + "T00:00:00Z"); // Parse as UTC
      const today = new Date();

      // Set the hours, minutes, seconds, and milliseconds of today's date to 0
      // This way, we're only comparing the year, month, and day
      today.setHours(0, 0, 0, 0);

      return inputDate >= today;
    } catch (error) {
      recordedErrorLog("isTodayOrFuture function failure: ", error);
    }
  }

  useEffect(() => {
    if (isAuthReady) {
      api
        .getMyPackages(authToken)
        .then((response) => {
          let active = [];
          let expired = [];
          for (let i = 0; i < response.data.length; i++) {
            const pack = response.data[i];
            if (isTodayOrFuture(pack.end)) {
              active.push(pack);
            } else {
              expired.push(pack);
            }
          }
          setActivePacks(active);
          setExpiredPacks(expired);
        })
        .catch((err) => {
          recordedErrorLog("THERE WAS A PROBLEM GETTING USER PACKAGES: ", err);
        });

      api
        .getActivePolls(authToken)
        .then((response) => {
          setActivePolls(response.data);
          if (response.data.length > 0) {
            setPollToSolve(response.data[0]);
          }
        })
        .catch((err) => {
          recordedErrorLog("THERE WAS AN ERROR RETRIEVING ACTIVE POLLS: ", err);
        });
    }
  }, [isAuthReady]);

  const handlePurchaseModalClose = () => {
    setPurchaseModalOpen(false);
  };

  const handlePurchaseInit = () => {
    setPurchaseModalOpen(true);
  };

  const handleSolvedPoll = (pollId) => {
    try {
      const filteredActivePolls = activePolls.filter(
        (poll) => poll.id !== pollId
      );

      setActivePolls(filteredActivePolls);

      if (filteredActivePolls.length > 0) {
        setPollToSolve(filteredActivePolls[0]);
      } else {
        setPollToSolve(null);
      }
    } catch (error) {
      recordedErrorLog("Poll solve handler has failed: ", error);
    }
  };

  return (
    <div className="profilePage" ref={profileHomeRef}>
      <div className="redirectButtons">
        {activePacks.length > 0 ? (
          <Button
            variant="contained"
            onClick={() => handleReffitRedirect()}
            className="toReffitButton"
            id="to-dashboard"
          >
            To RefFIt <ArrowForwardIosIcon />
          </Button>
        ) : (
          <></>
        )}
      </div>
      <div className="profileHomeContainer">
        <div className="packagesSection">
          <div className="packageSecTitle">Packages:</div>
          <div className="packageArea">
            <div className="areaTitle">Active:</div>
            {activePacks.length > 0 ? (
              <div className="activePacks" id="active-packages-container">
                {activePacks.map((pack) => {
                  const foundPack = getPackageFromList(
                    packages,
                    pack.package_id
                  );
                  if (foundPack !== undefined) {
                    return (
                      <Package
                        key={pack.id}
                        title={foundPack.name}
                        description={foundPack.description}
                        activeTill={pack.end}
                        handlePurchaseInit={handlePurchaseInit}
                        active
                      />
                    );
                  } else {
                    return;
                  }
                })}
              </div>
            ) : (
              <div className="noActive">No active packages</div>
            )}
            <div className="areaTitle">Available:</div>
            {packages.map((pack) => {
              return (
                <Package
                  key={pack.id}
                  title={pack.name}
                  description={pack.description}
                  price={pack.cost}
                  length={pack.months}
                  handlePurchaseInit={handlePurchaseInit}
                />
              );
            })}
            {expiredPacks.length > 0 ? (
              <>
                <div className="areaTitle">Expired:</div>
                <div className="activePacks last">
                  {expiredPacks.map((pack) => {
                    const foundPack = getPackageFromList(
                      packages,
                      pack.package_id
                    );
                    if (foundPack !== undefined) {
                      return (
                        <Package
                          key={pack.id}
                          title={foundPack.name}
                          description={foundPack.description}
                          activeTill={pack.end}
                          handlePurchaseInit={handlePurchaseInit}
                          active
                          expired
                        />
                      );
                    } else {
                      return;
                    }
                  })}
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
        {activePolls.length > 0 ? (
          <div className="pollArea">
            <div className="pollsTitle">Active polls:</div>
            <div className="pollCounter">
              {activePolls.length} polls remaining
            </div>
            <div className="solveArea">
              {pollToSolve !== null ? (
                <PollSolver
                  question={pollToSolve.question}
                  pollId={pollToSolve.id}
                  endDate={pollToSolve.closing_date}
                  handleSolved={handleSolvedPoll}
                />
              ) : (
                <></>
              )}
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
      <Modal
        isOpen={purchaseModalOpen}
        onRequestClose={handlePurchaseModalClose}
        shouldCloseOnOverlayClick={true}
        contentLabel="Purchase Modal"
        appElement={profileHomeRef.current}
        style={{
          content: {
            width: "90vw",
            height: "90vh",
            top: "5%",
            left: "5%",
            zIndex: "1000",
          },
          overlay: {
            zIndex: "1010",
          },
        }}
      >
        <div className="purchaseModal">PURCHASE MODAL</div>
      </Modal>
    </div>
  );
}

export default ProfileHome;
