import React, { useState, useEffect, useContext } from "react";
import Modal from "react-modal";
import { adjustModalPositionAndSize, vwToPixels } from "../../../utils/helpers";
import { GeneralContext } from "../../../context/GeneralContext";
import AddIcon from "@mui/icons-material/Add";
import "./parameters.scss";

function ModelExchange(props) {
  const {
    componentRef,
    isOpen,
    setIsOpen,
    listOfOptions,
    model,
    handleModelExchange,
    handleExchangeToNewModel,
    isRec,
  } = props;
  const { recordedErrorLog } = useContext(GeneralContext);

  const [modalPlaceAndSize, setModalPlaceAndSize] = useState({
    top: "0",
    left: "0",
    height: "300px",
    width: "300px",
  });

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      try {
        const modalPosition = {
          top:
            componentRef.current != undefined
              ? componentRef.current.getBoundingClientRect().top
              : 0,
          left:
            componentRef.current != undefined
              ? componentRef.current.getBoundingClientRect().right
              : vwToPixels(17),
          right: "auto",
        };
        const modalSize = { width: 200, height: 300 };

        const adjusted = adjustModalPositionAndSize(modalPosition, modalSize);

        setModalPlaceAndSize(adjusted);
      } catch (error) {
        recordedErrorLog("Update starting has failed: ", error);
      }
    }
  }, [isOpen]);

  const handleModelClick = (id) => {
    handleModelExchange(model.FE_ID, id, isRec);
    handleCloseModal();
  };

  const handleNewModelRequest = () => {
    handleExchangeToNewModel(model.FE_ID, model.reffitID);
    handleCloseModal();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleCloseModal}
      shouldCloseOnOverlayClick={true}
      contentLabel="Model Exchange Modal"
      appElement={componentRef.current}
      style={{
        content: {
          top: modalPlaceAndSize.top,
          left: modalPlaceAndSize.left,
          right: modalPlaceAndSize.right,
          transform: `translate(-50%, -35%)`,
          width: modalPlaceAndSize.width,
          height: modalPlaceAndSize.height,
        },
        overlay: {
          backgroundColor: "transparent",
          zIndex: "9999",
        },
      }}
    >
      <div className="modelExchange">
        {listOfOptions.map((option) => {
          return (
            <div
              key={option.FE_ID}
              className="option"
              onClick={() => handleModelClick(option.FE_ID)}
            >
              {option.title}
            </div>
          );
        })}
        <div className="option new" onClick={() => handleNewModelRequest()}>
          <AddIcon />
          <div className="title">New Model</div>
        </div>
      </div>
    </Modal>
  );
}

export default ModelExchange;
