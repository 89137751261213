import * as React from "react";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

export default function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      {/* link to project description with current year */}
      <Link
        color="inherit"
        href="https://www.speqqle.ch"
      >
        Speqqle
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
