import { hasProperty } from "../../../utils/helpers";
import {
  deepCopy,
  getModelById,
  replaceModelById,
} from "../../leftSide/Models/modelLogic";

export function updateParameterChanges(params, updatedRow) {
  let paramsCopy = params.map((obj) => Object.assign({}, obj));

  for (let i = 0; i < updatedRow.length; i++) {
    // Double index here because we now have multiple tables for non rec params,
    // so first index stands for table number, and 0 is the first (and the only)
    // row in the non rec table
    const row = updatedRow[i][0];

    paramsCopy = paramsCopy.map((parameter) => {
      if (hasProperty(row, parameter.name)) {
        return { ...parameter, value: row[parameter.name] };
      } else {
        return parameter;
      }
    });
  }

  return paramsCopy;
}

export function handleCorrectCellColorParams(correctParams, valueOfCell) {
  if (correctParams != undefined) {
    if (correctParams.min != null && valueOfCell < correctParams.min) {
      return "#ee964b";
    } else if (correctParams.max != null && valueOfCell > correctParams.max) {
      return "#ee964b";
    } else if (
      typeof valueOfCell === "number" &&
      !Number.isInteger(valueOfCell) &&
      correctParams.type == "int"
    ) {
      return "#ee964b";
    }
  }
  return;
}

export function handleCorrectFontParams(correctParams) {
  if (correctParams != undefined) {
    if (correctParams.fixed) {
      return "600";
    } else if (
      Object.prototype.hasOwnProperty.call(correctParams, "customFixed") &&
      correctParams.customFixed
    ) {
      return "600";
    } else {
      return "500";
    }
  }
  return "500";
}

function deepEqual(a, b) {
  if (a === b) return true;

  if (
    typeof a !== "object" ||
    typeof b !== "object" ||
    a === null ||
    b === null
  ) {
    return false;
  }

  if (Array.isArray(a) && Array.isArray(b)) {
    if (a.length !== b.length) return false;

    for (let i = 0; i < a.length; i++) {
      if (!deepEqual(a[i], b[i])) {
        return false;
      }
    }

    return true;
  }

  const keysA = Object.keys(a);
  const keysB = Object.keys(b);

  if (keysA.length !== keysB.length) return false;

  for (let key of keysA) {
    if (!keysB.includes(key) || !deepEqual(a[key], b[key])) {
      return false;
    }
  }

  return true;
}

export function arraysOfObjectsAreEqual(arr1, arr2) {
  if (arr1.length !== arr2.length) {
    return false;
  }

  for (let i = 0; i < arr1.length; i++) {
    if (!deepEqual(arr1[i], arr2[i])) {
      return false;
    }
  }

  return true;
}

export function isParamDescendant(event) {
  if (
    event != undefined &&
    (event.className === "parameterWindow" || event.className === "modelName")
  ) {
    return true;
  } else {
    return false;
  }
}

const hasParentWithClass = (element, className) => {
  if (!element) {
    return false;
  }

  if (element.classList && element.classList.contains(className)) {
    return true;
  }

  return hasParentWithClass(element.parentElement, className);
};

const isDescendantOfReactSelect = (element) => {
  if (!element) {
    return false;
  }

  if (
    element.getAttribute &&
    element.getAttribute("data-testid") === "react-select-container"
  ) {
    return true;
  }

  return isDescendantOfReactSelect(element.parentElement);
};

export function isCellOrStickyWindow(event) {
  //These long classNames are used because it's not possible to find react-select element otherwise
  if (
    event != undefined &&
    (event.target.className == "parametersContainer" ||
      event.target.className ==
        "MuiGrid-root MuiGrid-item MuiGrid-grid-xs-6 css-1gejpqg-MuiGrid-root")
  ) {
    return false;
  } else if (
    event != undefined &&
    (event.currentTarget.activeElement.className ==
      "MuiMenuItem-root MuiMenuItem-gutters MuiButtonBase-root menuItem css-1hau5ki-MuiButtonBase-root-MuiMenuItem-root" ||
      event.currentTarget.activeElement.id == "group-select" ||
      event.currentTarget.activeElement.className ==
        "MuiMenuItem-root MuiMenuItem-gutters Mui-selected MuiButtonBase-root menuItem css-1hau5ki-MuiButtonBase-root-MuiMenuItem-root" ||
      (typeof event.target.className === "string" &&
        event.target.className.includes("menuItem")) ||
      hasParentWithClass(event.target, "tableCell") ||
      hasParentWithClass(event.target, "stickyWindow") ||
      isDescendantOfReactSelect(event.target))
  ) {
    return true;
  } else {
    return false;
  }
}

export function isInChain(targetFE_ID, idChain) {
  let found = false;

  function traverse(id) {
    if (id === targetFE_ID) {
      found = true;
      return;
    }
  }

  idChain.forEach(traverse);
  return found;
}

export function getNonRecParamsFromTableNum(params, tableIndex) {
  return params.filter((param) => {
    return param.recuring === 0 && param.displaytable === tableIndex;
  });
}

export function checkIfDefaultExpanded(dict, id) {
  if (hasProperty(dict, id)) {
    return dict[id];
  } else {
    dict[id] = true;
    return dict[id];
  }
}

export function sortAscending(arr, key) {
  return arr.sort((a, b) => a[key] - b[key]);
}

export function sortDescending(arr, key) {
  return arr.sort((a, b) => b[key] - a[key]);
}

export function checkSortOrder(arr, key) {
  let isAscending = true;
  let isDescending = true;

  for (let i = 0; i < arr.length - 1; i++) {
    if (arr[i][key] > arr[i + 1][key]) {
      isAscending = false;
    }
    if (arr[i][key] < arr[i + 1][key]) {
      isDescending = false;
    }
  }

  if (isAscending) {
    return "ascending";
  } else if (isDescending) {
    return "descending";
  } else {
    return "unsorted";
  }
}

export function getIdChainForModels(modelIds, modelData) {
  const indexChains = [];

  for (let i = 0; i < modelData.length; i++) {
    const model = modelData[i];
    deepId(model, "");
  }

  function deepId(model, parentIdices) {
    let currentPath;
    if (parentIdices === "") {
      currentPath = `${model.FE_ID}`;
    } else {
      currentPath = parentIdices + `;${model.FE_ID}`;
    }
    if (modelIds.some((id) => model.FE_ID === id)) {
      indexChains.push(currentPath);
    }
    if (model.subModels.length > 0) {
      for (let i = 0; i < model.subModels.length; i++) {
        const subModel = model.subModels[i];
        deepId(subModel, currentPath);
      }
    }
  }

  return indexChains;
}

export function getUpdatedUniqueIds(idList, modelData) {
  const idChainList = getIdChainForModels(idList, modelData);

  const uniqueChains = [...new Set(idChainList)];

  const singleString = uniqueChains.join(";");

  const separateIds = singleString.split(";");

  const uniqueIds = [...new Set(separateIds)].map((id) => parseInt(id));

  return uniqueIds;
}

export function updateLinksAfterChange(modelID, modelData) {
  const modelsCopy = deepCopy(modelData);

  let modelCount = 0;

  const checkModel = (modelList) => {
    for (let i = 0; i < modelList.length; i++) {
      const model = modelList[i];

      if (model.FE_ID === modelID) {
        modelCount++;
      }

      if (model.subModels.length > 0) {
        checkModel(model.subModels);
      }
    }
  };

  checkModel(modelsCopy);

  if (modelCount > 1) {
    return modelsCopy;
  }

  const modelToUpdate = getModelById(modelID, modelsCopy);

  modelToUpdate.linked = false;

  const updatedModels = replaceModelById(modelsCopy, modelToUpdate, modelID);

  return updatedModels;
}

export const updateRecParamsAndRows = (
  model,
  currentSubModelId,
  modelToExchangeWithId,
  displayName
) => {
  const modelCopy = deepCopy(model);

  let indexOfModelToReplace = null;

  for (let i = 0; i < modelCopy.subModels.length; i++) {
    const subMod = modelCopy.subModels[i];

    if (subMod.FE_ID === currentSubModelId) {
      indexOfModelToReplace = i;
    }
  }

  modelCopy.recParams[indexOfModelToReplace] = modelCopy.recParams[
    indexOfModelToReplace
  ].map((param) => {
    if (hasProperty(param, "FE_ID")) {
      param.FE_ID = modelToExchangeWithId;
    }
    return param;
  });

  modelCopy.recTableRows[indexOfModelToReplace].Model = displayName;

  return modelCopy;
};
