import React, { useState } from "react";
function Checkbox({ data }) {
  const [isChecked, setIsChecked] = useState(
    Object.prototype.hasOwnProperty.call(data, "value")
      ? data.value
      : data.default === 1
  );
  return (
    <div>
      <input
        type="checkbox"
        id={data.id}
        name={data.name}
        value={isChecked}
        onChange={() => setIsChecked(!isChecked)}
      />
      <label htmlFor={data.id}>{data.name}</label>
    </div>
  );
}

export default Checkbox;
