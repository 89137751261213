import React, { useState, useContext } from "react";
import { Button } from "@mui/material";
import SolvedClash from "./SolvedClash";
import ClashToSolve from "./ClashToSolve";
import { deepCopy } from "./modelLogic";
import "./clashing.scss";
import {
  hasProperty,
  produceGroupListWithModelGroups,
} from "../../../utils/helpers";
import { GeneralContext } from "../../../context/GeneralContext";

function GroupClashWindow(props) {
  const {
    clashingGroups,
    clashingModel,
    valueGroups,
    solveClash,
    rejectClash,
  } = props;

  const { recordedErrorLog } = useContext(GeneralContext);

  const [clashingList, setClashingList] = useState(
    clashingGroups !== null ? Object.keys(clashingGroups) : []
  );
  const [solvedList, setSolvedList] = useState([]);
  const [nextToSolve, setNextToSolve] = useState(
    clashingGroups !== null && Object.keys(clashingGroups).length > 0
      ? parseInt(Object.keys(clashingGroups)[0])
      : null
  );
  const [localGroups, setLocalGroups] = useState(
    produceGroupListWithModelGroups(valueGroups, clashingModel)
  );
  const [localModel, setLocalModel] = useState(clashingModel);

  const handleFinalSolve = () => {
    solveClash(localGroups, localModel);
  };

  const solve = (groupId, chosenNewGroup, solution) => {
    try {
      const modelCopy = deepCopy(localModel);
      const modelRecParams = modelCopy.recParams;
      const modelRecTableRows = modelCopy.recTableRows;
      let newGroupToWrite = groupId;
      let updatedGroups = [];

      switch (solution) {
        case "CURRENT_STAYS":
          modelCopy.modelParams = modelCopy.modelParams.map((param) => {
            if (hasProperty(param, "group") && param.group === groupId) {
              return {
                ...param,
                value: clashingGroups[groupId].currentValue,
              };
            } else {
              return param;
            }
          });

          for (let i = 0; i < modelRecParams.length; i++) {
            const paramSet = modelRecParams[i];

            for (let j = 0; j < paramSet.length; j++) {
              const param = paramSet[j];
              if (hasProperty(param, "group") && param.group === groupId) {
                modelRecParams[i][j] = {
                  ...modelRecParams[i][j],
                  value: clashingGroups[groupId].currentValue,
                };
                modelRecTableRows[i] = {
                  ...modelRecTableRows[i],
                  [modelRecParams[i][j].name]:
                    clashingGroups[groupId].currentValue,
                };
              }
            }
          }

          modelCopy.recParams = modelRecParams;
          modelCopy.recTableRows = modelRecTableRows;

          updatedGroups = localGroups.map((group) => {
            if (group.groupNumber === groupId) {
              return {
                ...group,
                memberCount:
                  group.memberCount + clashingGroups[nextToSolve].memberCount,
              };
            } else {
              return group;
            }
          });

          setLocalGroups(updatedGroups);

          setLocalModel(modelCopy);
          break;
        case "NEW_STAYS":
          updatedGroups = localGroups.map((group) => {
            if (group.groupNumber === groupId) {
              if (
                group.hardMax !== null &&
                group.hardMax < clashingGroups[groupId].incomingValue
              ) {
                return {
                  ...group,
                  value: clashingGroups[groupId].incomingValue,
                  hardMax: null,
                  memberCount:
                    group.memberCount + clashingGroups[nextToSolve].memberCount,
                };
              }
              if (
                group.hardMin !== null &&
                group.hardMin > clashingGroups[groupId].incomingValue
              ) {
                return {
                  ...group,
                  value: clashingGroups[groupId].incomingValue,
                  hardMin: null,
                  memberCount:
                    group.memberCount + clashingGroups[nextToSolve].memberCount,
                };
              }
              return {
                ...group,
                value: clashingGroups[groupId].incomingValue,
                memberCount:
                  group.memberCount + clashingGroups[nextToSolve].memberCount,
              };
            } else {
              return group;
            }
          });

          setLocalGroups(updatedGroups);
          break;
        case "NEW_GROUP":
          newGroupToWrite = chosenNewGroup;

          modelCopy.modelParams = modelCopy.modelParams.map((param) => {
            if (hasProperty(param, "group") && param.group === groupId) {
              return {
                ...param,
                group: chosenNewGroup,
              };
            } else {
              return param;
            }
          });

          for (let i = 0; i < modelRecParams.length; i++) {
            const paramSet = modelRecParams[i];

            for (let j = 0; j < paramSet.length; j++) {
              const param = paramSet[j];
              if (hasProperty(param, "group") && param.group === groupId) {
                modelRecParams[i][j] = {
                  ...modelRecParams[i][j],
                  group: chosenNewGroup,
                };
              }
            }
          }

          modelCopy.recParams = modelRecParams;

          setLocalModel(modelCopy);

          updatedGroups = localGroups.map((group) => {
            if (group.groupNumber === chosenNewGroup) {
              return {
                ...group,
                value: clashingGroups[groupId].incomingValue,
                memberCount:
                  group.memberCount + clashingGroups[nextToSolve].memberCount,
              };
            } else {
              return group;
            }
          });

          setLocalGroups(updatedGroups);
          break;

        default:
          break;
      }

      setSolvedList((old) => [
        ...old,
        {
          currentValue: clashingGroups[nextToSolve].currentValue,
          currentGroup: groupId,
          newVal: clashingGroups[nextToSolve].incomingValue,
          newGroup: newGroupToWrite,
          solution: solution,
        },
      ]);

      const copyOfClashList = clashingList;
      copyOfClashList.shift();
      setClashingList(copyOfClashList);

      if (copyOfClashList.length > 0) {
        setNextToSolve(parseInt(copyOfClashList[0]));
      } else {
        setNextToSolve(null);
      }
    } catch (error) {
      recordedErrorLog("Solution processing failure: ", error);
    }
  };

  return (
    <div className="groupClashWindow">
      <div className="solvingSection">
        {solvedList.length > 0 ? (
          <div className="solved">
            <div className="solvedHeader">Clash Solutions:</div>
            {solvedList.map((solution, index) => (
              <SolvedClash
                key={index}
                currentVal={solution.currentValue}
                currentGroup={solution.currentGroup}
                newVal={solution.newVal}
                newGroup={solution.newGroup}
                solution={solution.solution}
              />
            ))}
          </div>
        ) : (
          <></>
        )}
        {nextToSolve !== null && clashingGroups !== null ? (
          <div className="toSolve">
            <div className="conterContainer">
              <div className="counterTitle">Clashes left to solve:</div>
              <div className="counterVal">{clashingList.length}</div>
            </div>
            <ClashToSolve
              groupId={nextToSolve}
              groups={localGroups}
              currentValue={clashingGroups[nextToSolve].currentValue}
              incomingValue={clashingGroups[nextToSolve].incomingValue}
              solve={solve}
            />
          </div>
        ) : (
          <div className="noSolutions">
            All clashing groups have been solved.
          </div>
        )}
      </div>

      <div className="solutionButtons">
        <Button
          variant="contained"
          size="small"
          sx={{ m: 1 }}
          onClick={() => handleFinalSolve()}
          className="solutionButton"
          disabled={clashingList.length !== 0}
        >
          Solve Clash
        </Button>
        <Button
          variant="contained"
          size="small"
          sx={{ m: 1 }}
          onClick={() => rejectClash()}
          className="solutionButton"
        >
          Cancel Load
        </Button>
      </div>
    </div>
  );
}

export default GroupClashWindow;
