import React, { useState } from "react";
import axios from "axios";

function CrashPage(props) {
  const { text, originalError } = props;
  const [reportSuccess, setReportSuccess] = useState(false);
  const [reportSent, setReportSent] = useState(false);

  function authHeaders(token) {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  }

  async function sendErrorMessage(baseUrl, token, data) {
    return axios.post(
      `${baseUrl}/api/v1/users/contact_admin/`,
      data,
      authHeaders(token)
    );
  }

  const formatDate = () => {
    try {
      const date = new Date();
      const pad = (n) => (n < 10 ? "0" + n : n);

      const day = pad(date.getDate());
      const month = pad(date.getMonth() + 1); // Months are zero-based
      const year = date.getFullYear();
      const hours = pad(date.getHours());
      const minutes = pad(date.getMinutes());

      return `${day}/${month}/${year} ${hours}:${minutes}`;
    } catch (_) {
      return "unretrieved";
    }
  };

  function serializeError(error) {
    try {
      if (error instanceof Error) {
        return {
          name: error.name,
          message: error.message,
          stack: error.stack,
          ...error, // this will include any additional properties that might have been added to the error object
        };
      }
      return error; // if it's not an Error instance, just return it as is
    } catch (e) {
      console.error("Serialization failed: ", e);
      return "Error serialization failed";
    }
  }

  function handleErrorReport() {
    try {
      const baseUrl =
        window.sessionStorage.getItem("url") !== null
          ? JSON.parse(window.sessionStorage.getItem("url"))
          : "https://belab.speqqle.com";

      const authTokenSession = JSON.parse(
        window.sessionStorage.getItem("authToken")
      );

      const sessionErrorLog = window.sessionStorage.getItem("errorLog");
      const currentUserSession = window.sessionStorage.getItem("currentUser");

      let currentUserID = "unretrieved";

      if (currentUserSession !== null) {
        try {
          const parsedUser = JSON.parse(currentUserSession);

          if (Object.prototype.hasOwnProperty.call(parsedUser, "id")) {
            currentUserID = parsedUser.id;
          }
        } catch (_) {
          console.log("Current user parsing error.");
        }
      }

      if (sessionErrorLog !== null && authTokenSession !== null) {
        const constructedEmail = `ERROR REPORT FROM:\nUSER ID: ${currentUserID}\nTIME: ${formatDate()}\nERROR THAT CAUSED CRASH PAGE: ${JSON.stringify(
          serializeError(originalError)
        )}\nERROR LOGS: ${sessionErrorLog}`;

        const messagePayload = {
          email_title: "FE ERROR REPORT",
          email_content: constructedEmail,
        };

        sendErrorMessage(baseUrl, authTokenSession, messagePayload)
          .then(() => {
            setReportSuccess(true);
            setReportSent(true);
          })
          .catch(() => {
            setReportSuccess(false);
            setReportSent(true);
          });
      } else {
        setReportSuccess(false);
        setReportSent(true);
      }
    } catch (_) {
      setReportSuccess(false);
      setReportSent(true);
    }
  }

  return (
    <div
      style={{
        width: "95vw",
        height: "95vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "1.5em",
      }}
    >
      <div style={{ padding: "10vh 10vw", textWrap: "wrap" }}>
        {text}&nbsp;Please try refreshing the page or closing and opening the
        page again.
        <br />
        <br />
        You can do easy automatic report by clicking this button:
        <br />
        <button
          style={{
            fontSize: "0.8em",
            marginTop: "20px",
            marginBottom: "10px",
            padding: "8px 10px",
            backgroundColor: "#3b4b95",
            color: "white",
            borderRadius: "10px",
            cursor: "pointer",
          }}
          onClick={() => handleErrorReport()}
        >
          Send Report
        </button>
        <br />
        <br />
        {reportSent && !reportSuccess ? (
          <>
            Automatic reporting failed. If you can, please report this to
            support manually.
            <br />
            Support email: contact@speqqle.com
          </>
        ) : (
          <></>
        )}
        {reportSent && reportSuccess ? (
          <div
            style={{
              color: "#139429",
            }}
          >
            Automatic report sent succesfully!
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default CrashPage;
