import React, { useEffect, useState, useRef, useContext } from "react";
import Modal from "react-modal";
import {
  adjustModalPositionAndSize,
  arrayBufferToBase64,
  filterCurveDataToRange,
  generateWarningObject,
  getShortName,
  handleFileChange,
  hasProperty,
  segmentsIntoSingleCurve,
  simpleReadFile,
  splitCurveIntoSegmentsByEdges,
} from "../../../utils/helpers";
import { Button, TextField } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { DashboardContext } from "../../../context/DashboardContext";
import { WebSocketContext } from "../../../context/WebSocketContext";
import { AuthContext } from "../../../context/AuthContext";
import { GeneralContext } from "../../../context/GeneralContext";
import deleteIcon from "../../../res/icons/delete.png";
import AddIcon from "@mui/icons-material/Add";
import "./parameters.scss";
import { createModelFitPayloadParams } from "../../middle/graphLogic";
import { processFileWithOptions } from "../../leftSide/Files/fileProcessing/processingLogic";
import FileOpenIcon from "@mui/icons-material/FileOpen";

function ModalVDF(props) {
  const {
    reference,
    model,
    modalOpen,
    setModalOpen,
    hadVDFbefore = false,
    preSelectedFiles = [],
    preselectedKK,
    preselectedON,
    preselectedFitActive,
    onVdfCreate,
  } = props;
  const { uploadedFiles, setWarnings, setNewWarningCount } =
    useContext(DashboardContext);
  const { sendJsonMessage } = useContext(WebSocketContext);
  const { currentUser } = useContext(AuthContext);
  const { limitedToast, recordedErrorLog } = useContext(GeneralContext);
  const [isOn, setIsOn] = useState(preselectedON);
  const [isKK, setIsKK] = useState(preselectedKK);
  const [isFitActive, setIsFitActive] = useState(preselectedFitActive);
  const [updatedName, setUpdatedName] = useState(hadVDFbefore);
  const [changesMade, setChangesMade] = useState(false);
  const [gridChangesMade, setGridChangesMade] = useState(false);
  const [gridModalOpen, setGridModalOpen] = useState(false);
  const [selectFileModalOpen, setSelectFileModalOpen] = useState(false);
  const vdfModalRef = useRef();
  const vdfLoadRef = useRef(null);
  const [selectedFileList, setSelectedFileList] = useState(preSelectedFiles);
  const [selectableFiles, setSelectableFiles] = useState([]);
  const [newLinearNPoints, setNewLinearNPoints] = useState("");
  const [newLinearMin, setNewLinearMin] = useState("");
  const [newLinearMax, setNewLinearMax] = useState("");

  const [modalPlaceAndSize, setModalPlaceAndSize] = useState({
    top: "0",
    left: "0",
    height: "300px",
    width: "300px",
  });

  const [gridModalPlaceAndSize, setGridModalPlaceAndSize] = useState({
    top: "0",
    left: "0",
    height: "300px",
    width: "300px",
  });

  useEffect(() => {
    if (uploadedFiles && uploadedFiles.length > 0) {
      const availableFiles = [];

      for (let i = 0; i < uploadedFiles.length; i++) {
        const file = uploadedFiles[i];

        if (
          !selectedFileList.some((selectedFile) => {
            if (hasProperty(selectedFile, "linear")) {
              return false;
            }
            return selectedFile.file.ID === file.ID;
          })
        ) {
          availableFiles.push(file);
        }
      }

      setSelectableFiles(availableFiles);
    }
  }, [selectedFileList, uploadedFiles]);

  useEffect(() => {
    try {
      if (modalOpen) {
        const modalPosition = {
          top:
            reference.current != undefined
              ? reference.current.getBoundingClientRect().top
              : 0,
          left:
            reference.current != undefined
              ? reference.current.getBoundingClientRect().left
              : 0,
          right: "auto",
        };
        const modalSize = { width: 380, height: 250 };

        const adjusted = adjustModalPositionAndSize(modalPosition, modalSize);

        setModalPlaceAndSize(adjusted);
      }
    } catch (error) {
      recordedErrorLog("VDF modal open checker useEffect has failed: ", error);
    }
  }, [modalOpen]);

  useEffect(() => {
    try {
      if (gridModalOpen) {
        const modalPosition = {
          top:
            vdfModalRef.current != undefined
              ? vdfModalRef.current.getBoundingClientRect().top + 200
              : 0,
          left:
            vdfModalRef.current != undefined
              ? vdfModalRef.current.getBoundingClientRect().left
              : 0,
          right: "auto",
        };
        const modalSize = { width: 390, height: 220 };

        const adjusted = adjustModalPositionAndSize(modalPosition, modalSize);

        setGridModalPlaceAndSize(adjusted);
      }
    } catch (error) {
      recordedErrorLog(
        "VDF Grid modal open checker useEffect has failed: ",
        error
      );
    }
  }, [gridModalOpen]);

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleCloseGridModal = () => {
    setGridModalOpen(false);
  };

  const handleCloseFileSelectModal = () => {
    setSelectFileModalOpen(false);
  };

  const handleCreateVDF = () => {
    const modelParams = createModelFitPayloadParams([model]);

    const vdfParams = {
      modelid: model.FE_ID,
      kk: isKK,
      on: isOn,
      fitactive: isFitActive,
    };

    if (!updatedName) {
      const dataIds = [];
      const sourceN = [];
      const points = [];
      const min = [];
      const max = [];

      for (let i = 0; i < selectedFileList.length; i++) {
        const entry = selectedFileList[i];
        if (hasProperty(entry, "linear")) {
          points.push(parseInt(entry.linear.npoints));
          min.push(parseInt(entry.linear.min));
          max.push(parseInt(entry.linear.max));
        } else {
          dataIds.push(entry.file.ID);
          sourceN.push(parseInt(entry.redFactor));
        }
      }

      if (dataIds.length > 0) {
        vdfParams.griddata = {
          dataids: dataIds,
          source_every_n: sourceN,
        };
      }

      if (points.length > 0) {
        vdfParams.gridlinear = {
          npoints: points,
          min: min,
          max: max,
        };
      }
    }

    let payload = {
      User: currentUser.id,
      Model: {
        SendModel: modelParams,
        Vdf: [vdfParams],
      },
    };

    if (!updatedName) {
      let fileDataPayload = [];

      for (let i = 0; i < selectedFileList.length; i++) {
        if (hasProperty(selectedFileList[i], "linear")) {
          // We skip non file entriess
          continue;
        }
        const file = selectedFileList[i].file;

        let data = Object.prototype.hasOwnProperty.call(file, "dataPoints")
          ? file.dataPoints
          : processFileWithOptions(
              file.content,
              file.options,
              recordedErrorLog
            );

        data = filterCurveDataToRange(
          data,
          file.dataRangeMin,
          file.dataRangeMax
        );

        data = segmentsIntoSingleCurve(
          splitCurveIntoSegmentsByEdges(data, file.edges)
        );

        let range = {};
        range = {
          ...range,
          min: file.dataRangeMin != "" ? file.dataRangeMin : -1000,
        };
        range = {
          ...range,
          max: file.dataRangeMax != "" ? file.dataRangeMax : 1000,
        };

        const filteredCutParams = file.edges.filter(
          (param) =>
            (param.min !== undefined && param.min !== "") ||
            (param.max !== undefined && param.max !== "")
        );

        fileDataPayload.push({
          dataid: file.ID,
          data: data,
          range: range,
          edges: filteredCutParams,
        });
      }
      payload.Data = {
        SendData: fileDataPayload,
      };
    }

    let requestedVdfToSet = [];
    if (!updatedName) {
      if (hasProperty(vdfParams, "griddata")) {
        requestedVdfToSet = [
          ...requestedVdfToSet,
          { id: model.FE_ID, gridData: vdfParams.griddata },
        ];
      }

      if (hasProperty(vdfParams, "gridlinear")) {
        requestedVdfToSet = [
          ...requestedVdfToSet,
          { id: model.FE_ID, gridlinear: vdfParams.gridlinear },
        ];
      }
      onVdfCreate();
    } else {
      requestedVdfToSet = [{ id: model.FE_ID }];
    }
    sendJsonMessage(payload, {
      type: "vdf-request",
      requestedVdfs: requestedVdfToSet,
    });
    setModalOpen(false);
    setUpdatedName(true);
    setChangesMade(false);
    setGridChangesMade(false);
  };

  const handleDefineGrid = () => {
    setGridModalOpen(true);
  };

  const handleFileDelete = (file) => {
    setUpdatedName(false);
    setChangesMade(true);
    setGridChangesMade(true);
    setSelectedFileList((oldList) => {
      const updatedList = oldList.filter(
        (entry) => entry.file.ID !== file.file.ID
      );

      return updatedList;
    });
  };

  const handleFileSelect = (file) => {
    setUpdatedName(false);
    setChangesMade(true);
    setGridChangesMade(true);
    setSelectedFileList((old) => [...old, { file: file, redFactor: 1 }]);
    handleCloseFileSelectModal();
  };

  const handleRedFactChange = (e, file) => {
    const value = e.target.value;
    if (value > 0) {
      setUpdatedName(false);
      setChangesMade(true);
      setGridChangesMade(true);
      setSelectedFileList((oldList) => {
        const updatedList = oldList.map((entry) => {
          if (hasProperty(entry, "linear")) {
            return entry;
          }
          if (entry.file.ID === file.file.ID) {
            return { ...entry, redFactor: value };
          } else {
            return entry;
          }
        });

        return updatedList;
      });
    }
  };

  async function processFiles(files) {
    try {
      const rfmData = await Promise.all(
        files.map((file) => simpleReadFile(file))
      );

      const Vdf = [];
      const requestedVdfs = [];

      // Handle RFM file processing logic here
      rfmData.forEach((fileData) => {
        const base64Content = arrayBufferToBase64(fileData.content);
        Vdf.push({
          modelid: model.FE_ID,
          binary: base64Content,
        });
        requestedVdfs.push({ id: model.FE_ID });
      });

      const payload = {
        User: currentUser.id,
        Model: {
          Vdf: Vdf,
        },
      };
      sendJsonMessage(payload, {
        type: "binary-vdf-load",
        requestedVdfs: requestedVdfs,
      });
      setModalOpen(false);
    } catch (error) {
      recordedErrorLog("ERROR RFM FILE PROCESS: ", error);
      limitedToast("Selected RFM file could not be processed.");
      generateWarningObject(
        "Selected RFM file could not be processed.",
        2,
        setWarnings,
        setNewWarningCount
      );
    } finally {
      if (vdfLoadRef.current) {
        vdfLoadRef.current.value = "";
      }
    }
  }

  const handleNpointChange = (e) => {
    const value = e.target.value;

    if (value === "") {
      setNewLinearNPoints("");
    } else if (value > 1) {
      setNewLinearNPoints(parseInt(value));
    } else {
      setNewLinearNPoints(1);
    }
  };

  const handleLinearMinChange = (e) => {
    const value = e.target.value;

    if (value === "") {
      setNewLinearMin("");
    } else {
      setNewLinearMin(value);
    }
  };

  const handleLinearMaxChange = (e) => {
    const value = e.target.value;

    if (value === "") {
      setNewLinearMax("");
    } else {
      setNewLinearMax(value);
    }
  };

  const handleAddLinearGrid = () => {
    setSelectedFileList((old) => [
      ...old,
      {
        linear: {
          npoints: newLinearNPoints,
          min: newLinearMin,
          max: newLinearMax,
        },
      },
    ]);
    setNewLinearNPoints("");
    setNewLinearMin("");
    setNewLinearMax("");
    setUpdatedName(false);
    setChangesMade(true);
    setGridChangesMade(true);
  };

  return (
    <Modal
      isOpen={modalOpen}
      onRequestClose={handleCloseModal}
      shouldCloseOnOverlayClick={true}
      contentLabel="VDF Modal"
      appElement={reference.current}
      style={{
        content: {
          width: modalPlaceAndSize.width,
          height: modalPlaceAndSize.height,
          top: modalPlaceAndSize.top,
          left: modalPlaceAndSize.left,
          right: modalPlaceAndSize.right,
        },
        overlay: {
          backgroundColor: "transparent",
          zIndex: "9900",
        },
      }}
    >
      <div className="vdfModal" ref={vdfModalRef}>
        <div className="createArea">
          <Button
            variant="contained"
            className="createVDFButton"
            onClick={() => handleCreateVDF()}
            disabled={!changesMade || (selectedFileList.length === 0 && isOn)}
          >
            {`${updatedName ? "Update" : "Create"} VDF`}
          </Button>
          <FileOpenIcon
            className="loadVdfIcon"
            onClick={() => vdfLoadRef.current.click()}
          />
          <input
            ref={vdfLoadRef}
            type="file"
            accept=".rfv,.RFV"
            onChange={(e) => handleFileChange(e, ["rfv", "RFV"], processFiles)}
            style={{ display: "none" }}
          />
        </div>
        <div className="optionsArea">
          <div className="checkboxes">
            <FormGroup
              sx={{
                width: "150px",
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isKK}
                    onChange={() => {
                      if (hadVDFbefore && !gridChangesMade) {
                        setUpdatedName(true);
                      }
                      setChangesMade(true);
                      setIsKK((old) => !old);
                    }}
                  />
                }
                label={"KK"}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isOn}
                    onChange={() => {
                      if (hadVDFbefore && !gridChangesMade) {
                        setUpdatedName(true);
                      }
                      setChangesMade(true);
                      setIsOn((old) => !old);
                    }}
                  />
                }
                label={"ON"}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isFitActive}
                    onChange={() => {
                      if (hadVDFbefore && !gridChangesMade) {
                        setUpdatedName(true);
                      }
                      setChangesMade(true);
                      setIsFitActive((old) => !old);
                    }}
                  />
                }
                label={"Fit Active"}
              />
            </FormGroup>
          </div>
          <div className="gridButtonArea">
            <Button
              variant="contained"
              className="createVDFButton"
              onClick={() => handleDefineGrid()}
            >
              Define Grid
            </Button>
          </div>
        </div>
        <Modal
          isOpen={gridModalOpen}
          onRequestClose={handleCloseGridModal}
          shouldCloseOnOverlayClick={true}
          contentLabel="VDF Grid Modal"
          appElement={vdfModalRef.current}
          style={{
            content: {
              width: gridModalPlaceAndSize.width,
              height: gridModalPlaceAndSize.height,
              top: gridModalPlaceAndSize.top,
              left: gridModalPlaceAndSize.left,
              right: gridModalPlaceAndSize.right,
            },
            overlay: {
              zIndex: "9990",
            },
          }}
        >
          <div className="gridDefineModal">
            <div className="listHeader">
              <div className="name">File Name:</div>
              <div className="reduction">Reduction factor:</div>
              <div className="del" />
            </div>
            {selectedFileList.length > 0 ? (
              <div className="selectedFileList">
                {selectedFileList.map((file, index) => {
                  if (hasProperty(file, "linear")) {
                    return (
                      <div className="linearEntry" key={`${index}`}>
                        <div className="details">
                          <div className="title">Lin.</div>
                          <div className="number" title="Number of points">
                            {file.linear.npoints}
                          </div>
                          <div className="number" title="min">
                            {file.linear.min}
                          </div>
                          <div className="number" title="max">
                            {file.linear.max}
                          </div>
                        </div>
                        <div className="deleteArea">
                          <img
                            src={deleteIcon}
                            alt="Delete VDF file"
                            className="deleteButton"
                            onClick={() => handleFileDelete(file)}
                          />
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div
                        className="vdfFileEntry"
                        key={`${file.file.ID}|${index}`}
                      >
                        <div className="fileName">
                          {getShortName(file.file.name)}
                        </div>
                        <div className="reductionFactor">
                          <input
                            value={file.redFactor}
                            type="number"
                            onChange={(e) => handleRedFactChange(e, file)}
                          />
                        </div>
                        <div className="deleteArea">
                          <img
                            src={deleteIcon}
                            alt="Delete VDF file"
                            className="deleteButton"
                            onClick={() => handleFileDelete(file)}
                          />
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
            ) : (
              <></>
            )}
            <div className="addFileButtonArea">
              <div
                className="addFileButton"
                onClick={() => setSelectFileModalOpen(true)}
              >
                <AddIcon />
                <div className="addText">Add Grid Entry</div>
              </div>
            </div>
            <Modal
              isOpen={selectFileModalOpen}
              onRequestClose={handleCloseFileSelectModal}
              shouldCloseOnOverlayClick={true}
              contentLabel="VDF Grid File Select Modal"
              appElement={vdfModalRef.current}
              style={{
                content: {
                  width: gridModalPlaceAndSize.width,
                  height: gridModalPlaceAndSize.height,
                  top: gridModalPlaceAndSize.top,
                  left: gridModalPlaceAndSize.left,
                  right: gridModalPlaceAndSize.right,
                },
                overlay: {
                  zIndex: "9999",
                },
              }}
            >
              <div className="selectFilesModal">
                {selectableFiles.map((file) => (
                  <div
                    key={file.ID}
                    className="selectableFileEntry"
                    onClick={() => handleFileSelect(file)}
                  >
                    {file.name}
                  </div>
                ))}
                <div className="linearEntry">
                  <div className="title">Add Linear Grid:</div>
                  <div className="entryFields">
                    <TextField
                      label="N-Points"
                      variant="standard"
                      value={newLinearNPoints}
                      type="number"
                      className="entry"
                      onChange={(e) => handleNpointChange(e)}
                    />
                    <TextField
                      label="Min"
                      variant="standard"
                      value={newLinearMin}
                      type="number"
                      className="entry"
                      onChange={(e) => handleLinearMinChange(e)}
                    />
                    <TextField
                      label="Max"
                      variant="standard"
                      value={newLinearMax}
                      type="number"
                      className="entry"
                      onChange={(e) => handleLinearMaxChange(e)}
                    />
                  </div>
                  <div className="buttonArea">
                    <Button
                      variant="outlined"
                      onClick={() => handleAddLinearGrid()}
                    >
                      Add Linear Grid
                    </Button>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
        </Modal>
      </div>
    </Modal>
  );
}

export default ModalVDF;
