import React, { useState, useEffect, useContext } from "react";
import DOMPurify from "dompurify";
import Modal from "react-modal";
import { Button, Checkbox, FormControlLabel, TextField } from "@mui/material";
import "./windowComps.scss";
import { adjustModalPositionAndSize } from "../../../utils/helpers";
import { GeneralContext } from "../../../context/GeneralContext";

function OptionsModal(props) {
  const {
    settingsModalIsOpen,
    setSettingsModalIsOpen,
    reference,
    yAxisName,
    setYAxisName,
    xAxisName,
    setXAxisName,
    isYAxisLogLast,
    setIsYAxisLogLast,
    isYAxisLog,
    setIsYAxisLog,
    xAxisRange,
    xAxisRangeTemp,
    setXAxisRangeTemp,
    yAxisRangeTemp,
    setYAxisRangeTemp,
    yAxisRange,
    initialLayout,
    graphRef,
    id,
    updateGraphLayout,
    zIndex,
    setXAxisUpdated,
  } = props;

  const { recordedErrorLog } = useContext(GeneralContext);

  const [xAxisNameTemp, setXAxisNameTemp] = useState(
    DOMPurify.sanitize(initialLayout.xaxis.title.text, { ALLOWED_TAGS: [] })
  );
  const [yAxisNameTemp, setYAxisNameTemp] = useState(
    DOMPurify.sanitize(initialLayout.yaxis.title.text, { ALLOWED_TAGS: [] })
  );
  const [rangeChanged, setRangeChanged] = useState(false);
  const [modalPlaceAndSize, setModalPlaceAndSize] = useState({
    top: "0",
    left: "0",
    height: "300px",
    width: "300px",
  });
  const [xAxisChanged, setXAxisChanged] = useState(false);

  useEffect(() => {
    if (setSettingsModalIsOpen) {
      const modalPosition = {
        top:
          reference.current != null
            ? reference.current.getBoundingClientRect().top
            : 0,
        right: "auto",
        left:
          reference.current != null
            ? reference.current.getBoundingClientRect().left
            : 0,
      };
      const modalSize = { width: 350, height: 440 };

      const adjusted = adjustModalPositionAndSize(modalPosition, modalSize);

      setModalPlaceAndSize(adjusted);
    }
  }, [settingsModalIsOpen]);

  const handleXAxisNameChange = (e) => {
    setXAxisNameTemp(e.target.value);
  };

  const handleYAxisNameChange = (e) => {
    setYAxisNameTemp(e.target.value);
  };

  const confirmSettingsModal = () => {
    try {
      const currentGraph = graphRef.current.find((graph) => graph.id == id);
      let updateNeeded = false;
      let layoutUpdate = {};
      if (yAxisNameTemp != yAxisName) {
        setYAxisName(yAxisNameTemp);
        layoutUpdate = {
          ...layoutUpdate,
          yaxis: {
            ...currentGraph.layout.yaxis,
            title: { text: yAxisNameTemp },
          },
        };
        updateNeeded = true;
      }
      if (xAxisNameTemp != xAxisName) {
        if (isYAxisLogLast != isYAxisLog) {
          setXAxisName(xAxisNameTemp);
          setIsYAxisLogLast(isYAxisLog);
          if (isYAxisLog) {
            layoutUpdate = {
              ...layoutUpdate,
              yaxis: {
                ...currentGraph.layout.yaxis,
                type: "log",
                title: { text: xAxisNameTemp },
              },
            };
          } else {
            layoutUpdate = {
              ...layoutUpdate,
              yaxis: {
                ...currentGraph.layout.yaxis,
                type: "linear",
                title: { text: xAxisNameTemp },
              },
            };
          }
          updateNeeded = true;
        } else {
          setXAxisName(xAxisNameTemp);
          layoutUpdate = {
            ...layoutUpdate,
            xaxis: {
              ...currentGraph.layout.xaxis,
              title: { text: xAxisNameTemp },
            },
          };
          updateNeeded = true;
        }
      } else if (isYAxisLogLast != isYAxisLog) {
        setIsYAxisLogLast(isYAxisLog);
        if (isYAxisLog) {
          layoutUpdate = {
            ...layoutUpdate,
            yaxis: { ...currentGraph.layout.yaxis, type: "log" },
          };
        } else {
          layoutUpdate = {
            ...layoutUpdate,
            yaxis: { ...currentGraph.layout.yaxis, type: "linear" },
          };
        }
        updateNeeded = true;
      }

      if (rangeChanged) {
        updateNeeded = true;
        layoutUpdate = {
          ...layoutUpdate,
          xaxis: {
            ...currentGraph.layout.xaxis,
            ...layoutUpdate.xaxis,
            range: [
              parseFloat(xAxisRangeTemp.min),
              parseFloat(xAxisRangeTemp.max),
            ],
            autorange: false,
          },
          yaxis: {
            ...currentGraph.layout.yaxis,
            ...layoutUpdate.yaxis,
            range: [
              parseFloat(yAxisRangeTemp.min),
              parseFloat(yAxisRangeTemp.max),
            ],
            autorange: false,
          },
        };
        setRangeChanged(false);
      }

      if (updateNeeded) {
        updateGraphLayout(layoutUpdate);
      }

      if (xAxisChanged) {
        setXAxisUpdated(true);
        setXAxisChanged(false);
      }
      setSettingsModalIsOpen(false);
    } catch (error) {
      recordedErrorLog("Setting confirmation failure: ", error);
    }
  };

  const handleCloseSettingsModal = () => {
    setSettingsModalIsOpen(false);
    // reset temp values to the initial layout values
    setRangeChanged(false);
    setXAxisNameTemp(
      DOMPurify.sanitize(initialLayout.xaxis.title.text, { ALLOWED_TAGS: [] })
    );
    setYAxisNameTemp(
      DOMPurify.sanitize(initialLayout.yaxis.title.text, { ALLOWED_TAGS: [] })
    );
    setXAxisRangeTemp({
      min: xAxisRange.min,
      max: xAxisRange.max,
    });
  };

  const handleAxisRangeChange = (axis, minOrMax, val) => {
    try {
      if (axis === "x") {
        if (minOrMax === "min") {
          setXAxisRangeTemp((old) => {
            return { ...old, min: val };
          });
        } else {
          setXAxisRangeTemp((old) => {
            return { ...old, max: val };
          });
        }
      } else {
        if (minOrMax === "min") {
          setYAxisRangeTemp((old) => {
            return { ...old, min: val };
          });
        } else {
          setYAxisRangeTemp((old) => {
            return { ...old, max: val };
          });
        }
      }
    } catch (error) {
      recordedErrorLog("Axis range change failure: ", error);
    }
  };

  const handleAxisRangeBlur = (axis, minOrMax, val) => {
    try {
      if (axis === "x") {
        setXAxisChanged(true);
        if (minOrMax === "min") {
          if (val >= xAxisRange.max) {
            setXAxisRangeTemp((old) => {
              return { ...old, min: xAxisRange.min };
            });
          } else {
            setRangeChanged(true);
          }
        } else {
          if (val <= xAxisRange.min) {
            setXAxisRangeTemp((old) => {
              return { ...old, max: xAxisRange.max };
            });
          } else {
            setRangeChanged(true);
          }
        }
      } else {
        if (minOrMax === "min") {
          if (val >= yAxisRange.max) {
            setYAxisRangeTemp((old) => {
              return { ...old, min: xAxisRange.min };
            });
          } else {
            setRangeChanged(true);
          }
        } else {
          if (val <= yAxisRange.min) {
            setYAxisRangeTemp((old) => {
              return { ...old, max: xAxisRange.max };
            });
          } else {
            setRangeChanged(true);
          }
        }
      }
    } catch (error) {
      recordedErrorLog("Axis range blur failure: ", error);
    }
  };

  return (
    <Modal
      isOpen={settingsModalIsOpen}
      onRequestClose={handleCloseSettingsModal}
      shouldCloseOnOverlayClick={true}
      contentLabel="Model Modal"
      appElement={reference.current}
      style={{
        content: {
          width: modalPlaceAndSize.width,
          height: modalPlaceAndSize.height,
          top: modalPlaceAndSize.top,
          left: modalPlaceAndSize.left,
          right: modalPlaceAndSize.right,
        },
        overlay: {
          backgroundColor: "transparent",
          zIndex: zIndex + 200,
        },
      }}
    >
      <div className="settingsModal" id="graph-settings-modal">
        <div className="axisGroup">
          <div className="x-data">
            <div className="axisName">X Axis:</div>
            <div className="axisValues">
              <div className="axisValueSet">
                <div className="axisValueTitle">Name:</div>
                <input
                  id="x-axis-name-input"
                  value={xAxisNameTemp}
                  onChange={handleXAxisNameChange}
                />
              </div>
              <div className="axisValueSet">
                <div className="axisValueTitle">Range:</div>
                <div className="rangeVals">
                  <TextField
                    style={{
                      height: "100%",
                    }}
                    label="Min"
                    value={xAxisRangeTemp.min}
                    onChange={(e) =>
                      handleAxisRangeChange("x", "min", e.target.value)
                    }
                    onBlur={(e) =>
                      handleAxisRangeBlur("x", "min", e.target.value)
                    }
                    className="rangeValEntry"
                    size="small"
                    type="number"
                  />
                  <TextField
                    style={{
                      height: "100%",
                    }}
                    label="Max"
                    className="rangeValEntry"
                    value={xAxisRangeTemp.max}
                    onChange={(e) =>
                      handleAxisRangeChange("x", "max", e.target.value)
                    }
                    onBlur={(e) =>
                      handleAxisRangeBlur("x", "max", e.target.value)
                    }
                    size="small"
                    type="number"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="divider" />
          <div className="y-axis">
            <div className="axisName">Y Axis:</div>
            <div className="axisValues">
              <div className="axisValueSet">
                <div className="axisValueTitle">Name:</div>
                <input
                  id="y-axis-name-input"
                  value={yAxisNameTemp}
                  onChange={handleYAxisNameChange}
                />
              </div>
              <div className="axisValueSet">
                <div className="axisValueTitle">Range:</div>
                <div className="rangeVals">
                  <TextField
                    style={{
                      height: "100%",
                    }}
                    label="Min"
                    className="rangeValEntry"
                    value={yAxisRangeTemp.min}
                    onChange={(e) =>
                      handleAxisRangeChange("y", "min", e.target.value)
                    }
                    onBlur={(e) =>
                      handleAxisRangeBlur("y", "min", e.target.value)
                    }
                    size="small"
                    type="number"
                  />
                  <TextField
                    style={{
                      height: "100%",
                    }}
                    label="Max"
                    className="rangeValEntry"
                    value={yAxisRangeTemp.max}
                    onChange={(e) =>
                      handleAxisRangeChange("y", "max", e.target.value)
                    }
                    onBlur={(e) =>
                      handleAxisRangeBlur("y", "max", e.target.value)
                    }
                    size="small"
                    type="number"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="logCheckbox">
          <FormControlLabel
            control={
              <Checkbox
                onClick={() => setIsYAxisLog(!isYAxisLog)}
                checked={isYAxisLog}
              />
            }
            label="Logarithmic"
          />
        </div>
        <div className="buttonSection">
          <Button
            variant="contained"
            size="small"
            sx={{ m: 1 }}
            onClick={() => confirmSettingsModal()}
            className="confirmationButton"
            id="graph-settings-apply-button"
          >
            Apply
          </Button>
          <Button
            variant="contained"
            size="small"
            sx={{ m: 1 }}
            onClick={() => handleCloseSettingsModal()}
            className="confirmationButton"
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default OptionsModal;
