/**
 * @category LeftSide
 * @component
 * @module Files/fileProcessing/RawAndSolved
 * @description This component is displaying 2 windows, one for raw file data (limited to 10 lines) and one for
 * processed data based on user selected options. If data is processed successfully, it is displayed as a table.
 */
import React, { useContext } from "react";
import { GeneralContext } from "../../../../context/GeneralContext";
import "./fileProcessing.scss";

function RawAndSolved(props) {
  const { rawFileContent, processedContent } = props;
  const { recordedErrorLog } = useContext(GeneralContext);

  /**
   * This function takes the content of a file and returns the first 10 lines of it.
   * @function getFirstTenLines
   * @param {string} content - This is the content of the file that is being processed.
   * @returns {string} - This is the first 10 lines of the file.
   */
  const getFirstTenLines = (content) => {
    try {
      if (content === null) {
        return "";
      }
      const lines = content.split("\n");
      return lines.slice(0, 10).join("\n");
    } catch (error) {
      recordedErrorLog("First 10 lines getter failure: ", error);
    }
  };

  /**
   * This function takes the content of a file and returns a table containing the first 10 rows of the processed
   * data.
   * @function getTenTableRows
   * @param {object[]} content - This is the content of the file that has been processed.
   * @returns {JSX.Element} - This is the table containing the processed data.
   */
  const getTenTableRows = (content) => {
    try {
      // Return null if the content is empty
      if (content === "") {
        return null;
      }
      if (content === null || content.length === 0) {
        return "Could not process the file";
      }

      // Get the keys from the first object to define the table columns
      const keys = Object.keys(content[0]);

      // Create the header row
      const headerRow = (
        <tr>
          {keys.map((key) => (
            <th key={key}>
              {key === "x" ? (
                <span>
                  x cm<sup>-1</sup>
                </span>
              ) : (
                key
              )}
            </th>
          ))}
        </tr>
      );

      // Create the data rows (up to 10)
      const dataRows = content.slice(0, 10).map((item, index) => (
        <tr key={index}>
          {keys.map((key) => (
            <td key={key}>{item[key]}</td>
          ))}
        </tr>
      ));

      // Construct the table
      return (
        <table className="processedTable">
          <thead>{headerRow}</thead>
          <tbody>{dataRows}</tbody>
        </table>
      );
    } catch (error) {
      recordedErrorLog("10 table rows getter failure: ", error);
    }
  };

  const table = getTenTableRows(processedContent);

  return (
    <div className="rawAndSolved">
      <div className="contentArea">
        <div className="title">Raw Data:</div>
        <pre className="content">{getFirstTenLines(rawFileContent)}</pre>
      </div>
      <div className="contentArea">
        <div className="title">Processed Data:</div>
        {/* NEEDS TO BE PUT INTO THE TABLE! */}
        <div
          className={`content ${
            table !== null && typeof table !== "string" ? "noBorder" : ""
          }`}
        >
          {table}
        </div>
      </div>
    </div>
  );
}

export default RawAndSolved;
