import React, { useState, useContext, useRef, useEffect, useMemo } from "react";
import ParameterWindow from "./ParameterWindow";
import { DashboardContext } from "../../../context/DashboardContext";
import { GraphContext } from "../../../context/GraphContext";
import { AuthContext } from "../../../context/AuthContext";
import {
  deepCopy,
  addSubModelToModels,
  hasParentOrChildWithID,
  getModelById,
  setLinked,
  replaceModelById,
  linkedSubModelAdd,
  createModelFromParamList,
  generateModel,
} from "../../leftSide/Models/modelLogic";
import {
  checkIfDefaultExpanded,
  getUpdatedUniqueIds,
  isParamDescendant,
} from "./parameterLogic";
import StickyParameterWindow from "./StickyParameterWindow";
import "./parameters.scss";
import {
  arrayBufferToBase64,
  generateWarningObject,
  hasProperty,
  isDeepEqual,
  simpleReadFile,
  updateModelsOnGroupChanges,
} from "../../../utils/helpers";
import { WebSocketContext } from "../../../context/WebSocketContext";
import { GeneralContext } from "../../../context/GeneralContext";
import BlockingOverlay from "../../commonComponents/BlockingOverlay";

function Parameters(props) {
  const { fitOpen } = props;
  const {
    modelData,
    setModelData,
    modelNextID,
    setModelNextID,
    abbrDic,
    setAbbrDic,
    namingDic,
    setNamingDic,
    allLocalModels,
    setValueGroups,
    expandedWindows,
    setExpandedWindows,
    setWarnings,
    setNewWarningCount,
  } = useContext(DashboardContext);
  const {
    lastJsonMessage,
    sendJsonMessage,
    isFitOngoing,
    retrieveRequestData,
    deleteRequestDataEntry,
  } = useContext(WebSocketContext);
  const { setUpdatedModelFE_IDs } = useContext(GraphContext);
  const { currentUser } = useContext(AuthContext);
  const { limitedToast, recordedErrorLog } = useContext(GeneralContext);
  const [updateBacklog, setUpdateBacklog] = useState({});
  const [idChain, setIdChain] = useState([]);
  const [linkingActive, setLinkingActive] = useState(false);
  const [linkingID, setLinkingID] = useState(null);
  const [linkingReffitID, setLinkingReffitID] = useState(null);
  const [stickyWindowOpen, setStickyWindowOpen] = useState(false);
  const [modelForStickyIndexes, setModelForStickyIndexes] = useState(null);
  const [recForSticky, setRecForSticky] = useState(null);
  const [cellForSticky, setCellForSticky] = useState(null);
  const [tableIndexForSticky, setTableIndexForSticky] = useState(null);
  const [cellColumn, setCellColumn] = useState(null);
  const [cellFeId, setCellFeId] = useState(null);
  const [localExpanded, setLocalExpanded] = useState({});

  //This variable is needed to pass value and fix updates done directly in the table to sticky parameter window
  const [valueUpdate, setValueUpdate] = useState(null);
  const [fixedUpdate, setFixedUpdate] = useState(null);

  const modelDataRef = useRef(modelData);
  const parametersRef = useRef(null);
  const tempUpdatedIdsRef = useRef([]);
  // const requestedBinary = useRef(null);
  let isRendering = false;

  function generateModelForParams(reffitID) {
    const generatedModel = generateModel(
      reffitID,
      getID,
      upID,
      getAbbrDic,
      setLocalAbbrDic,
      // allModels,
      allLocalModels,
      namingDic,
      setNamingDic,
      false,
      recordedErrorLog
    );

    return generatedModel;
  }

  function handleBinaryLoad(jsonMessage) {
    try {
      const modelFromBinary = jsonMessage.Model.SendModel[0];

      const modelsCopy = deepCopy(modelData);
      const modelToUpdate = getModelById(modelFromBinary.modelid, modelsCopy);

      const filledModelWithParams = createModelFromParamList(
        modelFromBinary,
        modelToUpdate,
        generateModelForParams
      );

      const updatedWithNew = replaceModelById(
        modelsCopy,
        filledModelWithParams,
        modelFromBinary.modelid
      );

      setModelData(updatedWithNew);
      setUpdatedModelFE_IDs([modelFromBinary.modelid]);
    } catch (error) {
      recordedErrorLog(
        "Failed to handle binary file load in parameter window: ",
        error
      );
    }
  }

  useEffect(() => {
    try {
      if (lastJsonMessage) {
        if (hasProperty(lastJsonMessage, "requestID")) {
          const requestData = retrieveRequestData(lastJsonMessage.requestID);
          if (requestData !== null) {
            switch (requestData.type) {
              case "binary-load-parameter-window":
                handleBinaryLoad(lastJsonMessage);
                deleteRequestDataEntry(lastJsonMessage.requestID);
                break;
              default:
                break;
            }
          }
        } else {
          throw new Error("Request ID was not attached to the json message.");
        }
      }
    } catch (error) {
      recordedErrorLog(
        "Last Json message handling failure in useEffect: ",
        error
      );
    }
  }, [lastJsonMessage]);

  const updateNestedModel = (models, updatedModel, FE_ID) => {
    try {
      return models.map((model) => {
        if (model.FE_ID === FE_ID) {
          return updatedModel;
        }

        if (model.subModels && model.subModels.length > 0) {
          return {
            ...model,
            subModels: updateNestedModel(model.subModels, updatedModel, FE_ID),
          };
        }

        return model;
      });
    } catch (error) {
      recordedErrorLog("Nested model update failure: ", error);
    }
  };

  // We have local expansion dictionary because if we try to update Context while Parameters component is being
  // rendered, we get an error. With this we record the changes locally and only when component is redered, we
  // propagate them to the Context
  useEffect(() => {
    setExpandedWindows(localExpanded);
  }, [localExpanded]);

  useEffect(() => {
    if (!isDeepEqual(modelDataRef.current, modelData)) {
      modelDataRef.current = modelData;
    }
  }, [modelData]);

  const changeModel = (
    updatedModel,
    FE_ID,
    valGroups = null,
    fromFile = false
  ) => {
    try {
      // console.log("MODEL UPDATING ID: ", FE_ID)
      if (isRendering) {
        // console.log("PARAMETERS IN BACKLOG!!!")
        if (Object.prototype.hasOwnProperty.call(updateBacklog, FE_ID)) {
          console.log("UPDATING BACKLOG");
          setUpdateBacklog({
            ...updateBacklog,
            [FE_ID]: { ...updateBacklog[FE_ID], ...updatedModel },
          });
        } else {
          setUpdateBacklog({ ...updateBacklog, [FE_ID]: updatedModel });
        }
      } else {
        if (valGroups != null) {
          const oldModelData = deepCopy(modelDataRef.current);
          const newModelData = updateNestedModel(
            oldModelData,
            updatedModel,
            FE_ID
          );
          // const updatedModelWithGroups = handleValueGroupChanges(
          //   newModelData,
          //   valGroups
          // );
          const updatedModelWithGroups = updateModelsOnGroupChanges(
            newModelData,
            valGroups
          );
          tempUpdatedIdsRef.current = [
            ...tempUpdatedIdsRef.current,
            ...updatedModelWithGroups.updatedIds,
          ];

          if (fromFile) {
            tempUpdatedIdsRef.current = [...tempUpdatedIdsRef.current, FE_ID];
          }

          setValueGroups(valGroups);
          if (
            !isDeepEqual(oldModelData, updatedModelWithGroups.udpatedModels)
          ) {
            setUpdatedModelFE_IDs((oldIDS) => {
              const uniqueIds = getUpdatedUniqueIds(
                [...oldIDS, ...tempUpdatedIdsRef.current],
                modelDataRef.current
              );

              return uniqueIds;
            });
            // console.log("CHECKING WITH GROUPS");
            // console.log("OLD DATA WITH GROUPS: ", oldModelData);
            // console.log("NEW DATA WITH GROUPS: ", updatedModelWithGroups);
            modelDataRef.current = updatedModelWithGroups.udpatedModels;
            setModelData(modelDataRef.current);
            tempUpdatedIdsRef.current = [];
            return modelDataRef.current;
          }
        } else {
          const oldModelData = deepCopy(modelDataRef.current);
          const newModelData = updateNestedModel(
            oldModelData,
            updatedModel,
            FE_ID
          );
          if (!isDeepEqual(oldModelData, newModelData)) {
            // console.log("CHECKING WITHout GROUPS");
            // console.log("OLD DATA WITHout GROUPS: ", oldModelData);
            // console.log("NEW DATA WITHout GROUPS: ", newModelData);
            setUpdatedModelFE_IDs((oldIDS) => {
              const uniqueIds = getUpdatedUniqueIds(
                [...oldIDS, FE_ID],
                modelDataRef.current
              );

              return uniqueIds;
            });
            modelDataRef.current = newModelData;
            setModelData(() => {
              return modelDataRef.current;
            });
            tempUpdatedIdsRef.current = [];
            return modelDataRef.current;
          }
          // setModelData((prevModelData) => {
          //   const newModelData = updateNestedModel(
          //     prevModelData,
          //     updatedModel,
          //     FE_ID
          //   );
          //   return newModelData;
          // });
        }
      }
      tempUpdatedIdsRef.current = [];
    } catch (error) {
      recordedErrorLog("Model change has failed: ", error);
    }
  };

  let currentID = modelNextID;

  function getID() {
    return currentID;
  }

  function upID() {
    currentID = currentID + 1;
    setModelNextID(currentID);
  }

  let localAbbrDic = abbrDic;
  function getAbbrDic() {
    return localAbbrDic;
  }

  function setLocalAbbrDic(changedAbbrs) {
    localAbbrDic = changedAbbrs;
    setAbbrDic(localAbbrDic);
  }

  function getModelByIndexes(models, indexes) {
    try {
      const [currentIndex, ...restIndexes] = indexes;
      return restIndexes.length === 0
        ? models[currentIndex]
        : getModelByIndexes(models[currentIndex].subModels, restIndexes);
    } catch (error) {
      recordedErrorLog("Get model by indexes failure: ", error);
    }
  }

  function addSubModelFromParams(FE_ID, linked, indexes, indexToInsert) {
    try {
      let newList;
      if (linked) {
        newList = linkedSubModelAdd(
          modelData,
          FE_ID,
          getID,
          allLocalModels,
          upID,
          getAbbrDic,
          setLocalAbbrDic,
          namingDic,
          setNamingDic,
          recordedErrorLog,
          indexToInsert
        );
      } else {
        newList = addSubModelToModels(
          modelData,
          FE_ID,
          getID,
          allLocalModels,
          upID,
          getAbbrDic,
          setLocalAbbrDic,
          namingDic,
          setNamingDic,
          null,
          recordedErrorLog,
          indexToInsert
        );
      }

      setModelData(newList);
      return getModelByIndexes(newList, indexes);
    } catch (error) {
      recordedErrorLog("Sub model from params addition failure: ", error);
    }
  }

  const handleBinaryModelLoad = async (ID, fileData) => {
    try {
      // We select just the first entry here, because you can only open a single file from parameter window
      const rfmData = await simpleReadFile(fileData[0]);

      const sendModel = [];

      // Handle RFM file processing logic here
      const base64Content = arrayBufferToBase64(rfmData.content);
      // requestedBinary.current = ID;
      sendModel.push({
        modelid: ID,
        binary: base64Content,
      });

      const payload = {
        User: currentUser.id,
        Model: {
          SendModel: sendModel,
        },
      };

      sendJsonMessage(payload, {
        type: "binary-load-parameter-window",
      });
    } catch (error) {
      recordedErrorLog("ERROR RFM FILE PROCESS: ", error);
      limitedToast("Selected RFM file could not be processed.");
      generateWarningObject(
        "Selected RFM file could not be processed.",
        2,
        setWarnings,
        setNewWarningCount
      );
    }
  };

  const renderModels = (modelData, changeModel, addSubModelFromParams) => {
    try {
      const expanded = deepCopy(expandedWindows);

      const renderModelsRecursive = (
        models,
        expanded,
        changeModel,
        addSubModelFromParams,
        indexes = [],
        renderedFEIDs = new Set()
      ) => {
        return models.flatMap((model, index) => {
          if (renderedFEIDs.has(model.FE_ID)) {
            return [];
          }

          renderedFEIDs.add(model.FE_ID);
          const currentIndexes = [...indexes, index];

          const defaultExpanded = checkIfDefaultExpanded(expanded, model.FE_ID);

          return [
            <React.Fragment key={currentIndexes.join("-")}>
              <ParameterWindow
                key={currentIndexes.join("-")}
                model={model}
                modelIndex={currentIndexes}
                changeModel={changeModel}
                addSubModelFromParams={addSubModelFromParams}
                handleModelClick={handleModelClick}
                startLinking={startLinking}
                canLink={canLink}
                isLinkActive={isLinkActive}
                openParameterWindow={openParameterWindow}
                idChain={idChain}
                setValueUpdate={setValueUpdate}
                setFixedUpdate={setFixedUpdate}
                defaultExpanded={defaultExpanded}
                changeExpansion={changeExpansion}
                loadBinary={handleBinaryModelLoad}
              />
            </React.Fragment>,
            ...(model.subModels.length > 0
              ? renderModelsRecursive(
                  model.subModels,
                  expanded,
                  changeModel,
                  addSubModelFromParams,
                  currentIndexes,
                  renderedFEIDs
                )
              : []),
          ];
        });
      };

      isRendering = true;
      const renderedWindows = renderModelsRecursive(
        modelData,
        expanded,
        changeModel,
        addSubModelFromParams
      );
      isRendering = false;
      handleChangesAfterRender();
      setLocalExpanded(expanded);

      return renderedWindows;
    } catch (error) {
      recordedErrorLog("Model rendering has failed: ", error);
    }
  };

  function changeExpansion(id, status) {
    try {
      const expandedUpdate = deepCopy(expandedWindows);

      expandedUpdate[id] = status;

      setExpandedWindows(expandedUpdate);
    } catch (error) {
      recordedErrorLog("Expansion change has failed: ", error);
    }
  }

  function handleChangesAfterRender() {
    try {
      const backlogKeys = Object.keys(updateBacklog);

      if (backlogKeys.length > 0) {
        let copyOfModelData = deepCopy(modelData);

        for (let i = 0; i < backlogKeys.length; i++) {
          const updateKey = backlogKeys[i];

          copyOfModelData = updateNestedModel(
            copyOfModelData,
            updateBacklog[updateKey].updatedModel,
            updateBacklog[updateKey].FE_ID
          );
        }

        setModelData(copyOfModelData);
      }
    } catch (error) {
      recordedErrorLog("Changes after render handler failure: ", error);
    }
  }

  function findRelatedFE_IDs(arr, targetFE_ID) {
    const result = new Set();

    function traverse(obj) {
      try {
        if (obj.FE_ID === targetFE_ID) {
          result.add(obj.FE_ID);
          addParentsAndSiblings(obj);
          addDescendants(obj);
        } else {
          obj.subModels.forEach(traverse);
        }
      } catch (error) {
        recordedErrorLog("Traversing has failed: ", error);
      }
    }

    function addParentsAndSiblings(obj) {
      try {
        if (!obj.parent) return;
        result.add(obj.parent.FE_ID);
        obj.parent.subModels.forEach((subModel) => {
          result.add(subModel.FE_ID);
        });
        addParentsAndSiblings(obj.parent);
      } catch (error) {
        recordedErrorLog("Adding parents and siblings has failed: ", error);
      }
    }

    function addDescendants(obj) {
      try {
        obj.subModels.forEach((subModel) => {
          result.add(subModel.FE_ID);
          addDescendants(subModel);
        });
      } catch (error) {
        recordedErrorLog("Adding descendants has failed: ", error);
      }
    }

    function addParentReferences(arr, parent = null) {
      try {
        arr.forEach((obj) => {
          obj.parent = parent;
          addParentReferences(obj.subModels, obj);
        });
      } catch (error) {
        recordedErrorLog("Adding Parent references has failed: ", error);
      }
    }

    try {
      addParentReferences(arr);
      arr.forEach(traverse);

      return Array.from(result);
    } catch (error) {
      recordedErrorLog("Related FE_IDs finder has failed: ", error);
    }
  }

  function handleModelClick(FE_ID) {
    try {
      const ids = findRelatedFE_IDs(deepCopy(modelData), FE_ID);
      setIdChain(ids);
    } catch (error) {
      recordedErrorLog("Model Click handler failure: ", error);
    }
  }

  function startLinking(ID, reffitID) {
    try {
      if (!linkingActive) {
        setLinkingActive(true);
        setLinkingID(ID);
        setLinkingReffitID(reffitID);
      } else {
        if (
          linkingID != ID &&
          !hasParentOrChildWithID(modelData, linkingID, linkingReffitID, ID) &&
          reffitID === linkingReffitID
        ) {
          const objectFound = getModelById(ID, modelData);
          setLinked(objectFound);
          const replacedList = replaceModelById(
            modelData,
            objectFound,
            linkingID
          );
          setModelData(replacedList);
          setLinkingActive(false);
          setLinkingID(null);
        }
      }
    } catch (error) {
      recordedErrorLog("Linking initiator failure: ", error);
    }
  }

  function canLink(ID, reffitID) {
    try {
      if (
        hasParentOrChildWithID(modelData, linkingID, reffitID, ID) ||
        linkingID == ID ||
        reffitID != linkingReffitID
      ) {
        return false;
      } else {
        return true;
      }
    } catch (error) {
      recordedErrorLog("canLink has failed: ", error);
    }
  }

  function isLinkActive() {
    return linkingActive;
  }

  const getModel = useMemo(() => {
    return (indexes) => {
      return getModelByIndexes(modelData, indexes);
    };
  }, [modelData]);

  function openParameterWindow(
    cell,
    rec,
    modelIndex,
    FE_ID,
    tableIndex,
    isCheckBox
  ) {
    try {
      if (cell.column.id !== "Model" && !isCheckBox) {
        if (
          (cellColumn != null && cellFeId != null) ||
          (cellColumn != cell.column.id && cellFeId != FE_ID)
        ) {
          setModelForStickyIndexes(modelIndex);
          setCellFeId(FE_ID);
          setCellColumn(cell.column.id);
          setCellForSticky(cell);
          setTableIndexForSticky(tableIndex);
          setRecForSticky(rec);
          setStickyWindowOpen(true);
        }
      }
    } catch (error) {
      recordedErrorLog("Parameter window opening has failed: ", error);
    }
  }

  useEffect(() => {
    function handleMouseDownOutside(event) {
      if (parametersRef.current && !isParamDescendant(event.target)) {
        setLinkingActive(false);
        setLinkingID(null);
      }
    }

    document.addEventListener("mousedown", handleMouseDownOutside);

    return () => {
      document.removeEventListener("mousedown", handleMouseDownOutside);
    };
  }, [parametersRef]);

  const closeStickyWindow = () => {
    setStickyWindowOpen(false);
    setModelForStickyIndexes(null);
    setCellForSticky(null);
    setRecForSticky(null);
  };

  const renderedModels = useMemo(() => {
    // console.log(
    //   "RENDERING MODELS CALLED! ********************************* and modelData is: ",
    //   modelData
    // );
    if (
      modelData != null &&
      modelData.length > 0 &&
      changeModel != undefined &&
      addSubModelFromParams != undefined
    ) {
      return renderModels(modelData, changeModel, addSubModelFromParams);
    }
    return null;
  }, [modelData, idChain, linkingActive]);

  return (
    <div
      className="parametersContainer"
      ref={parametersRef}
      id="model-parameter-window-container"
      style={isFitOngoing ? { position: "relative" } : {}}
    >
      {renderedModels}
      {stickyWindowOpen ? (
        <StickyParameterWindow
          modelIndexes={modelForStickyIndexes}
          getModel={getModel}
          cell={cellForSticky}
          rec={recForSticky}
          tableIndex={tableIndexForSticky}
          changeModel={changeModel}
          closeStickyWindow={closeStickyWindow}
          valueUpdate={valueUpdate}
          setValueUpdate={setValueUpdate}
          fixedUpdate={fixedUpdate}
          setFixedUpdate={setFixedUpdate}
          fitOpen={fitOpen}
        />
      ) : (
        <></>
      )}
      {isFitOngoing ? (
        <BlockingOverlay />
      ) : (
        // <FitLoaderComponent text={"Fit is being processed. Please wait."} />
        <></>
      )}
    </div>
  );
}

export default Parameters;
