import React, { useState, useEffect, useContext, useRef } from "react";
import ProfileHome from "./ProfileHome";
import Details from "./Details";
import FAQPage from "./FAQPage";
import Forum from "./Forum";
import ContactUs from "./ContactUs";
import { useNavigate, Routes, Route } from "react-router-dom";
import { Button } from "@mui/material";
import { AuthContext } from "../../context/AuthContext";
import { GeneralContext } from "../../context/GeneralContext";
import AdminPage from "./AdminPage";
import { api } from "../../utils/authActions";
import "./profilePages.scss";
import TestPage from "./TestPage";
import { WebSocketContext } from "../../context/WebSocketContext";
import { ReadyState } from "react-use-websocket";
import { hasProperty } from "../../utils/helpers";
import BroadcastModal from "../../components/commonComponents/BroadcastModal";
import { DashboardContext } from "../../context/DashboardContext";
import { GraphContext } from "../../context/GraphContext";
import ErrorBoundary from "../../errorHandling/ErrorBoundary";
import CrashPage from "../../errorHandling/CrashPage";

function AdminSystem() {
  const navigate = useNavigate();
  const {
    isAdmin,
    authToken,
    isActive,
    isAuthReady,
    setPackages,
    setCurrentUser,
    isFirstLogin,
    resetAuth,
    setIsFirstLogin,
  } = useContext(AuthContext);
  const {
    lastJsonMessage,
    readyState,
    sendMessage,
    authSent,
    setAuthSent,
    resetWebSocketContext,
  } = useContext(WebSocketContext);
  const { resetDashboardContext } = useContext(DashboardContext);
  const { resetGraphContext } = useContext(GraphContext);
  const {
    setBroadcastMessage,
    setActivePolls,
    setPollToSolve,
    recordedErrorLog,
  } = useContext(GeneralContext);
  const [username, setUsername] = useState("");
  const [broadcastOpen, setBroadcastOpen] = useState(false);
  const adminSytemPageRef = useRef();

  useEffect(() => {
    if (
      isAuthReady &&
      authToken !== "" &&
      ReadyState.OPEN === readyState &&
      !authSent
    ) {
      sendMessage("Token: " + authToken);
      setAuthSent(true);
    }
  }, [readyState, isAuthReady]);

  useEffect(() => {
    if (isAuthReady) {
      if (authToken === "") {
        navigate("/");
      }

      api
        .getPackages(authToken)
        .then((response) => {
          setPackages(response.data);
        })
        .catch((err) => {
          recordedErrorLog("THERE WAS AN ERROR WITH PACKAGE LOADING: ", err);
        });
      // .finally(() => {
      //   if (isActive && isFirstLogin) {
      //     setIsFirstLogin(false);
      //     navigate("/dashboard");
      //   }
      // });

      api
        .getMe(authToken)
        .then((response) => {
          setUsername(response.data.full_name);
          setCurrentUser(response.data);
        })
        .catch((err) => {
          recordedErrorLog("PROBLEM WITH LOADING CURRENT USER DATA: ", err);
        });

      api
        .getActivePolls(authToken)
        .then((response) => {
          setActivePolls(response.data);
          if (response.data.length > 0) {
            setPollToSolve(response.data[0]);
          } else {
            if (isActive && isFirstLogin) {
              navigate("/dashboard");
            }
          }
          setIsFirstLogin(false);
        })
        .catch((err) => {
          recordedErrorLog("THERE WAS AN ERROR RETRIEVING ACTIVE POLLS: ", err);
          if (isActive && isFirstLogin) {
            setIsFirstLogin(false);
            navigate("/dashboard");
          }
        });
    }
  }, [isAuthReady]);

  useEffect(() => {
    if (lastJsonMessage) {
      if (hasProperty(lastJsonMessage, "Broadcast")) {
        setBroadcastMessage(lastJsonMessage.Broadcast);
        setBroadcastOpen(true);
      }
    }
  }, [lastJsonMessage]);

  const handleLogout = () => {
    try {
      sessionStorage.clear();
      resetDashboardContext();
      resetAuth();
      resetGraphContext();
      resetWebSocketContext();
      navigate("/");
    } catch (error) {
      recordedErrorLog("Error handling logout: ", error);
    }
  };

  const handleCloseIconClick = () => {
    setBroadcastOpen(false);
  };

  return (
    <ErrorBoundary
      fallbackComponent={CrashPage}
      fallbackProps={{
        text: "User Home System failure.",
      }}
    >
      <div className="adminSystemPage" ref={adminSytemPageRef}>
        <div className="sidePannel">
          <div className="userArea">
            Welcome,
            <br />
            <div className="userName">{username}</div>
          </div>
          <div className="pagesArea">
            <div className="linkContainer">
              <div className="link" onClick={() => navigate("/profile")}>
                Home
              </div>
              <div
                className="link"
                onClick={() => navigate("/profile/details")}
                id="details-navigation-button"
              >
                Details
              </div>
              <div className="link" onClick={() => navigate("/profile/faq")}>
                F.A.Q.
              </div>
              {/* <div className="link" onClick={() => navigate("/profile/forum")}>
              Forum
            </div> */}
              <div
                className="link"
                onClick={() => navigate("/profile/contact")}
                id="contact-navigation-button"
              >
                Contact
              </div>
              {isAdmin && (
                <div
                  className="link"
                  onClick={() => navigate("/profile/admin")}
                >
                  Admin
                </div>
              )}
            </div>
          </div>
          <div className="sidePannelBottom">
            <div className="logout">
              <Button
                variant="outlined"
                onClick={() => handleLogout()}
                className="logoutButton"
                id="user-home-logout-button"
              >
                Log out
              </Button>
            </div>
          </div>
        </div>
        <div className="adminSystemContent">
          <Routes>
            <Route
              path="/"
              element={
                <ErrorBoundary
                  fallbackComponent={CrashPage}
                  fallbackProps={{
                    text: "User Home failure.",
                  }}
                >
                  <ProfileHome />
                </ErrorBoundary>
              }
            />
            <Route
              path="details"
              element={
                <ErrorBoundary
                  fallbackComponent={CrashPage}
                  fallbackProps={{
                    text: "User Details failure.",
                  }}
                >
                  <Details />
                </ErrorBoundary>
              }
            />
            <Route
              path="faq"
              element={
                <ErrorBoundary
                  fallbackComponent={CrashPage}
                  fallbackProps={{
                    text: "F.A.Q. page failure.",
                  }}
                >
                  <FAQPage />
                </ErrorBoundary>
              }
            />
            <Route path="forum" element={<Forum />} />
            <Route
              path="contact"
              element={
                <ErrorBoundary
                  fallbackComponent={CrashPage}
                  fallbackProps={{
                    text: "Contact page failure.",
                  }}
                >
                  <ContactUs />
                </ErrorBoundary>
              }
            />
            <Route
              path="admin"
              element={
                <ErrorBoundary
                  fallbackComponent={CrashPage}
                  fallbackProps={{
                    text: "Administrator page failure.",
                  }}
                >
                  <AdminPage />
                </ErrorBoundary>
              }
            />
            <Route path="test" element={<TestPage />} />
          </Routes>
        </div>
        <BroadcastModal
          elementRef={adminSytemPageRef}
          isOpen={broadcastOpen}
          setIsOpen={setBroadcastOpen}
          handleClose={handleCloseIconClick}
        />
      </div>
    </ErrorBoundary>
  );
}

export default AdminSystem;
