import React from "react";
import "./middle.scss";
import { Button } from "@mui/material";

function FitStopWindow(props) {
  const { iterationCount, handleStop } = props;

  return (
    <div className="fitStopWindow">
      <div className="fitInfo">
        <div className="text">Processing Fit...</div>
        <div className="iterationCount">{`Current iteration: ${
          iterationCount !== null ? iterationCount : 0
        }`}</div>
      </div>
      <div className="buttonArea">
        <Button
          variant="contained"
          className="stopFitButton"
          onClick={() => handleStop()}
        >
          Stop Fit
        </Button>
      </div>
    </div>
  );
}

export default FitStopWindow;
