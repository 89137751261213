import React, { useRef, useState, useContext } from "react";
import AdminFooter from "../components/commonComponents/AdminFooter";
import { TextField, Button } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Modal from "react-modal";
import "./register.scss";
import PrivacyPolicy from "../components/adminComponents/PrivacyPolicy";
import PasswordValidator from "password-validator";
// import VisibilityIcon from "@mui/icons-material/Visibility";
// import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
// import InputAdornment from "@mui/material/InputAdornment";
// import IconButton from "@mui/material/IconButton";
import { AuthContext } from "../context/AuthContext";
import { GeneralContext } from "../context/GeneralContext";
import { api } from "../utils/authActions";
import { useNavigate } from "react-router-dom";
import { hasProperty, validateEmail } from "../utils/helpers";
import PasswordEntry from "../components/adminComponents/PasswordEntry";
import toast from "react-hot-toast";

function Register() {
  const { authToken } = useContext(AuthContext);
  const { recordedErrorLog } = useContext(GeneralContext);
  const navigate = useNavigate();
  const privacyRef = useRef();
  const [privacyOpen, setPrivacyOpen] = useState(false);
  const [consented, setConsented] = useState(false);
  const [consentedNews, setConsentedNews] = useState(true);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [emailConfirm, setEmailConfirm] = useState("");
  const [emailMissmatch, setEmailMissmatch] = useState(false);
  const [emailValid, setEmailValid] = useState(true);
  const [password, setPassword] = useState("");
  // const [passwordConfirm, setPasswordConfirm] = useState("");
  // const [passMissmatch, setPassMissmatch] = useState(false);
  // const [passGood, setPassGood] = useState(true);
  const [interests, setInterests] = useState("");
  // const [showPassword, setShowPassword] = useState(false);
  const [questions, setQuestions] = useState({
    "From a colleague": false,
    "Via ResearchGate": false,
    "Via a search engine": false,
    "From (scientific) publications": false,
  });
  const [background, setBackground] = useState({
    "Mostly academia": false,
    "Mostly industry": false,
    "Mostly education": false,
  });
  const [emailAlreadyUsed, setEmailAlreadyUsed] = useState(false);

  const schema = new PasswordValidator();
  schema
    .is()
    .min(8) // Minimum length 8
    .is()
    .max(100) // Maximum length 100
    .has()
    .uppercase() // Must have uppercase letters
    .has()
    .lowercase() // Must have lowercase letters
    .has()
    .digits(2) // Must have at least 2 digits
    .has()
    .not()
    .spaces() // Should not have spaces
    .is()
    .not()
    .oneOf(["Passw0rd", "Password123"]); // Blacklist these values

  const handleQuestionsChange = (event) => {
    setQuestions((old) => {
      return { ...old, [event.target.name]: event.target.checked };
    });
  };

  const handleBackgroundChange = (event) => {
    setBackground((old) => {
      return { ...old, [event.target.name]: event.target.checked };
    });
  };

  // const handleClickShowPassword = () => setShowPassword((show) => !show);

  // const handleMouseDownPassword = (event) => {
  //   event.preventDefault();
  // };

  const checkEmailMatch = () => {
    if (emailConfirm !== "" && email !== emailConfirm) {
      setEmailMissmatch(true);
    } else {
      setEmailMissmatch(false);
    }
  };

  // const checkPassMatch = () => {
  //   if (passwordConfirm !== "" && password !== passwordConfirm) {
  //     setPassMissmatch(true);
  //   } else {
  //     setPassMissmatch(false);
  //   }
  // };

  const handleClosePrivacyModal = () => {
    setPrivacyOpen(false);
  };

  // const handlePassBlur = (e) => {
  //   const pass = e.target.value;

  //   if (passwordConfirm !== "") {
  //     checkPassMatch();
  //   }

  //   if (password !== "") {
  //     if (schema.validate(pass)) {
  //       setPassGood(true);
  //     } else {
  //       setPassGood(false);
  //     }
  //   }
  // };

  const handleEmailBlur = () => {
    if (email !== "" && !validateEmail(email)) {
      setEmailValid(false);
    } else {
      setEmailValid(true);
    }
    if (emailConfirm !== "") {
      checkEmailMatch();
    }
  };

  const checkIfAllowedToClick = () => {
    return (
      password !== "" &&
      // password === passwordConfirm &&
      schema.validate(password) &&
      email !== "" &&
      email === emailConfirm &&
      validateEmail(email) &&
      firstName !== "" &&
      lastName !== "" &&
      interests !== "" &&
      Object.entries(questions).some(([, value]) => value === true) &&
      Object.entries(background).some(([, value]) => value === true)
    );
  };

  const trueKeysToString = (state) => {
    return Object.entries(state)
      .filter(([, value]) => value)
      .map(([key]) => key)
      .join("; ");
  };

  const handleRegister = () => {
    if (checkIfAllowedToClick) {
      const payload = {
        email: email,
        password: password,
        full_name: firstName + " " + lastName,
        affiliation: trueKeysToString(questions),
        background: trueKeysToString(background),
        interests: interests,
        newsletter: consentedNews,
      };

      api
        .createUser(authToken, payload)
        .then(() => {
          // function can receive 'response'
          navigate("/welcome");
        })
        .catch((err) => {
          if (
            hasProperty(err.response.data, "detail") &&
            err.response.data.detail ===
              "The user with this email already exists in the system."
          ) {
            setEmailAlreadyUsed(true);
          } else {
            recordedErrorLog("THERE WAS AN ERROR WITH USER CREATION: ", err);
            toast.error(
              "Error creating the user. Please try again or contact support."
            );
          }
        });
    }
  };

  return (
    <div className="registerPage">
      <div className="pageContentContainer">
        <h1 className="title">Create your Speqqle accout</h1>
        <div className="detailEntryContainer">
          {/* NAME SECTION */}
          <div className="entrySection">
            <div className="sectionTitle">Name*</div>
            <div className="entries">
              <div className="entry">
                {/* <div className="entryTitle">First*</div> */}
                <TextField
                  className="entryField"
                  size="small"
                  label={"First"}
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>
              <div className="entry">
                {/* <div className="entryTitle">Last*</div> */}
                <TextField
                  className="entryField"
                  label={"Last"}
                  size="small"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
            </div>
          </div>
          {/* EMAIL SECTION */}
          <div className="entrySection">
            <div className="sectionTitle">Email*</div>
            <div className="entries">
              <div className="entry">
                {/* <div className="entryTitle">Enter Email</div> */}
                <TextField
                  className="entryField"
                  size="small"
                  label={"Email"}
                  value={email}
                  onChange={(e) => {
                    setEmailValid(true);
                    setEmail(e.target.value);
                  }}
                  onBlur={() => handleEmailBlur()}
                  // onPaste={(event) => event.preventDefault()}
                  error={emailAlreadyUsed}
                />
              </div>
              <div className="entry">
                {/* <div className="entryTitle">Confirm Email</div> */}
                <TextField
                  className="entryField"
                  size="small"
                  label={"Confirm Email"}
                  value={emailConfirm}
                  onChange={(e) => {
                    setEmailValid(true);
                    setEmailConfirm(e.target.value);
                  }}
                  onBlur={() => checkEmailMatch()}
                  // onPaste={(event) => event.preventDefault()}
                  error={emailAlreadyUsed}
                />
              </div>
            </div>
            {emailAlreadyUsed ? (
              <div className="emailInUse">Email is already in use.</div>
            ) : (
              <></>
            )}
          </div>
          {emailMissmatch ? (
            <div className="missmatch">Emails do not match!</div>
          ) : (
            <></>
          )}
          {!emailValid ? (
            <div className="missmatch">Email is not valid!</div>
          ) : (
            <></>
          )}
          {/* PASSWORD SECTION */}
          <PasswordEntry title={"Password*"} setFinalPass={setPassword} />
        </div>
        {/* QUESTIONS AREA */}
        <div className="questionEntryContainer">
          <div className="questionSection">
            <div className="question">How did you find out about Speqqle?*</div>
            <div className="replyArea">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={questions["From a colleague"]}
                      onChange={handleQuestionsChange}
                      name="From a colleague"
                    />
                  }
                  label="From a colleague"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={questions["Via ResearchGate"]}
                      onChange={handleQuestionsChange}
                      name="Via ResearchGate"
                    />
                  }
                  label="Via ResearchGate"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={questions["Via a search engine"]}
                      onChange={handleQuestionsChange}
                      name="Via a search engine"
                    />
                  }
                  label="Via a search engine"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={questions["From (scientific) publications"]}
                      onChange={handleQuestionsChange}
                      name="From (scientific) publications"
                    />
                  }
                  label="From (scientific) publications"
                />
              </FormGroup>
            </div>
          </div>
          <div className="questionSection">
            <div className="question">What is your background?*</div>
            <div className="replyArea">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={background["Mostly academia"]}
                      onChange={handleBackgroundChange}
                      name="Mostly academia"
                    />
                  }
                  label="Mostly academia"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={background["Mostly industry"]}
                      onChange={handleBackgroundChange}
                      name="Mostly industry"
                    />
                  }
                  label="Mostly industry"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={background["Mostly education"]}
                      onChange={handleBackgroundChange}
                      name="Mostly education"
                    />
                  }
                  label="Mostly education"
                />
              </FormGroup>
            </div>
          </div>
          <div className="questionSection">
            <div className="question">
              Can you tell us about your interests?*
            </div>
            <div className="replyAreaText">
              <div className="questionNote">
                We use your entries to improve Speqqle, so try to be specific.
              </div>
              <textarea
                className="textArea"
                value={interests}
                onChange={(e) => setInterests(e.target.value)}
              />
            </div>
          </div>

          {/* AGGREE TO TERMS */}
          <div className="questionSection">
            <div className="question">Consent</div>
            <div className="replyArea">
              <FormGroup>
                <FormControlLabel
                  required
                  control={
                    <Checkbox
                      checked={consented}
                      onClick={() => setConsented((old) => !old)}
                    />
                  }
                  label="I agree to the privacy policy"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={consentedNews}
                      onClick={() => setConsentedNews((old) => !old)}
                    />
                  }
                  label="I agree to receive newsletters"
                />
              </FormGroup>
              <div
                className="privacyPolicy"
                ref={privacyRef}
                onClick={() => setPrivacyOpen(true)}
              >
                Privacy Policy
              </div>
            </div>
          </div>
          <Modal
            isOpen={privacyOpen}
            onRequestClose={handleClosePrivacyModal}
            shouldCloseOnOverlayClick={true}
            contentLabel="Fit Modal"
            appElement={privacyRef.current}
            style={{
              content: {
                top: "35%",
                left: "50%",
                right: "auto",
                bottom: "auto",
                marginRight: "-50%",
                transform: "translate(-50%, -35%)",
                minWidth: "440px",
                width: "80vw",
                minHeight: "650px",
                height: "70vh",
              },
              overlay: {
                zIndex: "100",
              },
            }}
          >
            <PrivacyPolicy />
          </Modal>

          {consented ? (
            <div className="afterConsent">
              {/* <div className="captchaArea">Here is supposed to be captcha</div> */}
              <div className="registerButtonArea">
                <Button
                  disabled={!checkIfAllowedToClick()}
                  variant="contained"
                  onClick={() => handleRegister()}
                  className="registerButton"
                >
                  Register
                </Button>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <AdminFooter />
    </div>
  );
}

export default Register;
