import React, { useEffect, useState, useContext, useRef } from "react";
import { AuthContext } from "../../context/AuthContext";
import { WebSocketContext } from "../../context/WebSocketContext";
import { GeneralContext } from "../../context/GeneralContext";
import { api } from "../../utils/authActions";
import { DataGrid } from "@mui/x-data-grid";
import SettingsIcon from "@mui/icons-material/Settings";
import Modal from "react-modal";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import EditUser from "../../components/adminComponents/EditUser";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import "./profilePages.scss";
import AnswerEntry from "../../components/adminComponents/polling/AnswerEntry";
import { deepCopy } from "../../components/leftSide/Models/modelLogic";
import AddIcon from "@mui/icons-material/Add";
import { Button } from "@mui/material";
import { isActive } from "../../utils/helpers";
import ConfirmationScreen from "../../components/commonComponents/ConfirmationScreen";
import PollWithAnswers from "../../components/adminComponents/polling/PollWithAnswers";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs-plugin-utc";
import toast from "react-hot-toast";

dayjs.extend(dayjsPluginUTC);

function AdminPage() {
  const { authToken, isAuthReady } = useContext(AuthContext);
  const { sendJsonMessage } = useContext(WebSocketContext);
  const { recordedErrorLog } = useContext(GeneralContext);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [editOpen, setEditOpen] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [value, setValue] = useState("users");
  const [newQuestion, setNewQuestion] = useState("");
  const [newAnswers, setNewAnswers] = useState(["", ""]);
  const [activePolls, setActivePolls] = useState([]);
  const [closedPolls, setClosedPolls] = useState([]);
  const [message, setMessage] = useState("");
  const [confirmMessageOpen, setConfirmMessageOpen] = useState(false);
  const [noEnd, setNoEnd] = useState(false);
  const [noStart, setNoStart] = useState(false);
  const [startDate, setStartDate] = useState(dayjs().add(2, "hour"));
  const [endDate, setEndDate] = useState(dayjs().add(5, "day"));
  const tableRef = useRef();
  const messageRef = useRef();

  useEffect(() => {
    if (isAuthReady) {
      api
        .getAllPolls(authToken)
        .then((response) => {
          const activePolls = [];
          const closedPolls = [];

          for (let i = 0; i < response.data.length; i++) {
            const singlePoll = response.data[i];

            if (isActive(singlePoll.closing_date)) {
              activePolls.push(singlePoll);
            } else {
              closedPolls.push(singlePoll);
            }
          }

          setActivePolls(activePolls);
          setClosedPolls(closedPolls);
        })
        .catch((err) => {
          recordedErrorLog("error in all poll retrieval: ", err);
          toast.error(
            "Could not retrieve all polls. Check console for more info."
          );
        });
    }
  }, [isAuthReady]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const columns = [
    { field: "id", headerName: "ID", width: 40 },
    { field: "email", headerName: "Email", width: 200 },
    { field: "email_confirmed", headerName: "Confirmed", width: 80 },
    { field: "full_name", headerName: "Full name", width: 130 },
    { field: "affiliation", headerName: "Affiliation", width: 130 },
    { field: "is_superuser", headerName: "Admin", width: 60 },
    { field: "created_on", headerName: "Created on", width: 120 },
    { field: "newsletter", headerName: "Newsletter", width: 80 },
    {
      field: "edit",
      headerName: "Edit",
      width: 60,
      renderCell: (params) => (
        <SettingsIcon
          style={{ cursor: "pointer" }}
          onClick={() => {
            setSelectedUser(params.id);
            setEditOpen(true);
          }}
        />
      ),
    },
  ];

  useEffect(() => {
    try {
      const rowsToSet = users.map((user) => {
        let dateObj = new Date(user.created_on);

        let day = String(dateObj.getDate()).padStart(2, "0");
        let month = String(dateObj.getMonth() + 1).padStart(2, "0"); // Note: 0 = January, so we must add 1 to get correct month
        let year = dateObj.getFullYear();

        let newDateString = day + "/" + month + "/" + year;

        return {
          id: user.id,
          email: user.email,
          email_confirmed: user.email_confirmed,
          full_name: user.full_name,
          affiliation: user.affiliation,
          is_superuser: user.is_superuser,
          created_on: newDateString,
          newsletter: user.newsletter,
        };
      });

      setRows(rowsToSet);
    } catch (error) {
      recordedErrorLog("Users useEffect has failed: ", error);
    }
  }, [users]);

  useEffect(() => {
    try {
      if (isAuthReady && authToken !== "") {
        setIsTableLoading(true);
        api
          .getUsers(authToken)
          .then((response) => {
            setUsers(response.data);
          })
          .catch((err) => {
            recordedErrorLog(
              "THERE WAS AN ERROR WITH LOADING USERS IN ADMIN: ",
              err
            );
            toast.error(
              "Could not retrieve users. Check console for more info."
            );
          })
          .finally(() => {
            setIsTableLoading(false);
          });
      }
    } catch (error) {
      recordedErrorLog("'isAuthReady' useEffect has failed: ", error);
    }
  }, [isAuthReady]);

  const handleCloseEditModal = () => {
    setEditOpen(false);
  };

  const handleAnswerChange = (val, index) => {
    try {
      const newAnswersArray = deepCopy(newAnswers);
      newAnswersArray[index] = val;
      setNewAnswers(newAnswersArray);
    } catch (error) {
      recordedErrorLog("Answer change handler has failed: ", error);
    }
  };

  const handleDeleteAnswer = (index) => {
    try {
      const newAnswersArray = deepCopy(newAnswers);
      newAnswersArray.splice(index, 1);
      setNewAnswers(newAnswersArray);
    } catch (error) {
      recordedErrorLog("Answer delete handler has failed: ", error);
    }
  };

  const handleAddNewAnswer = () => {
    setNewAnswers((old) => [...old, ""]);
  };

  const handleCreatePoll = () => {
    try {
      const clearedAnswers = newAnswers.filter((answer) => answer !== "");
      if (
        clearedAnswers.length >= 2 &&
        newQuestion !== "" &&
        startDate.isBefore(endDate)
      ) {
        const payloadForPoll = {
          question: newQuestion,
          answers: clearedAnswers,
        };

        if (!noStart) {
          payloadForPoll.starting_date = startDate.utc().toISOString();
        }

        if (!noEnd) {
          payloadForPoll.closing_date = endDate.utc().toISOString();
        }

        api
          .createNewPoll(authToken, payloadForPoll)
          .then((response) => {
            const newPoll = response.data;
            setActivePolls((old) => [...old, newPoll]);
            setNewQuestion("");
            setNewAnswers(["", ""]);
          })
          .catch((err) => {
            recordedErrorLog("error in poll creation: ", err);
            toast.error(
              "Could not create the poll. Check console for more info."
            );
          });
      }
    } catch (error) {
      recordedErrorLog("Create poll handler has failed: ", error);
    }
  };

  const handleGlobalMessageSend = () => {
    setConfirmMessageOpen(true);
  };

  const handleConfirmMessageSend = () => {
    const payload = {
      Broadcast: message,
    };

    try {
      sendJsonMessage(payload, false);
    } catch (error) {
      recordedErrorLog("Error in sending global message: ", error);
      toast.error(
        "Could not send global message. Check console for more info."
      );
    }

    setMessage("");
    setConfirmMessageOpen(false);
  };

  const handleDeletePoll = (id, active) => {
    try {
      if (active) {
        const clearedActive = activePolls.filter((poll) => poll.id !== id);
        setActivePolls(clearedActive);
      } else {
        const clearedClosed = closedPolls.filter((poll) => poll.id !== id);
        setClosedPolls(clearedClosed);
      }
    } catch (error) {
      recordedErrorLog("Delete poll handler has failed: ", error);
    }
  };

  const handlePollUpdate = () => {
    api
      .getAllPolls(authToken)
      .then((response) => {
        const activePolls = [];
        const closedPolls = [];

        for (let i = 0; i < response.data.length; i++) {
          const singlePoll = response.data[i];

          if (isActive(singlePoll.closing_date)) {
            activePolls.push(singlePoll);
          } else {
            closedPolls.push(singlePoll);
          }
        }

        setActivePolls(activePolls);
        setClosedPolls(closedPolls);
      })
      .catch((err) => {
        recordedErrorLog("error in all poll retrieval: ", err);
        toast.error(
          "Could not retrieve all polls. Check console for more info."
        );
      });
  };

  const handleUserDelete = (userId) => {
    try {
      const updatedUsers = users.filter((user) => user.id !== userId);
      setUsers(updatedUsers);
      setEditOpen(false);
    } catch (error) {
      recordedErrorLog("User delete handler has failed: ", error);
    }
  };

  return (
    <div className="adminPage">
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Users" value="users" />
            <Tab label="Polls" value="polls" />
            <Tab label="Extras" value="extras" />
          </TabList>
        </Box>
        <TabPanel value="users" className="usersTabArea">
          <div className="usersTableSection">
            <div className="usersTableTitle">Search Users:</div>
            <div className="usersTableSearchArea">
              <TextField
                className="usersTableSearch"
                placeholder="Search users"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <FilterAltIcon className="filter" />
            </div>
            <div className="usersTableContainer" ref={tableRef}>
              <DataGrid
                rows={rows}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 5 },
                  },
                }}
                pageSizeOptions={[5, 10]}
                sx={{
                  "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                    outline: "none !important",
                  },
                }}
                loading={isTableLoading}
              />
              <Modal
                isOpen={editOpen}
                onRequestClose={handleCloseEditModal}
                shouldCloseOnOverlayClick={true}
                contentLabel="Fit Modal"
                appElement={tableRef.current}
                style={{
                  content: {
                    top: "35%",
                    left: "50%",
                    right: "auto",
                    bottom: "auto",
                    marginRight: "-50%",
                    transform: "translate(-50%, -35%)",
                    minWidth: "440px",
                    width: "80vw",
                    minHeight: "650px",
                    height: "70vh",
                  },
                  overlay: {
                    zIndex: "100",
                  },
                }}
              >
                <EditUser
                  userData={users.find((user) => user.id === selectedUser)}
                  handleUserDelete={handleUserDelete}
                />
              </Modal>
            </div>
          </div>
        </TabPanel>
        <TabPanel value="polls" className="pollsTabArea">
          <div className="pollingSection">
            <div className="pollingTitle">Polls:</div>
            <div className="newPoll">
              <div className="pollTitle">Create new poll:</div>
              <TextField
                className="newQuestionEntry"
                size="small"
                value={newQuestion}
                onChange={(e) => setNewQuestion(e.target.value)}
                label="New poll question"
              />
              <div className="answers">
                {newAnswers.map((answer, index) => {
                  return (
                    <AnswerEntry
                      key={index}
                      value={answer}
                      setValue={handleAnswerChange}
                      index={index}
                      deleteAnswer={handleDeleteAnswer}
                    />
                  );
                })}
              </div>
              <div className="addAnswerArea">
                <div
                  className="addAnswerButton"
                  onClick={() => handleAddNewAnswer()}
                >
                  <AddIcon />
                  <div className="addText">Add answer</div>
                </div>
              </div>
              <div className="durationArea">
                <div className="duration">
                  <div className="title">Starting date:</div>
                  <div className="checkboxArea">
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={noStart}
                            onChange={() => setNoStart((old) => !old)}
                          />
                        }
                        label={"Poll has no start date"}
                      />
                    </FormGroup>
                  </div>
                  <div className={`time`}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Date"
                        value={startDate}
                        onChange={(newValue) => setStartDate(newValue)}
                        disabled={noStart}
                        minDate={dayjs()}
                      />
                      <TimePicker
                        label="Time"
                        className="timeSelector"
                        value={startDate}
                        onChange={(newValue) => setStartDate(newValue)}
                        disabled={noStart}
                        // minTime={
                        //   startDate?.isSame(dayjs(), "day")
                        //     ? dayjs()
                        //     : undefined
                        // }
                      />
                    </LocalizationProvider>
                  </div>
                </div>
                <div className="duration">
                  <div className="title">End date:</div>
                  <div className="checkboxArea">
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={noEnd}
                            onChange={() => setNoEnd((old) => !old)}
                          />
                        }
                        label={"Poll has no end date"}
                      />
                    </FormGroup>
                  </div>
                  <div className={`time`}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Date"
                        value={endDate}
                        onChange={(newValue) => setEndDate(newValue)}
                        disabled={noEnd}
                        minDate={startDate || dayjs()}
                      />
                      <TimePicker
                        label="Time"
                        className="timeSelector"
                        value={endDate}
                        onChange={(newValue) => setEndDate(newValue)}
                        disabled={noEnd}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
              </div>
              <div className="createPollButtonArea">
                <Button
                  variant="contained"
                  className="createButton"
                  onClick={() => handleCreatePoll()}
                  id="create-poll-button-admin"
                >
                  Create Poll
                </Button>
              </div>
            </div>
            <div className="divider" />
            <div className="pollingTitle">Active Polls:</div>
            <div className="activePollArea">
              {activePolls.length > 0 ? (
                <div className="polls">
                  {activePolls.map((poll) => {
                    return (
                      <div className="singlePoll" key={poll.id}>
                        <PollWithAnswers
                          active
                          start={poll.starting_date}
                          end={poll.closing_date}
                          pollId={poll.id}
                          question={poll.question}
                          answers={poll.answers}
                          handleDeletePoll={handleDeletePoll}
                          handlePollUpdate={handlePollUpdate}
                        />
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="noActive">No active polls</div>
              )}
            </div>
            <div className="pollingTitle">Closed Polls:</div>
            <div className="activePollArea">
              {closedPolls.length > 0 ? (
                <div className="polls">
                  {closedPolls.map((poll) => {
                    return (
                      <div className="singlePoll" key={poll.id}>
                        <PollWithAnswers
                          start={poll.starting_date}
                          end={poll.closing_date}
                          pollId={poll.id}
                          question={poll.question}
                          answers={poll.answers}
                          handleDeletePoll={handleDeletePoll}
                        />
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="noActive">No closed polls</div>
              )}
            </div>
          </div>
        </TabPanel>
        <TabPanel value="extras" className="extrasTabArea">
          <div className="extrasArea">
            <div className="globalMessage" ref={messageRef}>
              <div className="title">Message for all active users:</div>
              <textarea
                placeholder="Message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
              <div className="buttonContainer">
                <Button
                  variant="contained"
                  className="messageButton"
                  onClick={() => handleGlobalMessageSend()}
                >
                  Send Message
                </Button>
                <ConfirmationScreen
                  onYes={handleConfirmMessageSend}
                  onNo={() => setConfirmMessageOpen(false)}
                  text={
                    "Are you sure you want to send this message to ALL ACTIVE USERS?"
                  }
                  open={confirmMessageOpen}
                  setOpen={setConfirmMessageOpen}
                  element={messageRef}
                />
              </div>
            </div>
          </div>
        </TabPanel>
      </TabContext>
    </div>
  );
}

export default AdminPage;
