import { Button } from "@mui/material";
import React, { useState } from "react";
import { HexColorPicker } from "react-colorful";
import "./windowComps.scss";

function ColorPicker(props) {
  const { oldColor, confirmNewColor } = props;
  const [color, setColor] = useState(oldColor);

  return (
    <div className="colorPicker">
      <div className="chooseColor">
        <HexColorPicker color={color} onChange={setColor} />
      </div>
      <div className="oldVsNewColor">
        <div className="colorsArea">
          <div className="color top" style={{ backgroundColor: oldColor }} />
          <div className="color bottom" style={{ backgroundColor: color }} />
        </div>
        <div className="buttonArea">
          <Button
            variant="contained"
            size="small"
            onClick={() => confirmNewColor(color)}
            className="confirmNewColor"
          >
            Change
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ColorPicker;
