import React from "react";
import HtmlTooltip from "./HtmlTooltip";
import "./commonComponents.scss";

function AdminFooter() {
  return (
    <div className="footer">
      <div className="footerData">
        <div className="foorterList">
          <div className="section">
            <div className="footerListItem">
              <a href="https://speqqle.ch/">Webpage</a>
            </div>
            <div className="footerListItem">
              <a href="https://speqqle.ch/contact-us/">Contact Us</a>
            </div>
          </div>
          <div className="section">
            <HtmlTooltip
              title={
                <React.Fragment>
                  <div className="soonTooltip">Coming soon!</div>
                </React.Fragment>
              }
            >
              <div className="footerListItem">F.A.Q.</div>
            </HtmlTooltip>
            <HtmlTooltip
              title={
                <React.Fragment>
                  <div className="soonTooltip">Coming soon!</div>
                </React.Fragment>
              }
            >
              <div className="footerListItem">Forum</div>
            </HtmlTooltip>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminFooter;
