import React, { createContext, useState, useContext } from "react";
import { deepCopy } from "../components/leftSide/Models/modelLogic";
import { DashboardContext } from "./DashboardContext";

export const FileHandlingContext = createContext();

export const FileHandlingContextProvider = (props) => {
  const { uploadedFiles, setUploadedFiles } = useContext(DashboardContext);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const updateSelected = (newFile, index) => {
    const copyOfFiles = deepCopy(uploadedFiles);

    copyOfFiles[index] = newFile;

    setUploadedFiles(copyOfFiles);
  };

  const value = { selectedFiles, setSelectedFiles, updateSelected };

  return (
    <FileHandlingContext.Provider value={value}>
      {props.children}
    </FileHandlingContext.Provider>
  );
};

export default FileHandlingContextProvider;
