import React from "react";
import "./adminComponents.scss";
import { TextField } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Tooltip from "@mui/material/Tooltip";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

function UserDetail(props) {
  const {
    title,
    value,
    setValue,
    showVerified = null,
    checkbox = false,
    checkboxLabel = "",
    nonEdit = false,
  } = props;

  const handleValueChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <div className="detail">
      <div className="title">{title}</div>
      <div>
        {nonEdit ? (
          <div className="nonEditEntry" id="uneditable-entry">
            {value}
          </div>
        ) : checkbox ? (
          <FormGroup
            sx={{
              width: "150px",
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={value}
                  onChange={() => setValue((old) => !old)}
                />
              }
              label={checkboxLabel}
            />
          </FormGroup>
        ) : (
          <TextField
            variant="standard"
            size="small"
            id={title}
            value={value}
            onChange={handleValueChange}
          />
        )}
        {showVerified !== null ? (
          showVerified ? (
            <Tooltip title="Verified">
              <CheckCircleOutlineIcon />
            </Tooltip>
          ) : (
            <Tooltip title="Not verified">
              <HighlightOffIcon />
            </Tooltip>
          )
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default UserDetail;
