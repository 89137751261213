/**
 * @category LeftSide
 * @module Files/FileUploadLogic
 * @description This file contains the logic for the file uploading system.
 */

/**
 * This function checks whether the click was outside of the StickyFileWindow.
 * @function isClickedOutside
 * @param {Event} event - This is a click event.
 * @returns {boolean} Returns true if the click was outside of the StickyFileWindow.
 */
export function isClickedOutside(event) {
  if (
    event.target.className == "fileName" ||
    event.target.className ==
      "MuiMenuItem-root MuiMenuItem-gutters MuiButtonBase-root css-1hau5ki-MuiButtonBase-root-MuiMenuItem-root" ||
    event.target.className ==
      "MuiButtonBase-root MuiMenuItem-root MuiMenuItem-gutters MuiMenuItem-root MuiMenuItem-gutters css-1hau5ki-MuiButtonBase-root-MuiMenuItem-root" ||
    event.target.className ==
      "MuiButtonBase-root MuiMenuItem-root MuiMenuItem-gutters Mui-selected MuiMenuItem-root MuiMenuItem-gutters Mui-selected css-1hau5ki-MuiButtonBase-root-MuiMenuItem-root" ||
    event.target.id == "file-entry" ||
    hasStickyFileWindowAncestor(event)
  ) {
    return false;
  }
  return true;
}

/**
 * This function checks whether the clicked element has a stickyFileWindow ancestor. 
 * @function hasStickyFileWindowAncestor
 * @param {Event} event - This is a click event.
 * @returns {boolean} Returns true if clicked element has a stickyFileWindow ancestor.
 */
function hasStickyFileWindowAncestor(event) {
  let element = event.target;

  while (element) {
    if (element.classList && element.classList.contains("stickyFileWindow")) {
      return true;
    }
    element = element.parentElement;
  }

  return false;
}
