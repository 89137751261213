import React from "react";
import CompareChanges from "./CompareChanges";
import { Button } from "@mui/material";
import "./adminComponents.scss";

function ChangesCheck(props) {
  const { changesList, approveChanges, reverseChanges } = props;

  return (
    <div className="changesModal">
      <CompareChanges list={changesList} />
      <div className="confirmRevertSection">
        <Button
          variant="contained"
          className="updateButton"
          onClick={() => approveChanges()}
        >
          Approve Changes
        </Button>
        <Button
          variant="contained"
          className="updateButton"
          onClick={() => reverseChanges()}
        >
          Reverse Changes
        </Button>
      </div>
    </div>
  );
}

export default ChangesCheck;
