/**
 * @namespace Utilities.API
 * @description API calls to the backend used throughout the application.
 */

import axios from "axios";

/**
 * Base URL for all API requests.
 * Dynamically sets the base URL based on sessionStorage or defaults to a specified URL.
 * Session storage is only used for development purposes to crete custom URL for testing. It will be removed later.
 * @type {string}
 * @memberof Utilities.API
 */
export const BASE_URL =
  window.sessionStorage.getItem("url") !== null
    ? JSON.parse(window.sessionStorage.getItem("url"))
    : "https://belab.speqqle.com";

/**
 * Axios instance configured with the base URL for API requests.
 * @type {AxiosInstance}
 * @memberof Utilities.API
 */
export default axios.create({
  baseURL: BASE_URL,
});

/**
 * Generates authentication headers for API requests.
 * @memberof Utilities.API
 * @function
 * @param {string} token - The authentication token.
 * @returns {object} The headers object with Authorization header.
 */
function authHeaders(token) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

/**
 * API object containing methods for interacting with the backend.
 * Includes user authentication, user management, package management, poll management, etc.
 * @memberof Utilities.API
 */
export const api = {
  /**
   * POST request - Logs in a user and returns a token.
   * @async
   * @function
   * @param {string} username - The user's username.
   * @param {string} password - The user's password.
   * @returns {Promise<AxiosResponse>} A promise resolving to the login response.
   * @memberof Utilities.API
   */
  async logInGetToken(username, password) {
    const params = new URLSearchParams();
    params.append("username", username);
    params.append("password", password);

    return axios.put(`${BASE_URL}/api/v1/login/access-token`, params);
  },
  /**
   * GET request - Gets details about currently logged in user.
   * @async
   * @function
   * @param {string} token - Authentication token.
   * @returns {Promise<AxiosResponse>} A promise resolving to the user data retrieval response.
   * @memberof Utilities.API
   */
  async getMe(token) {
    return axios.get(`${BASE_URL}/api/v1/users/me`, authHeaders(token));
  },
  /**
   * GET request - Test request to check if token is valid.
   * @async
   * @function
   * @param {string} token - Authentication token.
   * @returns {Promise<AxiosResponse>} A promise resolving to the token test response.
   * @memberof Utilities.API
   */
  async testToken(token) {
    return axios.get(`${BASE_URL}/api/v1/login/test-token`, authHeaders(token));
  },
  /**
   * GET request - Gets all the users in the database. Admin only.
   * @async
   * @function
   * @param {string} token - Authentication token.
   * @returns {Promise<AxiosResponse>} A promise resolving to the user retrieval from DB response.
   * @memberof Utilities.API
   */
  async getUsers(token) {
    return axios.get(`${BASE_URL}/api/v1/admin/users/`, authHeaders(token));
  },
  /**
   * POST request - Creates a new user.
   * @async
   * @function
   * @param {string} token - Authentication token.
   * @param {string} data - Payload for the request.
   * @example
   * data = {
   *   "email": "user@example.com",
   *   "password": "string",
   *   "full_name": "string",
   *   "affiliation": "string",
   *   "background": "string",
   *   "interests": "string",
   *   "newsletter": false
   * }
   * @returns {Promise<AxiosResponse>} A promise resolving to the user creation response.
   * @memberof Utilities.API
   */
  async createUser(token, data) {
    return axios.post(
      `${BASE_URL}/api/v1/users/create_user`,
      data,
      authHeaders(token)
    );
  },
  /**
   * PUT request - Updates the current user.
   * @async
   * @function
   * @param {string} token - Authentication token.
   * @param {object} data - Payload for the request.
   * @example
   * data = {
   *   "email": "user@example.com",
   *   "password": "string",
   *   "full_name": "string",
   *   "affiliation": "string",
   *   "background": "string",
   *   "interests": "string",
   *   "newsletter": false
   * }
   * @returns {Promise<AxiosResponse>} A promise resolving to the current user update response.
   * @memberof Utilities.API
   */
  async updateMe(token, data) {
    return axios.put(`${BASE_URL}/api/v1/users/me`, data, authHeaders(token));
  },
  /**
   * GET request - Retrieves all the available packages for the current user.
   * @async
   * @function
   * @param {string} token - Authentication token.
   * @returns {Promise<AxiosResponse>} A promise resolving to the available user packages retrieval response.
   * @memberof Utilities.API
   */
  async getPackages(token) {
    return axios.get(`${BASE_URL}/api/v1/packages/`, authHeaders(token));
  },
  /**
   * GET request - Retrieves active packages for the current user.
   * @async
   * @function
   * @param {string} token - Authentication token.
   * @returns {Promise<AxiosResponse>} A promise resolving to the active user packages retrieval response.
   * @memberof Utilities.API
   */
  async getMyPackages(token) {
    return axios.get(`${BASE_URL}/api/v1/packages/me`, authHeaders(token));
  },
  /**
   * GET request - Gets active packages for specified user. Admin only.
   * @async
   * @function
   * @param {string} token - Authentication token.
   * @param {number} userId - Id of the user we want to get actives packages for.
   * @returns {Promise<AxiosResponse>} A promise resolving to the retrieval of specified user packages response.
   * @memberof Utilities.API
   */
  async getUserPackages(token, userId) {
    return axios.get(
      `${BASE_URL}/api/v1/admin/packages/${userId}`,
      authHeaders(token)
    );
  },
  /**
   * POST request - Creates a new user.
   * @async
   * @function
   * @param {string} token - Authentication token.
   * @param {object} data - Payload for the POST request.
   * @example
   * data = {
   *   "start": "2024-03-07",
   *   "end": "2024-03-07",
   *   "authorised_by": 0,
   *   "user_id": -1,
   *   "package_id": -1
   * }
   * @returns {Promise<AxiosResponse>} A promise resolving to package addition for specific user response.
   * @memberof Utilities.API
   */
  async addPackToUser(token, data) {
    return axios.post(
      `${BASE_URL}/api/v1/admin/packages/add_package_to_user`,
      data,
      authHeaders(token)
    );
  },
  /**
   * PUT request - Updates user related to specific ID. Admin only.
   * @async
   * @function
   * @param {string} token - Authentication token.
   * @param {object} data - Payload for the request.
   * @example
   * data = {
   *   "email": "user@example.com",
   *   "is_superuser": false,
   *   "full_name": "string",
   *   "affiliation": "string",
   *   "background": "string",
   *   "interests": "string",
   *   "created_on": "2024-03-12T16:53:14.109Z",
   *   "activated_on": "2024-03-12T16:53:14.109Z",
   *   "last_login": "2024-03-12T16:53:14.109Z",
   *   "newsletter": false,
   *   "email_confirmed": false,
   *   "password": "string"
   * }
   * @param {number} id - Id of the user we want to update.
   * @returns {Promise<AxiosResponse>} A promise resolving to the specific user update response.
   * @memberof Utilities.API
   */
  async updateUser(token, data, id) {
    return axios.put(
      `${BASE_URL}/api/v1/admin/users/${id}`,
      data,
      authHeaders(token)
    );
  },
  /**
   * POST request - Sends a message to an administrator.
   * @async
   * @function
   * @param {string} token - Authentication token.
   * @param {object} data - Payload for the request.
   * @example
   * data = {
   *   "email_title": "string",
   *   "email_content": "string"
   * }
   * @returns {Promise<AxiosResponse>} A promise resolving to sent message response.
   * @memberof Utilities.API
   */
  async sendMessage(token, data) {
    return axios.post(
      `${BASE_URL}/api/v1/users/contact_admin/`,
      data,
      authHeaders(token)
    );
  },
  /**
   * POST request - Sends a request for password recovery.
   * @async
   * @function
   * @param {string} data - String representing user email for passwor recovery.
   * @returns {Promise<AxiosResponse>} A promise resolving to the password recovery response.
   * @memberof Utilities.API
   */
  async sendPasswordReset(data) {
    return axios.post(`${BASE_URL}/api/v1/password-recovery`, data);
  },
  /**
   * POST request - Resets a password after user requests a password recovery and clicks a link in email.
   * @async
   * @function
   * @param {object} data - Payload for the request.
   * @example
   * data = {
   *   "token": "string",
   *   "new_password": "string",
   *   "password_confirm": "string"
   * }
   * @returns {Promise<AxiosResponse>} A promise resolving to the password reset response.
   * @memberof Utilities.API
   */
  async resetPassword(data) {
    return axios.post(`${BASE_URL}/api/v1/reset-password`, data);
  },
  /**
   * POST request - Creates a new poll. Admin only.
   * @async
   * @function
   * @param {string} token - Authentication token.
   * @param {object} data - Payload for the request.
   * @example
   * data = {
   *   "id": 0,
   *   "question": "string",
   *   "starting_date": "2024-03-12T19:04:14.269Z",
   *   "closing_date": "2024-03-12T19:04:14.269Z",
   *   "answers": [
   *     "string"
   *   ]
   * }
   * @returns {Promise<AxiosResponse>} A promise resolving to the poll creation response.
   * @memberof Utilities.API
   */
  async createNewPoll(token, data) {
    return axios.post(
      `${BASE_URL}/api/v1/admin/polls/create`,
      data,
      authHeaders(token)
    );
  },
  /**
   * GET request - Gets active polls for current user.
   * @async
   * @function
   * @param {string} token - Authentication token.
   * @returns {Promise<AxiosResponse>} A promise resolving to the retrieval of active polls response.
   * @memberof Utilities.API
   */
  async getActivePolls(token) {
    return axios.get(
      `${BASE_URL}/api/v1/polls/get_active_polls`,
      authHeaders(token)
    );
  },
  /**
   * GET request - Gets poll results for specified poll.
   * @async
   * @function
   * @param {string} token - Authentication token.
   * @param {number} poll_id - Id of the poll we want to get results for.
   * @returns {Promise<AxiosResponse>} A promise resolving to the retrieval of specified poll results response.
   * @memberof Utilities.API
   */
  async getPollResults(token, poll_id) {
    return axios.get(
      `${BASE_URL}/api/v1/admin/polls/get_poll_results/${poll_id}`,
      authHeaders(token)
    );
  },
  /**
   * GET request - Gets all the polls in our DB. Admin only.
   * @async
   * @function
   * @param {string} token - Authentication token.
   * @returns {Promise<AxiosResponse>} A promise resolving to the retrieval of all the polls response.
   * @memberof Utilities.API
   */
  async getAllPolls(token) {
    return axios.get(`${BASE_URL}/api/v1/admin/polls/get_all`, authHeaders(token));
  },
  /**
   * GET request - Gets answers for a specific poll. Admin only.
   * @async
   * @function
   * @param {string} token - Authentication token.
   * @param {number} pollId - Id of the specific poll we want to get answers for.
   * @returns {Promise<AxiosResponse>} A promise resolving to the retrieval of specified poll answers response.
   * @memberof Utilities.API
   */
  async getPollAnswers(token, pollId) {
    return axios.get(
      `${BASE_URL}/api/v1/polls/get_answers/${pollId}`,
      authHeaders(token)
    );
  },
  /**
   * POST request - Sends an answer to the poll.
   * @async
   * @function
   * @param {string} token - Authentication token.
   * @param {object} data - Payload for the request.
   * @example
   * data = {
   *   "poll_id": 0,
   *   "answer_local_id": 0
   * }
   * @returns {Promise<AxiosResponse>} A promise resolving to the poll answering response.
   * @memberof Utilities.API
   */
  async answerPoll(token, data) {
    return axios.post(
      `${BASE_URL}/api/v1/polls/answer`,
      data,
      authHeaders(token)
    );
  },
  /**
   * PUT request - Updates specific poll indetified with id. Admin only.
   * @async
   * @function
   * @param {string} token - Authentication token.
   * @param {object} data - Payload for the request.
   * @example
   * data = {
   *   "update_data": {
   *     "id": 0,
   *     "question": "string",
   *     "starting_date": "2024-03-12T19:35:27.546Z",
   *     "closing_date": "2024-03-12T19:35:27.546Z"
   *   },
   *   "answer_in": [
   *     {
   *       "id": 0,
   *       "answer": "string",
   *       "poll_id": 0,
   *       "answer_local_id": 0
   *     }
   *   ]
   * }
   * @param {number} pollId - Id representing a poll we want to update.
   * @returns {Promise<AxiosResponse>} A promise resolving to the poll update response.
   * @memberof Utilities.API
   */
  async updatePoll(token, data, pollId) {
    return axios.put(
      `${BASE_URL}/api/v1/admin/polls/update?poll_id=${pollId}`,
      data,
      authHeaders(token)
    );
  },
  /**
   * DELETE request - Deletes a specific poll. Admin Only.
   * @async
   * @function
   * @param {string} token - Authentication token.
   * @param {number} pollId - Id for poll identification.
   * @returns {Promise<AxiosResponse>} A promise resolving to the poll delete response.
   * @memberof Utilities.API
   */
  async deletePoll(token, pollId) {
    const authHeadersObj = authHeaders(token);
    return axios.delete(`${BASE_URL}/api/v1/admin/polls/delete?poll_id=${pollId}`, {
      headers: authHeadersObj.headers,
    });
  },
  /**
   * POST request - Confirms user email. This is done after user clicks a link in email.
   * @async
   * @function
   * @param {string} data - Token generated by BE for email confirmation.
   * @returns {Promise<AxiosResponse>} A promise resolving to the email confirmation response.
   * @memberof Utilities.API
   */
  async confirmEmail(data) {
    return axios.post(`${BASE_URL}/api/v1/users/confirm-email`, data);
  },
  /**
   * DELETE request - Deletes a current user.
   * @async
   * @function
   * @param {string} token - Authentication token.
   * @returns {Promise<AxiosResponse>} A promise resolving to the current user delete response.
   * @memberof Utilities.API
   */
  async deleteCurrentUser(token) {
    const authHeadersObj = authHeaders(token);
    return axios.delete(`${BASE_URL}/api/v1/users/me`, {
      headers: authHeadersObj.headers,
    });
  },
  /**
   * DELETE request - Deletes a specific user. Admin only.
   * @async
   * @function
   * @param {string} token - Authentication token.
   * @param {number} userId - Id representing a user.
   * @returns {Promise<AxiosResponse>} A promise resolving to the specific user delete response.
   * @memberof Utilities.API
   */
  async deleteUser(token, userId) {
    const authHeadersObj = authHeaders(token);
    return axios.delete(`${BASE_URL}/api/v1/admin/users/${userId}`, {
      headers: authHeadersObj.headers,
    });
  },
  // OLD ENDPOINTS
  // async passwordRecovery(email) {
  //   return axios.post(`${BASE_URL}/api/v1/password-recovery/${email}`);
  // },
  // async resetPassword(password, token) {
  //   return axios.post(`${BASE_URL}/api/v1/reset-password/`, {
  //     new_password: password,
  //     token,
  //   });
  // },
};
