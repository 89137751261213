import React from 'react';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: null, errorInfo: null };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        console.error("Uncaught error:", error, errorInfo);
        this.setState({ error: error, errorInfo: errorInfo });
    }

    render() {
        if (this.state.hasError) {
            // Extracting FallbackComponent and fallbackProps from props
            const { fallbackComponent: FallbackComponent, fallbackProps } = this.props;
            // Merging component-specific props and general fallback props
            const props = { ...fallbackProps, originalError: this.state.error, errorInfo: this.state.errorInfo };
            return <FallbackComponent {...props} />;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
