import React from "react";
import "../leftSide.scss";

function ModalListEntry(props) {
  const {
    modelName,
    modelDescription,
    reffitID,
    // id,
    handleClick,
    // abbreviation,
    // params,
  } = props;
  return (
    <div className="modalListEntry" onClick={() => handleClick(reffitID)}>
      <div className="modalListEntryName" title={modelDescription}>
        {modelName}
      </div>
    </div>
  );
}

export default ModalListEntry;
