import React, { useState, useEffect } from "react";
import { getGroupColor } from "../../../theme/groupingColors";
import "./clashing.scss";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

function ClashToSolve(props) {
  const { groupId, groups, currentValue, incomingValue, solve } = props;

  const [freeGroups, setFreeGroups] = useState([]);
  const [chosenNewGroup, setChosenNewGroup] = useState(null);

  useEffect(() => {
    const availableGroups = [];

    for (let i = 0; i < groups.length; i++) {
      const group = groups[i];
      if (group.value === null) {
        availableGroups.push(group.groupNumber);
      }
    }

    if (availableGroups.length > 0 && chosenNewGroup === null) {
      setChosenNewGroup(availableGroups[0]);
    }

    setFreeGroups(availableGroups);
  }, [groups]);

  const handleKeepCurrent = () => {
    solve(groupId, chosenNewGroup, "CURRENT_STAYS")
  };
  
  const handleKeepIncoming = () => {
    solve(groupId, chosenNewGroup, "NEW_STAYS")
  };
  
  const handleNewGroupSolution = () => {
    solve(groupId, chosenNewGroup, "NEW_GROUP")
  }

  const handleGroupChange = (event) => {
    setChosenNewGroup(event.target.value);
  };

  return (
    <div className="clashToSolve">
      <div className="groupNumberSection">
        <div className="text">Group in conflict: </div>
        <div
          className="groupNumber"
          style={{ backgroundColor: getGroupColor(groupId) }}
        >
          {groupId}
        </div>
      </div>
      <div className="values">
        <div className="valueSection">
          <div className="valueTitle">Current value:</div>
          <div className="value">{currentValue}</div>
        </div>
        <div className="valueSection">
          <div className="valueTitle">Incoming value:</div>
          <div className="value">{incomingValue}</div>
        </div>
      </div>
      <div className="header">Choose a conflict solution:</div>
      <div className="solutionArea">
        <Button
          variant="contained"
          size="small"
          sx={{ m: 1 }}
          onClick={() => handleKeepCurrent()}
          className="solutionButton"
        >
          Keep Current
        </Button>
        <Button
          variant="contained"
          size="small"
          sx={{ m: 1 }}
          onClick={() => handleKeepIncoming()}
          className="solutionButton"
        >
          Keep Incoming
        </Button>
      </div>
      {freeGroups.length && chosenNewGroup !== null > 0 ? (
        <div className="newGroupChoice">
          <div className="newGroupTitle">
            Or select new available group for incoming value:
          </div>
          <div className="choosingGroup">
            <div className="selectArea">
              <FormControl fullWidth variant="standard" className="formControl">
                <InputLabel id="group-select-label" className="groupInputLabel">
                  New Group:
                </InputLabel>
                <Select
                  MenuProps={{
                    container: () => document.getElementById("clashModal"),
                  }}
                  labelId="group-select-label"
                  value={chosenNewGroup}
                  onChange={handleGroupChange}
                  style={{ backgroundColor: getGroupColor(chosenNewGroup) }}
                  className="select"
                >
                  {freeGroups.map((group) => {
                    return (
                      <MenuItem
                        key={group}
                        value={group}
                        className="menuItem"
                        style={{ backgroundColor: getGroupColor(group) }}
                      >
                        {group}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
            <div className="buttonContainer">
              <Button
                variant="contained"
                size="small"
                sx={{ m: 1 }}
                onClick={() => handleNewGroupSolution()}
                className="solutionButton"
              >
                New Group
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div>
          No free groups are available
        </div>
      )}
    </div>
  );
}

export default ClashToSolve;
