import React, { useEffect, useState } from "react";
import WestIcon from "@mui/icons-material/West";
import EastIcon from "@mui/icons-material/East";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import { getGroupColor } from "../../../theme/groupingColors";
import "./clashing.scss";

function SolvedClash(props) {
  const { currentVal, currentGroup, newVal, newGroup, solution } = props;
  const [solutionIcon, setSolutionIcon] = useState(null);

  useEffect(() => {
    switch (solution) {
      case "CURRENT_STAYS":
        setSolutionIcon(
          <div title="Current value chosen">
            <EastIcon className="solutionIcon" />
          </div>
        );
        break;
      case "NEW_STAYS":
        setSolutionIcon(
          <div title="Incoming value chosen">
            <WestIcon className="solutionIcon" />
          </div>
        );
        break;
      case "NEW_GROUP":
        setSolutionIcon(
          <div title="Changed groups">
            <LinkOffIcon className="solutionIcon" />
          </div>
        );
        break;

      default:
        break;
    }
  }, []);

  return (
    <div className="solvedClash">
      <div
        className="value"
        style={{ backgroundColor: getGroupColor(currentGroup) }}
        title={`Group: ${currentGroup}`}
      >
        {currentVal}
      </div>
      {solutionIcon}

      <div
        className="value"
        style={{ backgroundColor: getGroupColor(newGroup) }}
        title={`Group: ${newGroup}`}
      >
        {newVal}
      </div>
    </div>
  );
}

export default SolvedClash;
