import React, { useState, useContext, useRef, useEffect } from "react";
import "./polling.scss";
import AnswerBar from "./AnswerBar";
import { formatDate, hasProperty } from "../../../utils/helpers";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import { api } from "../../../utils/authActions";
import { AuthContext } from "../../../context/AuthContext";
import { GeneralContext } from "../../../context/GeneralContext";
import ConfirmationScreen from "../../commonComponents/ConfirmationScreen";
import TextField from "@mui/material/TextField";
import Modal from "react-modal";
import AnswerEntry from "./AnswerEntry";
import { deepCopy } from "../../leftSide/Models/modelLogic";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs-plugin-utc";
import toast from "react-hot-toast";
import useUpdatedRef from "../../../utils/hooks/useUpdatedRef";

dayjs.extend(dayjsPluginUTC);

function PollWithAnswers(props) {
  const {
    question,
    start,
    end,
    pollId,
    active = false,
    handleDeletePoll,
    handlePollUpdate,
  } = props;
  const { authToken } = useContext(AuthContext);
  const { recordedErrorLog } = useContext(GeneralContext);
  const [expanded, setExpanded] = useState(false);
  const [answers, setAnswers] = useState([]);
  const [loading, setLoading] = useState();
  const [totalVotes, setTotalVotes] = useState(0);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [editOpen, editOpenRef, setEditOpen] = useUpdatedRef(false);
  // const [editOpen, setEditOpen] = useState(false);
  const [questionEdit, setQuestionEdit] = useState(question);
  const [answerEdit, setAnswerEdit] = useState([]);
  const [noEnd, setNoEnd] = useState(false);
  const [noStart, setNoStart] = useState(false);
  const [startDate, setStartDate] = useState(dayjs(start));
  const [endDate, setEndDate] = useState(dayjs(end));
  const [pollWasEdited, setPollWasEdited] = useState(false);
  const [largestLoacalId, setLargestLocalId] = useState(null);
  const iconsRef = useRef();

  const startNormalized = formatDate(start);
  const endNormalized = formatDate(end);

  useEffect(() => {
    try {
      if (!editOpen) {
        setAnswerEdit(answers);
      }
      if (answers.length > 0) {
        const largestId = answers.reduce((currentLargest, answerToCheck) => {
          return answerToCheck.answer_local_id > currentLargest
            ? answerToCheck.answer_local_id
            : currentLargest;
        }, answers[0].answer_local_id);

        setLargestLocalId(largestId);
      }
    } catch (error) {
      recordedErrorLog("'answers' useEffect failure: ", error);
    }
  }, [answers]);

  useEffect(() => {
    try {
      if (pollWasEdited) {
        api
          .getPollResults(authToken, pollId)
          .then((response) => {
            let totalCount = 0;
            for (let i = 0; i < response.data.answers.length; i++) {
              const answer = response.data.answers[i];
              totalCount = totalCount + answer.count;
            }

            setTotalVotes(totalCount);
            setAnswers(response.data.answers);
            if (editOpen) {
              setAnswerEdit(response.data.answers);
            }
          })
          .catch((err) => {
            recordedErrorLog("THERE WAS ERROR WITH RESULT RETRIEVAL: ", err);
            toast.error("Could not retrieve poll answers.");
          })
          .finally(() => {
            setLoading(false);
          });

        setPollWasEdited(false);
      }
    } catch (error) {
      recordedErrorLog("Poll editing detection useEffect failed: ", error);
    }
  }, [pollWasEdited]);

  const handleExpandIconClick = () => {
    try {
      if (!expanded) {
        setLoading(true);

        api
          .getPollResults(authToken, pollId)
          .then((response) => {
            let totalCount = 0;
            for (let i = 0; i < response.data.answers.length; i++) {
              const answer = response.data.answers[i];
              totalCount = totalCount + answer.count;
            }

            setTotalVotes(totalCount);
            setAnswers(response.data.answers);
            if (editOpenRef.current) {
              setAnswerEdit(response.data.answers);
            }
          })
          .catch((err) => {
            recordedErrorLog("THERE WAS ERROR WITH RESULT RETRIEVAL: ", err);
            toast.error("Could not retrieve poll answers.");
          })
          .finally(() => {
            setLoading(false);
          });
      }
      setExpanded((old) => !old);
    } catch (error) {
      recordedErrorLog("Expand icon click handler failure: ", error);
    }
  };

  const handleDelete = () => {
    api
      .deletePoll(authToken, pollId)
      .then((response) => {
        console.log("poll delete successfull: ", response);
      })
      .catch((err) => {
        recordedErrorLog("THERE WAS ERROR WITH POLL DELETE: ", err);
        toast.error("Could not delete the poll. Check console for more info.");
      });

    handleDeletePoll(pollId, active);
    setConfirmDeleteOpen(false);
  };

  const requestDelete = () => {
    setConfirmDeleteOpen(true);
  };

  const handleEditPoll = () => {
    try {
      if (answerEdit.length > 1 && questionEdit !== "") {
        let largestId = largestLoacalId;
        const updatedAnswers = answerEdit.map((answer) => {
          if (hasProperty(answer, "answer_local_id")) {
            return {
              answer: answer.answer,
              answer_local_id: answer.answer_local_id,
            };
          } else {
            largestId = largestId + 1;
            return {
              answer: answer.answer,
              answer_local_id: largestId,
            };
          }
        });

        // no need to update largest local id here, since after we update the poll, we load it in from BE again,
        // to make sure that the poll displayed is actually the one stored in BE

        const payload = {
          update_data: {
            id: pollId,
            question: questionEdit,
          },
          answer_in: updatedAnswers,
        };

        if (!noStart) {
          payload.update_data.starting_date = startDate.utc().toISOString();
        }
        if (!noEnd) {
          payload.update_data.closing_date = endDate.utc().toISOString();
        }

        api
          .updatePoll(authToken, payload, pollId)
          .then(() => {
            handlePollUpdate();
            setEditOpen(false);
            setPollWasEdited(true);
          })
          .catch((err) => {
            recordedErrorLog("THERE WAS AN ERROR WITH POLL UPDATE: ", err);
            toast.error(
              "There was a problem with poll update, check console for more information"
            );
          });
      }
    } catch (error) {
      recordedErrorLog("Poll editing handler has failed: ", error);
    }
  };

  const handleDeleteAnswer = (index) => {
    try {
      const newAnswersArray = deepCopy(answerEdit);
      newAnswersArray.splice(index, 1);
      setAnswerEdit(newAnswersArray);
    } catch (error) {
      recordedErrorLog("Answer deletion handler has failed: ", error);
    }
  };

  const handleAnswerChange = (value, index) => {
    try {
      const newAnswersArray = deepCopy(answerEdit);
      newAnswersArray[index] = {
        ...newAnswersArray[index],
        answer: value,
      };
      setAnswerEdit(newAnswersArray);
    } catch (error) {
      recordedErrorLog("Answer change handler has failed: ", error);
    }
  };

  const handleAddNewAnswer = () => {
    setAnswerEdit((old) => [...old, { answer: "" }]);
  };

  const handleEditClick = () => {
    if (!expanded) {
      handleExpandIconClick();
    }

    setEditOpen(true);
  };

  return (
    <div className={`pollWithAnswers`}>
      <div className="pollHeader">
        <div className="questionExpand" onClick={() => handleExpandIconClick()}>
          {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          <div className="question">{question}</div>
        </div>
        <div className="controlIcons" ref={iconsRef}>
          {active ? <EditIcon onClick={() => handleEditClick()} /> : <></>}
          <DeleteForeverIcon onClick={() => requestDelete()} />
          <ConfirmationScreen
            onYes={handleDelete}
            onNo={() => setConfirmDeleteOpen(false)}
            text={"Are you sure you want to DELETE this poll?"}
            open={confirmDeleteOpen}
            setOpen={setConfirmDeleteOpen}
            element={iconsRef}
          />
          <Modal
            isOpen={editOpen}
            onRequestClose={() => setEditOpen(false)}
            shouldCloseOnOverlayClick={true}
            contentLabel="Poll edit Modal"
            appElement={iconsRef.current}
            id="pollEditingModal"
            style={{
              content: {
                width: "55vw",
                height: "60vh",
                top: "50%",
                left: "50%",
                right: "auto",
                bottom: "auto",
                marginRight: "-50%",
                transform: "translate(-50%, -50%)",
              },
              overlay: {
                zIndex: "9000",
              },
            }}
          >
            <div className="pollEditModal">
              <div className="questionWithAnswers">
                <div className="title">Edit poll Question:</div>
                <TextField
                  className="editQuestionEntry"
                  size="small"
                  value={questionEdit}
                  onChange={(e) => setQuestionEdit(e.target.value)}
                  label="Edit poll question"
                />
                <div className="title">Edit poll Answers:</div>
                {answerEdit.length > 0 ? (
                  <div className="answers">
                    {answerEdit.map((answer, index) => {
                      return (
                        <AnswerEntry
                          key={index}
                          value={answer.answer}
                          setValue={handleAnswerChange}
                          index={index}
                          deleteAnswer={handleDeleteAnswer}
                          deleteAllowed
                        />
                      );
                    })}
                  </div>
                ) : (
                  <div className="loading">Loading answers...</div>
                )}
                <div className="addAnswerArea">
                  <div
                    className="addAnswerButton"
                    onClick={() => handleAddNewAnswer()}
                  >
                    <AddIcon />
                    <div className="addText">Add answer</div>
                  </div>
                </div>
              </div>
              <div className="durationArea">
                <div className="duration">
                  <div className="title">Starting date:</div>
                  <div className="checkboxArea">
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={noStart}
                            onChange={() => setNoStart((old) => !old)}
                          />
                        }
                        label={"Poll has no start date"}
                      />
                    </FormGroup>
                  </div>
                  <div className={`time`}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Date"
                        value={startDate}
                        onChange={(newValue) => setStartDate(newValue)}
                        disabled={noStart}
                        PopperProps={{
                          style: { zIndex: 9999 }, // Ensure this z-index is higher than the modal's overlay
                        }}
                      />
                      <TimePicker
                        label="Time"
                        className="timeSelector"
                        value={startDate}
                        onChange={(newValue) => setStartDate(newValue)}
                        disabled={noStart}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
                <div className="duration">
                  <div className="title">End date:</div>
                  <div className="checkboxArea">
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={noEnd}
                            onChange={() => setNoEnd((old) => !old)}
                          />
                        }
                        label={"Poll has no end date"}
                      />
                    </FormGroup>
                  </div>
                  <div className={`time`}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Date"
                        value={endDate}
                        onChange={(newValue) => setEndDate(newValue)}
                        disabled={noEnd}
                        minDate={startDate || dayjs()}
                      />
                      <TimePicker
                        label="Time"
                        className="timeSelector"
                        value={endDate}
                        onChange={(newValue) => setEndDate(newValue)}
                        disabled={noEnd}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
              </div>
              <div className="buttonArea">
                <Button
                  variant="contained"
                  className="solveButton"
                  onClick={() => handleEditPoll()}
                  disabled={answerEdit.length < 2 || questionEdit === ""}
                >
                  Change Poll
                </Button>
              </div>
            </div>
          </Modal>
        </div>
      </div>
      {expanded ? (
        <div className="expandableArea">
          {!loading && answers.length > 0 ? (
            <div className="answers">
              {answers.map((answer) => {
                return (
                  <div className="answer" key={answer.id}>
                    <AnswerBar
                      answer={answer.answer}
                      value={answer.count}
                      total={totalVotes}
                    />
                  </div>
                );
              })}
            </div>
          ) : (
            <></>
          )}
          {loading ? <div className="loading">Loading ...</div> : <></>}
          <div className="times">
            <div className="start">Start: {startNormalized}</div>
            <div className="end">End: {endNormalized}</div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default PollWithAnswers;
