import React, { useState, useContext, useEffect } from "react";
import { GraphContext } from "../../context/GraphContext";
import { DashboardContext } from "../../context/DashboardContext";
import { WebSocketContext } from "../../context/WebSocketContext";
import { GeneralContext } from "../../context/GeneralContext";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import FileSettings from "../rightSide/parameters/FileSettings";
import Modal from "react-modal";
import { adjustModalPositionAndSize, vwToPixels } from "../../utils/helpers";

function AutoFitModal(props) {
  const {
    reference,
    model,
    autofitModalIsOpen,
    setAutofitModalIsOpen,
    preSelectedFile = null,
  } = props;
  const { setRequestedFitModel } = useContext(GraphContext);
  const { setUndoModels, uploadedFiles } = useContext(DashboardContext);
  const { sendJsonMessage } = useContext(WebSocketContext);
  const { recordedErrorLog } = useContext(GeneralContext);
  const [fileDetailsVisible, setFileDetailsVisible] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [fileSelected, setSelectedFile] = useState(preSelectedFile);
  const [modalPlaceAndSize, setModalPlaceAndSize] = useState({
    top: "0",
    left: "0",
    height: "300px",
    width: "300px",
  });

  useEffect(() => {
    try {
      if (autofitModalIsOpen) {
        const modalPosition = {
          top:
            reference.current != undefined
              ? reference.current.getBoundingClientRect().top
              : 0,
          left:
            reference.current != undefined
              ? reference.current.getBoundingClientRect().left
              : vwToPixels(17),
          right: "auto",
        };
        const modalSize = { width: 385, height: 610 };

        const adjusted = adjustModalPositionAndSize(modalPosition, modalSize);

        setModalPlaceAndSize(adjusted);
      }
    } catch (error) {
      recordedErrorLog(
        "Autofit modal open checker useEffect has failed: ",
        error
      );
    }
  }, [autofitModalIsOpen]);

  useEffect(() => {
    if (preSelectedFile !== null) {
      setFileDetailsVisible(true);
      setSelectedFile(preSelectedFile);
    }
  }, [preSelectedFile]);

  useEffect(() => {
    try {
      if (model !== null && model.autoFit) {
        setFileList(
          uploadedFiles.map((file) => {
            return (
              <div
                className="fileEntry"
                key={file.ID}
                onClick={() => handleFileClick(file)}
                data-testid="file-entry"
              >
                {file.name}
              </div>
            );
          })
        );
      }
    } catch (error) {
      recordedErrorLog(
        "uploaded files and model useEffect has failed: ",
        error
      );
    }
  }, [uploadedFiles, model]);

  const handleFileClick = (file) => {
    setSelectedFile(file);
    setFileDetailsVisible(true);
  };

  const handleBackClick = () => {
    setFileDetailsVisible(false);
  };

  const confirmFile = (payload, modelID) => {
    try {
      setRequestedFitModel((old) => [
        ...old,
        { modelId: modelID, quantity: 5 },
      ]); // Default quantity for raman is 5
      setUndoModels((old) => {
        if (old.some((undoModel) => model.FE_ID === undoModel.FE_ID)) {
          const updatedUndoModels = old.map((oldModel) => {
            if (oldModel.FE_ID === model.FE_ID) {
              return model;
            } else {
              return oldModel;
            }
          });

          return updatedUndoModels;
        } else {
          return [...old, model];
        }
      });
      sendJsonMessage(payload, { type: "autofit" });
      setAutofitModalIsOpen(false);
    } catch (error) {
      recordedErrorLog("File confirmation has failed: ", error);
    }
  };

  const handleCloseModal = () => {
    setFileDetailsVisible(false);
    setAutofitModalIsOpen(false);
  };

  return (
    <Modal
      isOpen={autofitModalIsOpen}
      onRequestClose={handleCloseModal}
      shouldCloseOnOverlayClick={true}
      contentLabel="AutoFit Modal"
      id="autoFit-modal"
      appElement={reference.current}
      style={{
        content: {
          width: modalPlaceAndSize.width,
          height: modalPlaceAndSize.height,
          top: modalPlaceAndSize.top,
          left: modalPlaceAndSize.left,
          right: modalPlaceAndSize.right,
        },
        overlay: {
          backgroundColor: "transparent",
          zIndex: "9900",
        },
      }}
    >
      <div>
        <div
          data-testid="autofit-modal"
          id="autofit-modal-selection"
          className={`modal-content ${
            fileDetailsVisible ? "slide-out-left" : "slide-in-right"
          }`}
        >
          <div>Choose a File:</div>
          <hr />
          {fileList.length > 0 ? fileList : <></>}
        </div>
      </div>
      {fileDetailsVisible && (
        <div className="modal-content slide-in-right">
          <div
            onClick={handleBackClick}
            className="backSection"
            data-testid="back-button"
          >
            <ArrowBackIosNewIcon className="backIcon" />
            <div className="backText">Back To File List</div>
          </div>
          <hr />
          {model !== null ? (
            <FileSettings
              file={fileSelected}
              confirmFile={confirmFile}
              modelID={model.FE_ID}
              modelReffitID={model.reffitID}
            />
          ) : (
            <></>
          )}
        </div>
      )}
    </Modal>
  );
}

export default AutoFitModal;
