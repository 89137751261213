import React from "react";
import EastIcon from "@mui/icons-material/East";
import "./adminComponents.scss";

function CompareChanges(props) {
  const { list } = props;

  return (
    <div className="compareChanges">
      <div className="title">Changes made:</div>
      <div className="changesList">
        {list.map((entry) => {
          if (entry.title === "Password") {
            return (
              <div className="listEntry" key={entry.title}>
                <div className="entryTitle">Password update</div>
              </div>
            );
          }
          return (
            <div className="listEntry" key={entry.title}>
              <div className="entryTitle">{entry.title}:</div>
              <div className="valueContainer">
                <div className="value">{entry.prev}</div>
                <EastIcon className="icon" />
                <div className="value">{entry.new}</div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default CompareChanges;
