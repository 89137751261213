/**
 * @namespace Utilities.Hooks
 * @description This hook is used to bind ref and state together. It is used in Sticky Parameter window.
 * We need this to have quick ref value updates and ability to trigger useEffect with state changes.
 */

import { useState, useRef, useEffect } from "react";

/**
 * Custom hook to keep a state value and its ref synchronized.
 *
 * This hook initializes a state and a ref with the same initial value.
 * Whenever the state updates, the ref is also updated to reflect the new state.
 * Provides a setter function to update the state and ref simultaneously.
 *
 * @memberof Utilities.Hooks
 * @param {any} initialValue The initial value of the state and ref.
 * @returns {Object} An object containing:
 *  - state: The current state.
 *  - ref: A ref object pointing to the current state.
 *  - setUpdatedRef: A function to update the state and the ref.
 */
export default function useUpdatedRef(initialValue) {
  const [state, setState] = useState(initialValue);
  const ref = useRef(initialValue);

  useEffect(() => {
    ref.current = state;
  }, [state]);

  const setUpdatedRef = (newValue) => {
    ref.current = newValue;
    setState(newValue);
  };

  return [state, ref, setUpdatedRef];
}
