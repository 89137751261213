import React, { useState, useContext } from "react";
import { Button, TextField } from "@mui/material";
import { api } from "../../utils/authActions";
import { AuthContext } from "../../context/AuthContext";
import { GeneralContext } from "../../context/GeneralContext";
import toast from "react-hot-toast";
import "./profilePages.scss";

function ContactUs() {
  const { authToken } = useContext(AuthContext);
  const { recordedErrorLog } = useContext(GeneralContext);
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const handleSubjChange = (e) => {
    setSubject(e.target.value);
  };

  const handleMessageSend = () => {
    try {
      if (subject === "" || message === "") {
        console.warn("Subject and message cannot be empty");
      } else {
        const payload = {
          email_title: subject,
          email_content: message,
        };
        api
          .sendMessage(authToken, payload)
          .then((response) => {
            if (response.data === "Email sent to admin") {
              setSubject("");
              setMessage("");
              toast.success("Message sent successfully!");
            }
          })
          .catch((err) => {
            recordedErrorLog("Send message Api failed", err);
          });
      }
    } catch (error) {
      recordedErrorLog("Message send handler has failed: ", error);
    }
  };

  return (
    <div className="contactPage">
      <div className="themeArea">
        <div className="messageTitle">Subject</div>
        <TextField
          label="Subject"
          id="subject-input"
          value={subject}
          onChange={(e) => handleSubjChange(e)}
          className="subjectInput"
          size="small"
        />
      </div>
      <div className="textInput">
        <div className="messageTitle">Message</div>
        <textarea
          placeholder="Message"
          id="message-input"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
      </div>
      <div className="buttonArea">
        <Button
          variant="contained"
          className="messageButton"
          id="send-message-button"
          onClick={() => handleMessageSend()}
        >
          Send Message
        </Button>
      </div>
    </div>
  );
}

export default ContactUs;
