export function getGroupColor(groupNumber) {
  switch (groupNumber) {
    case 1:
      return "#D3F8E2";
    case 2:
      return "#E4C1F9";
    case 3:
      return "#F694C1";
    case 4:
      return "#EDE7B1";
    case 5:
      return "#A9DEF9";
    case 6:
      return "#ffd6a5";
    case 7:
      return "#ffadad";
    case 8:
      return "#caffbf";
    case 9:
      return "#a0c4ff";
    case 10:
      return "#bdb2ff";
    default:
      return "#ffadad";
  }
}
