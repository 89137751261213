import React, { useState, useEffect } from "react";
import ToggleDisplayModal from "./ToggleDisplayModal";
import SlideOutWindow from "./SlideOutWindow";
import "./commonComponents.scss";

function ToggleButton(params) {
  const {
    handleStatusChange,
    type = "",
    modal,
    content,
    position,
    title,
    needSlideOutClose,
    white = false,
    fitContent = false,
    callFromGraph = false,
    id = "",
    noBgNoBorder = false,
  } = params;
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (callFromGraph) {
      setOpenModal(true);
    }
  }, [callFromGraph]);

  function handleClick() {
    if (handleStatusChange != undefined) {
      handleStatusChange(!openModal, type);
    }
    setOpenModal(!openModal);
  }

  const localModal =
    modal == undefined ? (
      <ToggleDisplayModal
        content={content}
        white={white}
        fitContent={fitContent}
        noBgNoBorder={noBgNoBorder}
      />
    ) : (
      modal
    );

  return (
    <>
      {id !== "" ? (
        <div
          className={`toggleButton ${position == "right" ? "right" : ""}`}
          onClick={() => handleClick()}
          data-testid="toggle-button"
          id={id}
        >
          {title}
        </div>
      ) : (
        <div
          className={`toggleButton ${position == "right" ? "right" : ""}`}
          onClick={() => handleClick()}
          data-testid="toggle-button"
        >
          {title}
        </div>
      )}
      {openModal ? localModal : <></>}
      {openModal && needSlideOutClose ? (
        <SlideOutWindow
          onClick={() => handleClick()}
          fitContent
          data-testid="slide-out-window"
        />
      ) : (
        <></>
      )}
    </>
  );
}

export default ToggleButton;
