/**
 * @category LeftSide
 * @component
 * @module Files/fileProcessing/PresetLoader
 * @description This component is a list of presets and is responsible for saving/loading presets when in
 * FileProcessingWindow and for setting/clearing preset when in loading options window. It also provides a
 * preset deletion functionality while not allowing deletion of the default preset.
 */
import React, { useState, useEffect } from "react";
import delImg from "../../../../res/icons/delete.png";
import { Button } from "@mui/material";
import "./fileProcessing.scss";

function PresetLoader(props) {
  const {
    presetList,
    load,
    savePreset,
    deleteEntry,
    optionMenu = false,
    setPreset,
    selectedPreset,
    clearPreset,
    wasChanged,
    setWasChanged,
  } = props;
  const [selectedId, setSelectedId] = useState(
    optionMenu ? (selectedPreset !== null ? selectedPreset.id : null) : null
  );

  useEffect(() => {
    if (wasChanged) {
      setWasChanged(false);
      setSelectedId(null);
    }
  }, [wasChanged]);

  const handleEntrySelect = (e, id) => {
    if (e.target.className !== "delIcon") {
      setSelectedId(id);
      if (!optionMenu) {
        load(id);
      }
    }
  };

  return (
    <div className="presetLoader">
      <div className="presetList">
        {presetList.map((preset) => {
          return (
            <div
              className={`presetEntry ${
                selectedId === preset.id ? "selected" : ""
              }`}
              key={preset.id}
              onClick={(e) => handleEntrySelect(e, preset.id)}
            >
              <div className="presetName">{preset.presetName}</div>
              {preset.presetName !== "Default" ? (
                <img
                  src={delImg}
                  alt="Delete Preset Button"
                  className="delIcon"
                  onClick={() => deleteEntry(preset.id)}
                />
              ) : (
                <></>
              )}
            </div>
          );
        })}
      </div>
      {optionMenu ? (
        <div className="presetButtonArea">
          <Button
            variant="contained"
            className="loadPresetButton"
            onClick={() => setPreset(selectedId)}
          >
            Set
          </Button>
          <Button
            variant="contained"
            className="loadPresetButton"
            onClick={() => clearPreset()}
          >
            Clear
          </Button>
        </div>
      ) : (
        <div className="presetButtonArea">
          <Button
            variant="contained"
            className="loadPresetButton"
            onClick={() => savePreset()}
          >
            Save
          </Button>
          {/* <Button
            variant="contained"
            className="loadPresetButton"
            onClick={() => load(selectedId)}
          >
            Load
          </Button> */}
        </div>
      )}
    </div>
  );
}

export default PresetLoader;
