import React from "react";
import Modal from "react-modal";
import { Button } from "@mui/material";

const MaxMinConfirmationPopUp = (props) => {
  const {
    isOpen,
    onAccept,
    onCancel,
    isMin,
    incomingVal,
    currentVal,
    reference,
  } = props;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCancel}
      contentLabel="Confirmation Modal"
      shouldCloseOnOverlayClick={false}
      appElement={reference.current}
      style={{
        content: {
          top: "35%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          transform: "translate(-50%, -35%)",
          width: "550px",
          height: "180px",
        },
        overlay: {
          zIndex: "9999",
        },
      }}
    >
      <div className="minMaxConfirmModalContent">
        {isMin ? (
          <p>
            You are setting hard minimum value: {incomingVal}, which is greater
            than current value: {currentVal}. If you procceed, the value will be
            changed to: {incomingVal}.
          </p>
        ) : (
          <p>
            You are setting hard maximum value: {incomingVal}, which is smaller
            than current value: {currentVal}. If you procceed, the value will be
            changed to: {incomingVal}.
          </p>
        )}
        <div className="minMaxConfirmModalButtons">
          <Button
            variant="contained"
            className="decideButton"
            onClick={() => onAccept()}
          >
            Accept
          </Button>
          <Button
            variant="contained"
            className="decideButton"
            onClick={() => onCancel()}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default MaxMinConfirmationPopUp;
