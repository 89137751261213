/**
 * @namespace Utilities.Constants
 * @description Constants used across the application.
 */

/**
 * This is a Raman reffit id.
 * @memberof Utilities.Constants
 * @type {number}
 */
export const RAMAN_REF_ID = -113;

/**
 * This is a default graph options that we use to create new empty graphs.
 * @memberof Utilities.Constants
 * @type {object}
 */
export const DEFAULT_GRAPH = {
  id: 0,
  position: {
    x: 100,
    y: 0,
  },
  title: "Graph",
  plotData: [],
  layout: {
    xaxis: {
      title: { text: "Wavenumber [cm<sup>-1</sup>]" },
      type: "linear",
      mirror: "ticks",
      showline: true,
      linecolor: "#000",
      linewidth: 1,
      range: [-1000, 1000],
    },
    yaxis: {
      title: { text: "Amplitude" },
      type: "linear",
      mirror: "ticks",
      showline: true,
      linecolor: "#000",
      linewidth: 1,
    },
    margin: {
      l: 60,
      r: 5,
      b: 35,
      t: 20,
      pad: 0,
    },
    autosize: true,
    width: window.innerWidth * 0.4 - 2,
    height: window.innerWidth * 0.3 - 38,
    // TRANSITION CAUSES ISSUES WITH RERENDERING AND PRODUCES VISUAL BUGS
    // transition: {
    //   duration: 500,
    //   easing: "cubic-in-out",
    // },
    paper_bgcolor: "#edf7ff",
    legend: {
      x: 1,
      y: 1,
      xanchor: "right", // anchors the legend's right side to the x position
      yanchor: "top", // anchors the legend's top to the y position
      bgcolor: "rgba(255,255,255,0.8)", // semi-transparent white background
    },
  },
  containedFiles: [],
  containedModels: [],
};

/**
 * This variable contains default value groups that we use to initialise and reset groups contained in context.
 * @memberof Utilities.Constants
 * @type {Array<object>}
 */
export const DEFAULT_VAL_GROUPS = [
  {
    groupNumber: 1,
    value: null,
    hardMax: null,
    hardMin: null,
    memberCount: 0,
    fixed: null,
  },
  {
    groupNumber: 2,
    value: null,
    hardMax: null,
    hardMin: null,
    memberCount: 0,
    fixed: null,
  },
  {
    groupNumber: 3,
    value: null,
    hardMax: null,
    hardMin: null,
    memberCount: 0,
    fixed: null,
  },
  {
    groupNumber: 4,
    value: null,
    hardMax: null,
    hardMin: null,
    memberCount: 0,
    fixed: null,
  },
  {
    groupNumber: 5,
    value: null,
    hardMax: null,
    hardMin: null,
    memberCount: 0,
    fixed: null,
  },
  {
    groupNumber: 6,
    value: null,
    hardMax: null,
    hardMin: null,
    memberCount: 0,
    fixed: null,
  },
  {
    groupNumber: 7,
    value: null,
    hardMax: null,
    hardMin: null,
    memberCount: 0,
    fixed: null,
  },
  {
    groupNumber: 8,
    value: null,
    hardMax: null,
    hardMin: null,
    memberCount: 0,
    fixed: null,
  },
  {
    groupNumber: 9,
    value: null,
    hardMax: null,
    hardMin: null,
    memberCount: 0,
    fixed: null,
  },
  {
    groupNumber: 10,
    value: null,
    hardMax: null,
    hardMin: null,
    memberCount: 0,
    fixed: null,
  },
];
