import React, { useState, useContext, useEffect } from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Button } from "@mui/material";
import { AuthContext } from "../../context/AuthContext";
import { GeneralContext } from "../../context/GeneralContext";
import { BASE_URL } from "../../utils/authActions";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function TestPage() {
  //GENERAL VARIABLES
  const { authToken, isAuthReady, isAdmin, baseBE_URL, setBaseBE_URL } =
    useContext(AuthContext);
  const { recordedErrorLog } = useContext(GeneralContext);
  const navigate = useNavigate();
  //ENDPOINT TEST VARIABLES
  const [endpoint, setEndpoint] = useState("");
  const [isPost, setIsPost] = useState(false);
  const [attachAut, setattachAuth] = useState(true);
  const [payload, setPayload] = useState("");
  const [isParseable, setIsParseable] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  //BASE URL VARIABLES
  const [newBaseURL, setNewBaseURL] = useState(baseBE_URL);

  useEffect(() => {
    if (isAuthReady) {
      if (authToken === "" || !isAdmin) {
        navigate("/profile");
      }
    }
  }, [isAuthReady]);

  function testJson() {
    try {
      JSON.parse(payload);
      setIsParseable(true);
    } catch (error) {
      setIsParseable(false);
    }
  }

  function authHeaders() {
    return {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };
  }

  function makeRequest() {
    try {
      setIsLoading(true);
      if (isPost) {
        if (attachAut) {
          axios
            .post(`${BASE_URL}${endpoint}`, JSON.parse(payload), authHeaders())
            .then((response) =>
              console.log("RESPONSE TO TEST REQUEST: ", response)
            )
            .catch((err) =>
              console.log("THERE WAS AN ERROR WITH TEST REQUEST: ", err)
            )
            .finally(() => setIsLoading(false));
        } else {
          axios
            .post(`${BASE_URL}${endpoint}`, JSON.parse(payload))
            .then((response) =>
              console.log("RESPONSE TO TEST REQUEST: ", response)
            )
            .catch((err) =>
              console.log("THERE WAS AN ERROR WITH TEST REQUEST: ", err)
            )
            .finally(() => setIsLoading(false));
        }
      } else {
        if (attachAut) {
          axios
            .get(`${BASE_URL}${endpoint}`, authHeaders())
            .then((response) =>
              console.log("RESPONSE TO TEST REQUEST: ", response)
            )
            .catch((err) =>
              console.log("THERE WAS AN ERROR WITH TEST REQUEST: ", err)
            )
            .finally(() => setIsLoading(false));
        } else {
          axios
            .get(`${BASE_URL}${endpoint}`)
            .then((response) =>
              console.log("RESPONSE TO TEST REQUEST: ", response)
            )
            .catch((err) =>
              console.log("THERE WAS AN ERROR WITH TEST REQUEST: ", err)
            )
            .finally(() => setIsLoading(false));
        }
      }
    } catch (err) {
      recordedErrorLog("THERES WAS AN ERROR TRYING TO MAKE A REQUEST: ", err);
    }
  }

  return (
    <div
      style={{
        paddingTop: "25px",
        paddingBottom: "25px",
        paddingRight: "20px",
        paddingLeft: "20px",
      }}
    >
      Endpoint test:
      <div style={{ marginTop: "20px" }}>
        {BASE_URL}
        <input
          style={{ marginLeft: "5px" }}
          value={endpoint}
          onChange={(e) => setEndpoint(e.target.value)}
        />
      </div>
      <div style={{ marginTop: "10px" }}>TYPE:</div>
      <FormGroup
        sx={{
          width: "100px",
        }}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={!isPost}
              onChange={() => setIsPost((old) => !old)}
            />
          }
          label="GET"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={isPost}
              onChange={() => setIsPost((old) => !old)}
            />
          }
          label="POST"
        />
      </FormGroup>
      <div style={{ marginTop: "10px" }}>AUTHENTICATION:</div>
      <FormGroup
        sx={{
          width: "150px",
        }}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={attachAut}
              onChange={() => setattachAuth((old) => !old)}
            />
          }
          label="Include"
        />
      </FormGroup>
      {isPost ? (
        <div
          style={{
            marginTop: "20px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div>PAYLOAD:</div>
          <textarea
            style={{ width: "500px", height: "400px" }}
            value={payload}
            onChange={(e) => {
              setIsParseable(null);
              setPayload(e.target.value);
            }}
            placeholder='{
              "test": 1,
              "array": ["a", "b", "c"]
              }'
          />
          <div
            style={{
              width: "500px",
              marginTop: "10px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Button variant="contained" onClick={() => testJson()}>
              Test Json
            </Button>
            {isParseable !== null ? (
              <div
                style={
                  isParseable
                    ? { marginLeft: "15px", color: "green" }
                    : { marginLeft: "15px", color: "red" }
                }
              >
                Json is {isParseable ? "OKAY" : "WRONG"}
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}
      <Button
        variant="contained"
        style={{ marginTop: "30px" }}
        onClick={() => makeRequest()}
      >
        Make Request
      </Button>
      <div>{isLoading ? "LOADING ..." : ""}</div>
      <div
        style={{
          marginTop: "4vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}
      >
        <div style={{ display: "flex" }}>
          <div style={{ marginRight: "15px" }}>Current BE WebSocket URL:</div>
          <div>{baseBE_URL}</div>
        </div>
        <div style={{ marginTop: "1vh", display: "flex" }}>
          <div style={{ marginRight: "15px" }}>New BE WebSocket URL:</div>
          <input
            value={newBaseURL}
            style={{ minWidth: "200px" }}
            onChange={(e) => setNewBaseURL(e.target.value)}
          />
        </div>
        <Button
          variant="contained"
          style={{ marginTop: "30px" }}
          onClick={() => setBaseBE_URL(newBaseURL)}
        >
          Change BE WS URL
        </Button>
      </div>
    </div>
  );
}

export default TestPage;
