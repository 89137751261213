import React from "react";
import info from "../../res/icons/info.png";
import warning from "../../res/icons/warning.png";
import error from "../../res/icons/error-cross.png";
import clear from "../../res/icons/delete.png";
import "./appBar.scss";

function WarningEntry(params) {
  const { message, level, time, handleDelete, index } = params;
  let iconToUse = null;
  let color = null;

  switch (level) {
    case 0:
      iconToUse = info;
      color = "#006eff1e";
      break;
    case 1:
      iconToUse = warning;
      color = "#ffbb001e";
      break;
    case 2:
      iconToUse = error;
      color = "#ff00001e";
      break;
    default:
      iconToUse = error;
      color = "#ff00001e";
      break;
  }

  return (
    <div className="warningEntry" style={{ backgroundColor: color }}>
      <img src={iconToUse} alt="warning message icon" className="messageIcon" />
      <div className="messageContainer">
        <div className="clearWarningContainer">
          <img
            src={clear}
            alt="clear message button"
            className="clearWarning"
            onClick={() => handleDelete(index)}
          />
        </div>
        <div className="message">{message}</div>
        <div className="time">{time}</div>
      </div>
    </div>
  );
}

export default WarningEntry;
