import React, { useState, useEffect, useContext } from "react";
import PasswordValidator from "password-validator";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { TextField } from "@mui/material";
import { GeneralContext } from "../../context/GeneralContext";

function PasswordEntry(props) {
  const {
    title = "Password",
    setFinalPass,
    stacked = false,
    setFilled = () => {},
  } = props;
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [passMissmatch, setPassMissmatch] = useState(false);
  const [passGood, setPassGood] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const { recordedErrorLog } = useContext(GeneralContext);

  const schema = new PasswordValidator();
  schema
    .is()
    .min(8) // Minimum length 8
    .is()
    .max(100) // Maximum length 100
    .has()
    .uppercase() // Must have uppercase letters
    .has()
    .lowercase() // Must have lowercase letters
    .has()
    .digits(2) // Must have at least 2 digits
    .has()
    .not()
    .spaces() // Should not have spaces
    .is()
    .not()
    .oneOf(["Passw0rd", "Password123"]); // Blacklist these values

  useEffect(() => {
    if (password !== "" || passwordConfirm !== "") {
      setFilled(true);
    } else {
      setFilled(false);
    }
  }, [password, passwordConfirm]);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handlePassBlur = (e) => {
    try {
      const pass = e.target.value;

      if (passwordConfirm !== "") {
        checkPassMatch();
      }

      if (password !== "") {
        if (schema.validate(pass)) {
          setPassGood(true);
        } else {
          setPassGood(false);
          setFinalPass("");
        }
      }
    } catch (error) {
      recordedErrorLog("Password blur handler has failed: ", error);
    }
  };

  const checkAndSetFinal = () => {
    if (
      password !== "" &&
      passwordConfirm !== "" &&
      password === passwordConfirm &&
      schema.validate(password)
    ) {
      setFinalPass(password);
    }
  };

  const checkPassMatch = () => {
    if (passwordConfirm !== "" && password !== passwordConfirm) {
      setPassMissmatch(true);
      setFinalPass("");
      return;
    } else {
      setPassMissmatch(false);
    }

    checkAndSetFinal();
  };

  return (
    <div className="passwordEntry">
      {/* PASSWORD SECTION */}
      <div className="entrySection">
        <div className={`sectionTitle ${stacked ? "stacked" : "flat"}`}>
          {title}
        </div>
        <div className={`entries ${stacked ? "stacked" : ""}`}>
          <div className={`entry ${stacked ? "stacked" : "flat"}`}>
            {/* <div className="entryTitle">Enter Password</div> */}
            <TextField
              className="entryField"
              size="small"
              value={password}
              type={showPassword ? "text" : "password"}
              onChange={(e) => setPassword(e.target.value)}
              onBlur={(e) => handlePassBlur(e)}
              label={"Password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityOffIcon />
                      ) : (
                        <VisibilityIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onPaste={(event) => event.preventDefault()}
            />
          </div>
          <div className="entry">
            {/* <div className="entryTitle">Confirm Password</div> */}
            <TextField
              className="entryField"
              size="small"
              value={passwordConfirm}
              type={showPassword ? "text" : "password"}
              onChange={(e) => setPasswordConfirm(e.target.value)}
              onBlur={() => checkPassMatch()}
              label={"Confirm Password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityOffIcon />
                      ) : (
                        <VisibilityIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onPaste={(event) => event.preventDefault()}
            />
          </div>
        </div>
      </div>
      {passMissmatch ? (
        <div className="missmatch">Passwords do not match!</div>
      ) : (
        <></>
      )}
      {!passGood ? (
        <div className="missmatch">
          Password must:
          <ul>
            <li>Be from 8 to 100 characters long</li>
            <li>Contain uppercase letter</li>
            <li>Contain lowercase letter</li>
            <li>Contain 2 digits</li>
            <li>Not contain spaces</li>
          </ul>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default PasswordEntry;
