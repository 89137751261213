import React from "react";
import WarningEntry from "./WarningEntry";

export function generateWarningList(warningList, handleDelete) {
  const warningComponentList = warningList.map((warning, index) => (
    <WarningEntry
      key={warning.time + index}
      message={warning.message}
      level={warning.level}
      time={warning.time}
      index={index}
      handleDelete={handleDelete}
    />
  ));

  return warningComponentList;
}
