import React, { createContext, useEffect, useState } from "react";
import { deepCopy } from "../components/leftSide/Models/modelLogic";

export const GraphContext = createContext();

const GraphContextProvider = (props) => {
  // Initialising graphs with an empty graph, so that there is a blank graph on the start of the app
  const [graphs, setGraphs] = useState([]);
  const [graphId, setGraphId] = useState(1);
  const [updatedModelFE_IDs, setUpdatedModelFE_IDs] = useState([]);
  const [requestedFitModel, setRequestedFitModel] = useState([]);
  const [chi2Terms, setChi2Terms] = useState([]);
  const [chi2TermID, setChi2TermID] = useState(0);
  const [selectedChi2Terms, setSelectedChi2Terms] = useState([]);
  const [isGraphsReady, setIsGraphsReady] = useState(false);

  //values not stored in session
  const [fitModalIsOpen, setFitModalIsOpen] = useState(false);

  const [zIndices, setZIndices] = useState(
    deepCopy(graphs).reduce((acc, win) => {
      acc[win.id] = 0;
      return acc;
    }, {})
  );

  function getGraphVariables() {
    return {
      graphs,
      graphId,
      updatedModelFE_IDs,
      chi2Terms,
      chi2TermID,
      selectedChi2Terms,
    };
  }

  function setGraphVariables(variables) {
    const {
      graphs,
      graphId,
      updatedModelFE_IDs,
      chi2Terms,
      chi2TermID,
      selectedChi2Terms,
    } = variables;

    setGraphs(graphs);
    setGraphId(graphId);
    setUpdatedModelFE_IDs(updatedModelFE_IDs);
    setChi2Terms(chi2Terms);
    setChi2TermID(chi2TermID);
    setSelectedChi2Terms(selectedChi2Terms);
  }

  const resetGraphContext = () => {
    setGraphs([]);
    setGraphId(1);
    setUpdatedModelFE_IDs([]);
    setRequestedFitModel([]);
    setChi2Terms([]);
    setChi2TermID(0);
    setSelectedChi2Terms([]);
    // setIsGraphsReady(false);
    setFitModalIsOpen(false);
    setZIndices({});
  };

  useEffect(() => {
    const graphIdSession = JSON.parse(window.sessionStorage.getItem("graphId"));
    if (graphIdSession != null) {
      setGraphId(graphIdSession);
    }

    const graphsSession = JSON.parse(window.sessionStorage.getItem("graphs"));
    if (graphsSession != null) {
      setGraphs(graphsSession);
    }

    const updatedModelFE_IDsSession = JSON.parse(
      window.sessionStorage.getItem("updatedModelFE_IDs")
    );
    if (updatedModelFE_IDsSession != null) {
      setUpdatedModelFE_IDs(updatedModelFE_IDsSession);
    }

    const chi2TermsSession = JSON.parse(
      window.sessionStorage.getItem("chi2Terms")
    );
    if (chi2TermsSession != null) {
      setChi2Terms(chi2TermsSession);
    }

    const chi2TermIDSession = JSON.parse(
      window.sessionStorage.getItem("chi2TermID")
    );
    if (chi2TermIDSession != null) {
      setChi2TermID(chi2TermIDSession);
    }

    const selectedChi2TermsSession = JSON.parse(
      window.sessionStorage.getItem("selectedChi2Terms")
    );
    if (selectedChi2TermsSession != null) {
      setSelectedChi2Terms(selectedChi2TermsSession);
    }

    setIsGraphsReady(true);
  }, []);

  const value = {
    resetGraphContext,
    graphs,
    setGraphs,
    graphId,
    setGraphId,
    updatedModelFE_IDs,
    setUpdatedModelFE_IDs,
    requestedFitModel,
    setRequestedFitModel,
    chi2Terms,
    setChi2Terms,
    fitModalIsOpen,
    setFitModalIsOpen,
    chi2TermID,
    setChi2TermID,
    selectedChi2Terms,
    setSelectedChi2Terms,
    isGraphsReady,
    zIndices,
    setZIndices,
    getGraphVariables,
    setGraphVariables,
  };

  useEffect(() => {
    window.sessionStorage.setItem("graphs", JSON.stringify(graphs));
  }, [graphs]);

  useEffect(() => {
    window.sessionStorage.setItem("graphId", JSON.stringify(graphId));
  }, [graphId]);

  useEffect(() => {
    window.sessionStorage.setItem(
      "updatedModelFE_IDs",
      JSON.stringify(updatedModelFE_IDs)
    );
  }, [updatedModelFE_IDs]);

  useEffect(() => {
    window.sessionStorage.setItem("chi2Terms", JSON.stringify(chi2Terms));
  }, [chi2Terms]);

  useEffect(() => {
    window.sessionStorage.setItem("chi2TermID", JSON.stringify(chi2TermID));
  }, [chi2TermID]);

  useEffect(() => {
    window.sessionStorage.setItem(
      "selectedChi2Terms",
      JSON.stringify(selectedChi2Terms)
    );
  }, [selectedChi2Terms]);

  return (
    <GraphContext.Provider value={value}>
      {props.children}
    </GraphContext.Provider>
  );
};

export default GraphContextProvider;
