/**
 * @category LeftSide
 * @component
 * @module Files/fileProcessing/FileProcessingWindow
 * @description This component is a window that pops up when user tries to upload some files and has *no preset*
 * selected in loading options. This component then lets user select options for the file and then process it.
 * User can also save the options as a preset for later use or as an automatic loading option.
 */
import React, { useState, useEffect, useContext } from "react";
import RawAndSolved from "./RawAndSolved";
import ProcessingOptions from "./ProcessingOptions";
import { DashboardContext } from "../../../../context/DashboardContext";
import { GeneralContext } from "../../../../context/GeneralContext";
import FileList from "./FileList";
import "./fileProcessing.scss";
import { Button } from "@mui/material";
import { generateWarningObject, hasProperty } from "../../../../utils/helpers";
import {
  deepCopyFiles,
  getNextAvailableIndex,
  readFile,
} from "./processingLogic";
import { processFileWithOptions } from "./processingLogic";
import { deepCopy } from "../../Models/modelLogic";

function FileProcessingWindow(props) {
  const { fileList, cancelProcessing, solveProcessing, modalId } = props;
  const {
    filePresets,
    setFilePresets,
    fileID,
    setFileID,
    setWarnings,
    setNewWarningCount,
  } = useContext(DashboardContext);
  const { limitedToast, recordedErrorLog } = useContext(GeneralContext);
  const [currentFileIndex, setCurrentFileIndex] = useState(0);
  const [currentFileContent, setCurrentFileContent] = useState(null);
  const [processedContent, setProcessedContent] = useState("");
  const [idForFile, setIdForFile] = useState(fileID);
  const [solvedList, setSolvedList] = useState(
    Array(fileList.length).fill(null)
  );
  const [done, setDone] = useState(false);
  const [filesToProcess, setFilesToProcess] = useState(fileList);

  useEffect(() => {
    try {
      if (currentFileIndex <= filesToProcess.length - 1) {
        readFile(
          filesToProcess[currentFileIndex],
          generateWarningObject,
          setWarnings,
          setNewWarningCount,
          limitedToast,
          recordedErrorLog
        )
          .then((readContent) => {
            setCurrentFileContent(readContent);
          })
          .catch(() => {
            setCurrentFileContent(null);
            setCurrentFileIndex((old) => old + 1);
          });
      } else {
        setDone(true);
      }
    } catch (error) {
      recordedErrorLog("currentFileIndex useEffect failure: ", error);
    }
  }, [currentFileIndex]);

  const handleSave = (optionObject) => {
    try {
      const saveName = prompt("Name your option set:");
      if (saveName !== null) {
        const largestIdObj = filePresets.reduce((acc, curr) => {
          return acc.id > curr.id ? acc : curr;
        }, filePresets[0]);

        setFilePresets((old) => [
          ...old,
          {
            presetName: saveName,
            id: largestIdObj.id + 1,
            presetOptions: optionObject,
          },
        ]);
      }
    } catch (error) {
      recordedErrorLog("Save handler failure: ", error);
    }
  };

  const handleApply = (optionObject) => {
    try {
      const dataPoints = processFileWithOptions(
        currentFileContent,
        optionObject,
        recordedErrorLog
      );

      if (dataPoints !== null) {
        const maxX = dataPoints.reduce(function (max, p) {
          const x = p.x > 0 ? Math.ceil(p.x) : Math.floor(p.x);
          return x > max ? x : max;
        }, Math.ceil(dataPoints[0].x));

        const minX = dataPoints.reduce(function (min, p) {
          const x = p.x > 0 ? Math.ceil(p.x) : Math.floor(p.x);
          return x < min ? x : min;
        }, Math.floor(dataPoints[0].x));

        const file = filesToProcess[currentFileIndex];

        let filesToProcessCopy = deepCopyFiles(filesToProcess);

        filesToProcessCopy[currentFileIndex].solved = true;

        const fileData = {
          name: file.name,
          size: file.size,
          type: file.type,
          lastModified: file.lastModified,
          content: currentFileContent,
          dataPoints: dataPoints,
          npoints: dataPoints.length,
          edges: [{ min: "", max: "" }],
          dataRangeMin: minX,
          dataRangeMax: maxX,
          xUnit: optionObject.xUnit,
          ID: idForFile,
          options: optionObject,
        };
        setIdForFile((old) => old + 1);
        setFileID((old) => old + 1);

        setFilesToProcess(filesToProcessCopy);
        const updatedSolved = deepCopy(solvedList);
        updatedSolved[currentFileIndex] = fileData;
        setSolvedList(updatedSolved);
        const nextFreeIndex = getNextAvailableIndex(filesToProcessCopy);
        if (nextFreeIndex !== null) {
          setCurrentFileIndex(nextFreeIndex);
        } else {
          setDone(true);
        }
        setProcessedContent("");
      } else {
        setProcessedContent(dataPoints);
      }
    } catch (error) {
      recordedErrorLog("Apply handler failure: ", error);
    }
  };

  const handleCheck = (optionObject, skipClear) => {
    try {
      let dataPoints;
      if (
        hasProperty(filesToProcess[currentFileIndex], "solved") &&
        filesToProcess[currentFileIndex].solved
      ) {
        dataPoints = processFileWithOptions(
          solvedList[currentFileIndex].content,
          optionObject,
          recordedErrorLog
        );
      } else {
        dataPoints = processFileWithOptions(
          currentFileContent,
          optionObject,
          recordedErrorLog
        );
      }

      if (
        hasProperty(filesToProcess[currentFileIndex], "solved") &&
        filesToProcess[currentFileIndex].solved &&
        !skipClear
      ) {
        let filesToProcessCopy = deepCopyFiles(filesToProcess);

        filesToProcessCopy[currentFileIndex].solved = false;

        setFilesToProcess(filesToProcessCopy);

        const updatedSolved = deepCopy(solvedList);
        updatedSolved[currentFileIndex] = null;
        setSolvedList(updatedSolved);
        setDone(false);
      }

      setProcessedContent(dataPoints);
    } catch (error) {
      recordedErrorLog("Check handler failure: ", error);
    }
  };

  const handleFinishLoad = () => {
    solveProcessing(solvedList);
  };

  const handleCancelLoad = () => {
    cancelProcessing();
  };

  return (
    <div className="fileProcessWindow">
      <div className="leftSide">
        <div className="leftTitle">File queue:</div>
        <div className="leftContent">
          <FileList
            selectedFileIndex={currentFileIndex}
            filesToProcess={filesToProcess}
            setSelectedFile={setCurrentFileIndex}
          />
          <div className="buttonArea">
            <Button
              variant="contained"
              className="finishButton"
              onClick={() => handleFinishLoad()}
              disabled={!done}
            >
              Load Files
            </Button>
            <Button
              variant="contained"
              className="finishButton"
              onClick={() => handleCancelLoad()}
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
      <div className="rightSide">
        <RawAndSolved
          rawFileContent={currentFileContent}
          processedContent={processedContent}
        />
        <div className="divider" />
        <ProcessingOptions
          save={handleSave}
          apply={handleApply}
          check={handleCheck}
          modalId={modalId}
          fileContent={currentFileContent}
          processedFileDetails={solvedList[currentFileIndex]}
        />
      </div>
    </div>
  );
}

export default FileProcessingWindow;
