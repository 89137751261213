/**
 * @category LeftSide
 * @component
 * @module Files/fileProcessing/FileList
 * @description This component is responsible for creating a file list and marking which file is selected from
 * the list.
 */
import React from "react";
import DoneIcon from "@mui/icons-material/Done";
import "./fileProcessing.scss";
import { checkIfSolved } from "./processingLogic";

function FileList(props) {
  const { selectedFileIndex, filesToProcess, setSelectedFile } = props;

  return (
    <div className="fileList">
      {filesToProcess.map((file, index) => {
        return (
          <div
            key={index}
            className={`fileEntry ${
              index === selectedFileIndex ? "selected" : ""
            } ${checkIfSolved(file) ? "solved" : ""}`}
            onClick={() => setSelectedFile(index)}
          >
            <div className="fileName">{file.name}</div>
            {checkIfSolved(file) ? <DoneIcon className="icon" /> : <></>}
          </div>
        );
      })}
    </div>
  );
}

export default FileList;
